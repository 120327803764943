import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcMarker: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 18' fill='none' {...props}>
			<path
				d='M7.125 9.75C8.36764 9.75 9.375 8.74264 9.375 7.5C9.375 6.25736 8.36764 5.25 7.125 5.25C5.88236 5.25 4.875 6.25736 4.875 7.5C4.875 8.74264 5.88236 9.75 7.125 9.75Z'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M7.125 16.5C10.125 13.5 13.125 10.8137 13.125 7.5C13.125 4.18629 10.4387 1.5 7.125 1.5C3.81129 1.5 1.125 4.18629 1.125 7.5C1.125 10.8137 4.125 13.5 7.125 16.5Z'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
