import moment from 'moment';
import { useQuery } from '@tanstack/react-query';

import type {
	IDriverRelatedData,
	IInspectionedContainerRecordPage,
	IInspectionFormatPage,
	IKitRelatedData,
	ISecurityProcedureCheckListPage,
} from '../../pdfs/InspectionDocument/interfaces';

import { getKitDetails, getOneDriver, getOneProcess } from '@api';
import { apiUrl, getWeekOfYear } from '@utils';

import type { TProcess } from '@api';

interface IMappedData {
	inspeccionInterna: {
		kit_de_seguridad: string;
		booking: string;
		cliente: string;
		codigo_de_barra_del_formato: string;
		conductor: string;
		lugar: string;
		naviera: string;
		observacion: string;
		placa: string;
		sello_naviera: string;
		transportista: string;
		// escanear_kit: string;
		pictures: {
			title: string;
			pictures: {
				url: string;
				tookBy: string;
				date: string;
				coordinates: {
					lat: string;
					lng: string;
				};
			}[];
		}[];
	};

	inspeccionExterna: {
		pictures: {
			title: string;
			pictures: {
				url: string;
				tookBy: string;
				date: string;
				coordinates: {
					lat: string;
					lng: string;
				};
			}[];
		}[];
	};
}

interface IMapperResult {
	kitId: string;
	driverId: string;
	firstPage: ISecurityProcedureCheckListPage;
	secondPage: Omit<IInspectionedContainerRecordPage, 'kitDetails'>;
	thirdPage: Omit<IInspectionFormatPage, keyof IKitRelatedData | keyof IDriverRelatedData>;
	pictures: {
		inspeccionInterna: {
			title: string;
			pictures: {
				url: string;
				tookBy: string;
				date: string;
				coordinates: {
					lat: string;
					lng: string;
				};
			}[];
		}[];
		inspeccionExterna: {
			title: string;
			pictures: {
				url: string;
				tookBy: string;
				date: string;
				coordinates: {
					lat: string;
					lng: string;
				};
			}[];
		}[];
	};
}

// This sort of functions only work for a particular process
// Disable ts-check for this
const mapper = (processes: TProcess[]): IMapperResult => {
	const process = processes[0];
	console.log(process);

	const data: IMappedData = {
		inspeccionInterna: process.workflows
			.find(workflow => workflow.titleWorkflow === 'INSPECCION INTERNA REEFER')
			?.steps.reduce(
				(prev, currForm) => {
					if (currForm.formType === 'FORMS') {
						// Forms have multiple components
						currForm.component.forEach(component => {
							// Each response should also be parsed based on its dataType but this is good enough for now I guess
							const keyName = component.label.toLowerCase().split(' ').join('_');
							prev[keyName] = component.response[0]?.response_answer ?? '';
						});
					} else if (currForm.formType === 'CAMERA') {
						// Camera forms can have more than one "component"?
						prev['pictures'] = [
							...prev['pictures'],
							{
								title: currForm.title,
								pictures: currForm.component[0].response.map(r => ({
									url: r.response_answer,
									tookBy: r.fullName,
									date: r.response_date,
									coordinates: {
										lat: r.response_coordinates.lat,
										lng: r.response_coordinates.long,
									},
								})),
							},
						];
					}

					// Find the kit id

					return prev;
				},
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				{ pictures: [] } as any
			) ?? { pictures: [] },
		inspeccionExterna: process.workflows
			.find(workflow => workflow.titleWorkflow === 'INSPECCION EXTERNA REEFER')
			?.steps.reduce(
				(prev, currForm) => {
					if (currForm.formType === 'FORMS') {
						// Forms have multiple components
						currForm.component.forEach(component => {
							// Each response should also be parsed based on its dataType but this is good enough for now I guess
							const keyName = component.label.split(' ').join('_');
							prev[keyName] = component.response[0]?.response_answer ?? '';
						});
					} else if (currForm.formType === 'CAMERA') {
						// Camera forms can have more than one "component"?
						prev['pictures'] = [
							...prev['pictures'],
							{
								title: currForm.title,
								pictures: currForm.component[0].response.map(r => ({
									url: r.response_answer,
									tookBy: r.fullName,
									date: r.response_date,
									coordinates: {
										lat: r.response_coordinates.lat,
										lng: r.response_coordinates.long,
									},
								})),
							},
						];
					}

					return prev;
				},
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				{ pictures: [] } as any
			) ?? { pictures: [] },
	};

	console.log(data);

	return {
		kitId: data.inspeccionInterna.kit_de_seguridad,
		driverId: data.inspeccionInterna.conductor, // No tenemos componente para esto
		firstPage: {
			client: process.company ?? 'N/A',
			date: '2024-08-01 07:37:13', // should this be generated on the fly?
			inspectionPlace:
				process.workflows.find(workflow => workflow.titleWorkflow === 'INSPECCION INTERNA REEFER')?.location ?? 'N/A',
			spanishObservation: data.inspeccionInterna.observacion,
			englishObservation:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui oficia deserunt mollit anim id est laborum.',
		},
		secondPage: {
			inspectionFormatNumber: data.inspeccionInterna.codigo_de_barra_del_formato, // Creo
			madeBy: 'A. VINCES J. LEON', // Tengo
			inspectionPlace: data.inspeccionInterna.lugar, // Tengo
			date: '2024-08-01 07:37:13', // Tengo
			// Estas no
			spanishObservation: data.inspeccionInterna.observacion,
			englishObservation:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui oficia deserunt mollit anim id est laborum.',
			// A esta página falta agregarle las cintas que salen del kit
			containerNumber: process.reference,
		},
		thirdPage: {
			code: 'FR-OPR-005',
			date: moment.utc(process.date).format('DD-MM-YYYY'), // No tengo
			headerNumber: data.inspeccionInterna.codigo_de_barra_del_formato, // Should this be hardcoded
			client: process.company ?? 'N/A',
			inspectionPlace:
				process.workflows.find(workflow => workflow.titleWorkflow === 'INSPECCION INTERNA REEFER')?.location ?? 'N/A',
			weekNumber: getWeekOfYear(new Date()),
			containerNumber: process.reference,
			startTime: '07:37', // No tengo
			endTime: '08:31', // No tengo

			// driverFullName: data.inspeccionInterna.conductor,
			registrationPlate: data.inspeccionInterna.placa,
			transportCompany: data.inspeccionInterna.transportista,
			navieraBooking: data.inspeccionInterna.booking,
			selloCable: 'N/A', // From kit
			STK_SEG: 'N/A',
			RESP_INSP: `${data.inspeccionInterna.pictures[0]?.pictures[0]?.tookBy ?? ''}, ${data.inspeccionExterna.pictures[0]?.pictures[0]?.tookBy ?? ''}`, // El que hizo la externa e interna
			rastreo: 'N/A',
			selloSalida: 'GWF6650791Y', // There's no way of knowing this from the kit's structure
			model: 'N/A',
			selloRetorno: 'CBE1520791E', // There's no way of knowing this from the kit's structure
		},
		pictures: {
			inspeccionInterna: data.inspeccionInterna.pictures,
			inspeccionExterna: data.inspeccionExterna?.pictures ?? [],
		},
	};
};

const generateBarcodeImageSrc = (text: string) => {
	const url = apiUrl as string;
	const isLastCharSlash = url[url.length - 1] === '/';
	return `${apiUrl}${isLastCharSlash ? '' : '/'}api/barcode?text=${text}`;
};

// TODO: This should return data that will fill up the entire PDF
export const useProcessPdfData = ({ id }: { id: string }) => {
	const processesQuery = useQuery({
		queryKey: ['processes', id],
		queryFn: () => getOneProcess({ id }),
		retry: false,
		gcTime: 3600,
		staleTime: 3600,
		refetchOnWindowFocus: false,
	});

	let kitId = '',
		driverId = '';
	let processMappedData = null;
	if (!processesQuery.isLoading && !processesQuery.isError) {
		// Use mapper
		processMappedData = mapper(processesQuery.data!);
		kitId = processMappedData.kitId;
		driverId = processMappedData.driverId;
	}

	const kitQuery = useQuery({
		queryKey: ['kits', kitId],
		queryFn: () => getKitDetails({ kitId }),
		enabled: !!kitId,
		retry: false,
		gcTime: 3600,
		staleTime: 3600,
		refetchOnWindowFocus: false,
	});

	const driverQuery = useQuery({
		queryKey: ['drivers', driverId],
		queryFn: () => getOneDriver({ driverId }),
		enabled: !!driverId,
		retry: false,
		staleTime: 3600,
		gcTime: 3600,
		refetchOnWindowFocus: false,
	});

	let resultingData = undefined;
	if (processesQuery.data /* && kitQuery.data && driverQuery.data*/) {
		const kitDetails =
			kitQuery.data?.kitDetail.map(detail => ({
				name: detail.securityItemType,
				items: detail.items.map(securityItem => securityItem.value),
			})) ?? [];

		resultingData = {
			firstPage: processMappedData!.firstPage,
			secondPage: {
				...processMappedData!.secondPage,
				kitDetails,
			},
			thirdPage: {
				...processMappedData!.thirdPage,
				driverFullName: `${driverQuery.data?.firstName ?? ''} ${driverQuery.data?.lastName ?? 'N/A'}`,
				driverCellphoneNumber: driverQuery.data?.phoneNumber ?? '0999999999',
				driverNationalId: driverQuery.data?.dni ?? '0999999999',

				// There's no way of determining which one is external as that depends on the workflow
				internalTapping:
					kitDetails
						.find(detail => detail.name === 'Cinta externa e interna')
						?.items.slice(0, 3)
						.map(generateBarcodeImageSrc) ?? [],
				externalTapping:
					kitDetails.find(detail => detail.name === 'Cinta externa e interna')?.items.map(generateBarcodeImageSrc) ??
					[],
				fundaSeguridad: kitQuery.data?.kit ?? 'FPK0001',
				leftDoor: generateBarcodeImageSrc(
					kitDetails.find(detail => detail.name === 'Cinta de puerta empty')?.items[0] ?? ''
				),
				rightDoor: generateBarcodeImageSrc(
					kitDetails.find(detail => detail.name === 'Cinta de puerta full')?.items[0] ?? ''
				),
				selloNaviera: kitDetails.find(detail => detail.name === 'Sello de Naviera')?.items[0] ?? '',
				selloPlastico: kitDetails.find(detail => detail.name === 'Sello plastico')?.items[0] ?? '',
			},
			pictures: processMappedData!.pictures,
			kitData: kitQuery.data,
		};
	}

	console.log(resultingData);

	return {
		isLoading: processesQuery.isLoading || kitQuery.isLoading,
		isError: processesQuery.isError,
		data: resultingData,
	};
};
