import { LinkSolid } from '@atoms';

import type { ITabNavigationProps } from './TabNavigation.interfaces';

import styles from './TabNavigation.module.sass';

export const TabNavigation = ({ className = '', tabs }: ITabNavigationProps) => {
	return (
		<nav className={`${styles['tab-navigation']} ${className}`}>
			{tabs.map(tab => (
				<LinkSolid className={styles['tab-navigation__item']} key={tab.name} to={tab.path} label={tab.name} />
			))}
		</nav>
	);
};
