import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcBell: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 21' fill='none' {...props}>
			<path
				d='M13.0003 20H9.0003M1.29414 4.81989C1.27979 3.36854 2.06227 2.01325 3.32635 1.3M20.7025 4.8199C20.7169 3.36855 19.9344 2.01325 18.6703 1.3M17.0003 7C17.0003 5.4087 16.3682 3.88258 15.2429 2.75736C14.1177 1.63214 12.5916 1 11.0003 1C9.409 1 7.88288 1.63214 6.75766 2.75736C5.63244 3.88258 5.0003 5.4087 5.0003 7C5.0003 10.0902 4.22077 12.206 3.34996 13.6054C2.61542 14.7859 2.24816 15.3761 2.26162 15.5408C2.27653 15.7231 2.31516 15.7926 2.46207 15.9016C2.59476 16 3.19289 16 4.38915 16H17.6114C18.8077 16 19.4058 16 19.5385 15.9016C19.6854 15.7926 19.7241 15.7231 19.739 15.5408C19.7524 15.3761 19.3852 14.7859 18.6506 13.6054C17.7798 12.206 17.0003 10.0902 17.0003 7Z'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
