import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcDollarSign: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 17' fill='none' {...props}>
			<path
				d='M1.5 11.5C1.5 13.1569 2.84315 14.5 4.5 14.5H7.5C9.15685 14.5 10.5 13.1569 10.5 11.5C10.5 9.84315 9.15685 8.5 7.5 8.5H4.5C2.84315 8.5 1.5 7.15685 1.5 5.5C1.5 3.84315 2.84315 2.5 4.5 2.5H7.5C9.15685 2.5 10.5 3.84315 10.5 5.5M6 1V16'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
