import { Modal } from '../Modal';
import styles from '../Forms/overrides.module.sass';
import { FormModalProvider } from '../Forms/ctx';
import { InnerForm } from './InnerForm';
import { PROCESS_TYPE_BY_ID } from '@utils';

interface IModalEditWorkflowProps {
	initialWorkflowData: {
		id: string;
		name: string;
		details: {
			id: string;
			// order: 0; // this could be unnecessary
			label: string;
			defaultValue: string; // This is always empty. Request fix
			// Im not getting the required either
			isActive: boolean;
			componentDataType: {
				id: string;
			};
		}[];
	};
	isModalVisible: boolean;
	onClose: () => void;
}

export const ModalEditWorkflow = ({ initialWorkflowData, isModalVisible, onClose }: IModalEditWorkflowProps) => {
	async function handleSave() {
		alert('Implement Saving');
	}

	return (
		<Modal
			className={`flex flex-col gap-4 min-w-[1100px] ${styles.override}`}
			title='Crear Formulario'
			isOpen={isModalVisible}
			onClose={onClose}
		>
			<FormModalProvider>
				<InnerForm
					key={initialWorkflowData.details.length}
					isLoading={false} // Update when endpoint is available
					onClose={onClose}
					initialFormTitle={initialWorkflowData.name}
					initialState={initialWorkflowData.details.map((detail, index) => ({
						id: detail.id,
						backendId: detail.id,
						data: {
							id: detail.componentDataType.id,
							defaultValue: detail.defaultValue,
							isRequired: false,
							label: detail.label,
						},
						isBeingEdited: index === 0,
						isValid: true,
						// Formulario by default as it is the only one that varies (this will break for sure lol)
						type: PROCESS_TYPE_BY_ID[detail.componentDataType.id] ?? 'Formulario',
					}))}
					initialTitle={initialWorkflowData.details.at(0)?.label}
					onSave={handleSave}
				/>
			</FormModalProvider>
		</Modal>
	);
};
