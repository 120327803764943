import { apiPrivate } from '../config/apiPrivate';

interface ICreateProcessParams {
	title: string;
	items: {
		id: string;
		title: string;
		order: number;
		isRequired: boolean;
	}[];
}

interface ICreateFormResp {}

export const createProcess = async (params: ICreateProcessParams): Promise<ICreateFormResp> => {
	const { data } = await apiPrivate.post<ICreateFormResp>('/api/workflow/register', params);
	return data;
};
