import { apiPrivate } from '../config/apiPrivate';

interface IFormCategory {
	id: string;
	name: string;
	formHeaders: {
		id: string;
		name: string;
	}[];
}

export const getFormsUnpaginated = async () => {
	const { data } = await apiPrivate.get<IFormCategory[]>(`/api/workflow/forms-type`);
	const forms = data.find(category => category.name === 'FORMS');
	if (!forms) return [];
	return forms.formHeaders;
};
