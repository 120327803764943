import { IFieldValues } from '@interfaces';
import { Controller, useForm } from 'react-hook-form';
import { FormWrapper } from './FormWrapper';
import { FormInputCheckbox, FormSearchableDropdown } from '@molecules';

interface ITimeFieldFormValues {
	title: string;
	listId: string;
	isRequired: boolean;
}

interface ITimeFieldFormProps {
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onDelete: () => void;
}

// This should ideally come from backend
const TIME_FORMATS = [
	{
		id: '1',
		value: 'HH:mm',
	},
	{
		id: '2',
		value: 'HH:mm:SS',
	},
];

export function FormTimeField({ defaultValues, onSave, onDelete, title }: ITimeFieldFormProps & { title: string }) {
	const id = '6167cc28-b112-4861-8191-f0ff8d596398';
	const form = useForm<ITimeFieldFormValues>({
		defaultValues: {
			...defaultValues,
			title,
			listId: defaultValues.defaultValue,
		},
	});

	function handleSubmit(formValues: ITimeFieldFormValues) {
		console.log('form submitted with values', formValues);
		onSave({
			id,
			label: formValues.title,
			// I'm using this property just in this case, since the ID needs to be the component ID
			defaultValue: formValues.listId,
			isRequired: formValues.isRequired,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onCancel={onDelete}>
			<Controller
				control={form.control}
				name='listId'
				defaultValue=''
				rules={{ required: { value: true, message: 'Yo there' } }}
				render={({ field: { ref, ...rest } }) => (
					<FormSearchableDropdown
						required
						errorMessage={form.formState.errors.listId?.message}
						onError={!!form.formState.errors.listId}
						label='Formato de hora'
						options={[
							{
								id: '',
								value: 'Seleccionar formato',
							},
							...TIME_FORMATS,
						]}
						{...rest}
					/>
				)}
			/>
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}
