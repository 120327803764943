import { useEffect, useRef } from 'react';

import gsap from 'gsap';

import type { IButtonProps } from '../../components/atoms/Button/Button.interfaces';

const GRADIENT_FROM = 'linear-gradient(rgba(0,60,103,1) 0%, rgba(3,97,163,1) 100%)';
const GRADIENT_TO = 'linear-gradient(rgba(3,97,163,1) 0%, rgba(0,60,103,1) 100%)';
const GRADIENT_DISABLED = 'linear-gradient(rgba(255, 255, 255,1) 0%, rgba(255,255,255,1) 100%)';
export const useButtonAnimation = ({ variant, disabled }: Pick<IButtonProps, 'variant' | 'disabled'>) => {
	const isFirstRender = useRef(true);
	const buttonRef = useRef<HTMLButtonElement>(null);

	const onMouseEnter = () => {
		if (variant !== 'primary') return;
		gsap
			.timeline()
			.to(buttonRef.current!, {
				background: GRADIENT_FROM,
				ease: 'none',
				duration: 0,
			})
			.to(buttonRef.current!, {
				background: GRADIENT_TO,
				ease: 'none',
				duration: 0.3,
			});
	};

	const onMouseLeave = () => {
		if (variant !== 'primary') return;
		gsap.to(buttonRef.current!, {
			background: GRADIENT_FROM,
			ease: 'none',
			duration: 0.3,
		});
	};

	useEffect(() => {
		if (!buttonRef.current) return;
		if (isFirstRender.current && variant === 'primary') return;

		if (variant !== 'primary') return;
		gsap.to(buttonRef.current, {
			background: disabled ? GRADIENT_DISABLED : GRADIENT_FROM,
			duration: 0.3,
		});
	}, [disabled]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
		}
		return () => {
			isFirstRender.current = true;
		};
	}, []);

	return { buttonRef, animationHandlers: { onMouseEnter, onMouseLeave } };
};
