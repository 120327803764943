import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcLogOut: FC<IIconsProps> = props => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='21'
			viewBox='0 0 20 21'
			fill='none'
			stroke='black'
			{...props}
		>
			<path
				d='M14 15.5L19 10.5M19 10.5L14 5.5M19 10.5H7M10 15.5C10 16.43 10 16.895 9.89778 17.2765C9.62038 18.3117 8.81173 19.1204 7.77646 19.3978C7.39496 19.5 6.92997 19.5 6 19.5H5.5C4.10218 19.5 3.40326 19.5 2.85195 19.2716C2.11687 18.9672 1.53284 18.3831 1.22836 17.6481C1 17.0967 1 16.3978 1 15V6C1 4.60217 1 3.90326 1.22836 3.35195C1.53284 2.61687 2.11687 2.03284 2.85195 1.72836C3.40326 1.5 4.10218 1.5 5.5 1.5H6C6.92997 1.5 7.39496 1.5 7.77646 1.60222C8.81173 1.87962 9.62038 2.68827 9.89778 3.72354C10 4.10504 10 4.57003 10 5.5'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
