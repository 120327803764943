import { View, Text, Image, Link } from '@react-pdf/renderer';
import { NumberedContentPage } from './NumberedContentPage';

const Header = ({
	order,
	stepNameSpanish,
	stepNameEnglish = 'Step name',
	workflowName,
}: {
	order: string;
	stepNameSpanish: string;
	stepNameEnglish?: string;
	workflowName: string;
}) => {
	return (
		<View
			style={{
				padding: '8px',
				color: 'white',
				backgroundColor: '#092D45',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				gap: '8px',
				borderTopLeftRadius: '4px',
				borderTopRightRadius: '4px',
			}}
		>
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: '8px',
				}}
			>
				<Text style={{ fontWeight: 'bold' }}>{order}</Text>
				<View
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Text
						style={{
							fontWeight: 'bold',
						}}
					>
						{stepNameSpanish}
					</Text>
					<Text style={{ color: '#D9D9D9' }}>{stepNameEnglish}</Text>
				</View>
			</View>

			<View
				style={{
					height: '0.5px',
					flexGrow: 1,
					backgroundColor: 'white',
				}}
			>
				{' '}
			</View>

			<View
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '4px',
				}}
			>
				{/* TODO: Abstract this into a ValuePair component */}
				<Text
					style={{
						fontWeight: 'bold',
					}}
				>
					{workflowName}
				</Text>
			</View>
		</View>
	);
};

export const PicturesPage = ({
	order,
	headerName,
	steps,
	id,
}: {
	headerName: string;
	order: string;
	steps: {
		name: string;
		images: {
			url: string;
			tookBy: string;
			date: string;
			coordinates: {
				lat: string;
				lng: string;
			};
		}[];
	}[];
	id?: string;
}) => (
	<NumberedContentPage>
		{/* TODO: Havent abstracted out this yet */}
		<View
			id={id}
			style={{
				display: 'flex',
				flexDirection: 'row',
				gap: '8px',
			}}
		>
			<Text style={{ fontWeight: 'bold' }}>{order}.</Text>
			<View
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Text style={{ fontWeight: 'bold' }}>Fotografías de flujos: {headerName}</Text>
				<Text style={{ fontWeight: 400, color: '#A1A1A1' }}>Workflows images</Text>
			</View>
		</View>

		{steps.map((step, idx) => (
			<View style={{ marginBottom: '16px' }} wrap={false} key={`${step.name}-${idx}`}>
				<Header
					order={`${order}.${idx + 1}`}
					stepNameSpanish={step.name}
					stepNameEnglish='EIR photograph'
					workflowName={headerName}
				/>
				<View
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: '8px',
						flexWrap: 'wrap',
						marginTop: '8px',
					}}
				>
					{step.images.map(image => (
						<View
							key={image.url}
							style={{
								width: '32.35%',
								maxHeight: '180px',
								padding: 0,
								backgroundColor: '#F1F1F1',
							}}
						>
							<Image
								style={{ height: '100%', width: '100%', objectFit: 'contain' }}
								src={{
									uri: image.url,
									method: 'GET',
									headers: {},
									body: '',
								}}
							/>
							<View
								style={{
									width: '100%',
									backgroundColor: '#F1F1F1',
									borderBottomLeftRadius: '3px',
									borderBottomRightRadius: '3px',
									fontSize: 7,
									textAlign: 'center',
									paddingVertical: 2,
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'baseline',
									paddingHorizontal: 2,
								}}
							>
								<Text>{image.tookBy}</Text>

								<Link
									style={{
										color: '#0d3f61',
									}}
									src={`https://www.openstreetmap.org/#map=15/${image.coordinates.lat}/${image.coordinates.lng}`}
								>
									<Text style={{ fontSize: 6 }}>
										{image.coordinates.lat}, {image.coordinates.lng}
									</Text>
								</Link>
							</View>
						</View>
					))}
				</View>
			</View>
		))}
	</NumberedContentPage>
);
