import { StrictMode } from 'react';

import { browserTracingIntegration, init, replayIntegration } from '@sentry/react';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import ReactDOM from 'react-dom/client';

import { queryClient, ReactQueryDevtools } from '@api';
import { router } from '@router';
import { store } from '@RTK/Store';

import './styles/globals.sass';

init({
	dsn: 'https://0d38171bbec4a4ccb4047da3a219c4ec@o4506932596310016.ingest.us.sentry.io/4506932674625536',
	integrations: [
		browserTracingIntegration(),
		replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
	<StrictMode>
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<RouterProvider router={router} />
				<ReactQueryDevtools />
				<Toaster position='bottom-right' />
			</QueryClientProvider>
		</Provider>
	</StrictMode>
);
