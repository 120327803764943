import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormSupplier, Modal } from '@organisms';
import { QUERY_KEYS, updateSupplier } from '@api';
import { AxiosError } from 'axios';
import { useSupplier } from '@hooks';
import { IModalProps } from '@interfaces';

interface IModalEditSupplierProps extends IModalProps {
	editingItemId: string;
}

export const ModalEditSupplier = ({ isVisible, onCloseModal, editingItemId }: IModalEditSupplierProps) => {
	const form = useForm<FormSupplier.IFormSupplierValues>();
	const { handleSubmit: handleSubmitWrapper, reset, setError } = form;
	useEffect(() => {
		isVisible && reset();
	}, [isVisible]);

	// Preload data
	const { isLoading, isError, data } = useSupplier(editingItemId);
	useEffect(() => {
		if (isLoading) {
			reset({
				name: 'Loading...',
				nationalId: 'Loading...',
			});
		}

		if (!isLoading && !isError) {
			reset({
				name: data?.name,
				nationalId: data?.identification,
			});
		}
	}, [isLoading, isError, data]);

	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: updateSupplier,
		onSuccess: () => {
			onCloseModal();
			queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.suppliers] });
		},
		onError: (error: AxiosError) => {
			if (error.response?.status === 400) {
				setError('name', { message: 'Proveedor ya existe' }, { shouldFocus: true });
			} else {
				throw error;
			}
		},
	});
	const handleSubmit = ({ name, nationalId }: FormSupplier.IFormSupplierValues) => {
		mutation.mutate({
			id: editingItemId,
			name,
			identification: nationalId,
		});
	};

	return (
		<Modal
			className='p-10 w-full max-w-[838px]'
			title='Edición de Proveedor'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<p className='text-2xs w-3/4'>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
				magna aliqua.
			</p>
			<FormProvider {...form}>
				<FormSupplier
					isLoading={mutation.isPending}
					onSubmit={handleSubmitWrapper(handleSubmit)}
					onCancel={onCloseModal}
				/>
			</FormProvider>
		</Modal>
	);
};
