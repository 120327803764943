import { findAllLocationsByUser, QUERY_KEYS, unassignLocationFromUser } from '@api';
import { Badge, Button, IcDeny, IcPlus } from '@atoms';
import { useAppSelector, useConfirmation, useLocations, useTableQuery } from '@hooks';
import { ILocation } from '@interfaces';
import { BaseTable, ModalAssignLocation, ModalConfirmation } from '@organisms';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const columnHelper = createColumnHelper<ILocation>();
const locationColumns: ColumnDef<ILocation, string>[] = [
	columnHelper.accessor('name', {
		header: 'Nombre de Localidad',
	}),
	columnHelper.display({
		header: 'País',
		cell: 'Ecuador',
		meta: { alignment: 'center' },
	}),
	columnHelper.display({
		header: 'Ciudad',
		cell: '-',
		meta: { alignment: 'center' },
	}),
	columnHelper.accessor('locationType.name', {
		header: 'Tipo',
		meta: { alignment: 'center' },
	}),
	columnHelper.display({
		header: 'Estado',
		cell: ({ row }) => (
			<Badge
				variant={row.original.isActive ? 'success' : 'danger'}
				label={row.original.isActive ? 'Activo' : 'Inactivo'}
			/>
		),
		meta: { alignment: 'center' },
	}),
];

const LocationsPage = () => {
	const [isVisible, setIsVisible] = useState(false);
	const { id: userId = '' } = useParams();
	const { companies } = useAppSelector(state => state.userProfile);

	const { data: assignedLocations } = useLocations({ by: 'user', userId });
	const { data: locations } = useLocations({
		by: 'company',
		companyId: companies[0].id,
	});

	const queryClient = useQueryClient();
	const { confirm, confirmationModalProps } = useConfirmation({
		mutationFn: unassignLocationFromUser,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.locations],
			});
		},
	});

	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.locations, 'by-user'],
		fetcherFn: params => findAllLocationsByUser({ userId, ...params }),
		tableColumns: [
			...locationColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { id } = row.original;
					return (
						<div className='flex justify-end gap-4'>
							<IcDeny
								onClick={() =>
									confirm({
										locationsIds: [id],
										userId,
									})
								}
								className={
									'w-5 cursor-pointer transition-all ease-in-out duration-200 fill-bummock-disabled_grey_2 hover:fill-bummock-midnight_blue'
								}
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});

	const handleCloseAssignModal = () => {
		setIsVisible(false);
		queryClient.invalidateQueries({
			queryKey: ['locations'],
		});
	};

	return (
		<>
			<ModalConfirmation {...confirmationModalProps} />

			<ModalAssignLocation
				locationsOptions={[...locations.courtyards, ...locations.farms]
					.filter(
						location =>
							![...assignedLocations.courtyards, ...assignedLocations.farms].find(
								assignedLocation => assignedLocation.id === location.id
							)
					)
					.map(({ id, name }) => ({ id, value: name }))}
				isVisible={isVisible}
				onCloseModal={handleCloseAssignModal}
			/>
			<section>
				<Button onClick={() => setIsVisible(true)}>
					<IcPlus className='stroke-2 stroke-bummock-off_white w-[22px]' />
					Asignar Localidad
				</Button>
			</section>
			<section>
				<h2 className='text-xl font-bold mb-4 text-bummock-ocean_blue'>Localidades</h2>
				<BaseTable tableQueryResult={tableQueryResult} />
			</section>
		</>
	);
};

export default LocationsPage;
