import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcBusiness: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 21' fill='none' {...props}>
			<path
				d='M13 19.168V13.768C13 13.2079 13 12.9279 12.891 12.714C12.7951 12.5258 12.6422 12.3729 12.454 12.277C12.2401 12.168 11.9601 12.168 11.4 12.168H8.6C8.03995 12.168 7.75992 12.168 7.54601 12.277C7.35785 12.3729 7.20487 12.5258 7.10899 12.714C7 12.9279 7 13.2079 7 13.768V19.168M1 5.168C1 6.82485 2.34315 8.168 4 8.168C5.65685 8.168 7 6.82485 7 5.168C7 6.82485 8.34315 8.168 10 8.168C11.6569 8.168 13 6.82485 13 5.168C13 6.82485 14.3431 8.168 16 8.168C17.6569 8.168 19 6.82485 19 5.168M4.2 19.168H15.8C16.9201 19.168 17.4802 19.168 17.908 18.95C18.2843 18.7583 18.5903 18.4523 18.782 18.076C19 17.6482 19 17.0881 19 15.968V4.368C19 3.24789 19 2.68784 18.782 2.26002C18.5903 1.88369 18.2843 1.57773 17.908 1.38599C17.4802 1.168 16.9201 1.168 15.8 1.168H4.2C3.0799 1.168 2.51984 1.168 2.09202 1.38599C1.71569 1.57773 1.40973 1.88369 1.21799 2.26002C1 2.68784 1 3.24789 1 4.368V15.968C1 17.0881 1 17.6482 1.21799 18.076C1.40973 18.4523 1.71569 18.7583 2.09202 18.95C2.51984 19.168 3.07989 19.168 4.2 19.168Z'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
