import { apiPrivate } from '../config/apiPrivate';

interface IEditFormParams {
	formId: string;
	formData: {
		name: string;
		items: {
			id?: string;
			label: string;
			order: number;
			isRequerid: boolean;
			defaultValue: string;
			isActive: boolean;
			component: string;
		}[];
	};
}

interface IEditFormResp {}

export const editForm = async ({ formId, formData }: IEditFormParams): Promise<IEditFormResp> => {
	const { data } = await apiPrivate.patch<IEditFormResp>(`/api/workflow/forms/${formId}`, formData);
	return data;
};
