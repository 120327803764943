import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcEnvelop: FC<IIconsProps> = props => (
	<svg viewBox='0 0 26 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M1.3335 5.16675L10.8592 11.8348C11.6306 12.3747 12.0163 12.6447 12.4358 12.7493C12.8064 12.8416 13.194 12.8416 13.5645 12.7493C13.984 12.6447 14.3697 12.3747 15.1411 11.8348L24.6668 5.16675M6.9335 20.3334H19.0668C21.027 20.3334 22.0071 20.3334 22.7558 19.9519C23.4144 19.6164 23.9498 19.081 24.2854 18.4224C24.6668 17.6737 24.6668 16.6936 24.6668 14.7334V7.26675C24.6668 5.30656 24.6668 4.32647 24.2854 3.57778C23.9498 2.91921 23.4144 2.38378 22.7558 2.04823C22.0071 1.66675 21.027 1.66675 19.0668 1.66675H6.9335C4.97331 1.66675 3.99322 1.66675 3.24453 2.04823C2.58596 2.38378 2.05053 2.91921 1.71497 3.57778C1.3335 4.32647 1.3335 5.30656 1.3335 7.26675V14.7334C1.3335 16.6936 1.3335 17.6737 1.71497 18.4224C2.05053 19.081 2.58596 19.6164 3.24453 19.9519C3.99322 20.3334 4.97331 20.3334 6.9335 20.3334Z'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
