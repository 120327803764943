import { IFieldValues } from '@interfaces';
import { Controller, useForm } from 'react-hook-form';
import { FormWrapper } from './FormWrapper';
import { FormInputCheckbox, FormSearchableDropdown } from '@molecules';
import toast from 'react-hot-toast';

interface IListFieldFormValues {
	title: string;
	listId: string;
	isRequired: boolean;
}

interface IListFieldFormProps {
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onDelete: () => void;
}

const BACKEND_LISTS = [
	{
		id: 'd68d6a55-c7e6-4f14-88ab-2477f650dff9',
		value: 'Lista de cliente',
	},
	{
		id: '69211563-43c3-47cf-abbd-d266ab7b95f3',
		value: 'Lista de patios',
	},
	{
		id: '96f311d6-01dc-44ab-b3a8-4a099be9e4bb',
		value: 'Lista de navieras',
	},
	{
		id: '00a6fa6c-bb74-43b6-8908-a89d88a50392',
		value: 'Lista de conductores',
	},
	{
		id: '619a98ab-c415-4420-a6ac-8a50a1ee45ad',
		value: 'Lista de transportistas',
	},
];

export function FormListField({ defaultValues, onSave, onDelete, title }: IListFieldFormProps & { title: string }) {
	const form = useForm<IListFieldFormValues>({
		defaultValues: { ...defaultValues, title, listId: defaultValues.id },
	});

	function handleSubmit(formValues: IListFieldFormValues) {
		console.log('form submitted with values', formValues);
		toast.success('Campo guardado.', { position: 'bottom-right' });
		onSave({
			id: formValues.listId,
			defaultValue: '',
			isRequired: formValues.isRequired,
			label: formValues.title,
		});

		form.reset({
			title,
			listId: formValues.listId,
			isRequired: formValues.isRequired,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onCancel={onDelete}>
			<Controller
				control={form.control}
				name='listId'
				defaultValue=''
				rules={{ required: { value: true, message: 'Selecciona una lista' } }}
				render={({ field: { ref, ...rest } }) => (
					<FormSearchableDropdown
						required
						errorMessage={form.formState.errors.listId?.message}
						onError={!!form.formState.errors.listId}
						label='Listas'
						options={[
							{
								id: '',
								value: 'Seleccionar lista',
							},
							...BACKEND_LISTS,
						]}
						{...rest}
					/>
				)}
			/>
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}
