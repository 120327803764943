import { Button } from '@atoms';
import { FormInput, FormInputPhone, FormSearchableDropdown } from '@molecules';
import { getDirtyFormData } from '@utils';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { usePlaces } from '@hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IFormEditEntityValues } from '@interfaces';
import { useState } from 'react';
import { QUERY_KEYS, updateEntity } from '@api';
import toast from 'react-hot-toast';

interface IFormEditEntityProps {
	entityId: string;
	editingEntity: IFormEditEntityValues;
}

export const FormEditEntity = ({ entityId, editingEntity }: IFormEditEntityProps) => {
	const methods = useForm<IFormEditEntityValues>({
		defaultValues: editingEntity,
	});

	const {
		formState: { errors, isDirty, defaultValues, dirtyFields },
		handleSubmit: handleSubmitWrapper,
		register,
		control,
		reset,
	} = methods;

	const { data: places } = usePlaces();
	const [selectedState, setSelectedState] = useState(places.getStateByCityId(editingEntity.adminCity));
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: updateEntity,
		onSuccess: (_, { id, ...savedFields }) => {
			reset(
				{
					...defaultValues,
					...savedFields,
				},
				{ keepDirty: false }
			);
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.entities, entityId],
			});
			toast.success('Información actualizada.');
		},
	});

	function handleSubmit(formData: IFormEditEntityValues) {
		const dirties = getDirtyFormData(dirtyFields, formData);
		mutation.mutate({
			id: entityId,
			...dirties,
		});
	}

	return (
		<FormProvider {...methods}>
			<form autoComplete='off' onSubmit={handleSubmitWrapper(handleSubmit)}>
				<div className='grid grid-cols-4 gap-x-8 gap-y-4'>
					<FormInput
						type='text'
						label='Razón Social'
						errorMessage={errors.name?.message}
						onError={!!errors.name}
						placeholder='Bummock S.A'
						register={{
							...register('name', {
								required: { value: true, message: 'This field is required' },
							}),
						}}
					/>
					<FormInput
						type='text'
						label='RUC'
						errorMessage={errors.identificationNumber?.message}
						onError={!!errors.identificationNumber}
						placeholder='0999999999001'
						register={{
							...register('identificationNumber', {
								required: { value: true, message: 'This field is required' },
								minLength: {
									value: 10,
									message: 'National ID must be at least 10 characters long',
								},
							}),
						}}
					/>
					<FormInputPhone
						disabled={true}
						label='Número de Contacto'
						errorMessage={errors.contactPhoneNumber?.message}
						onError={!!errors.contactPhoneNumber}
						placeholder='0999999999'
						register={{
							...register('contactPhoneNumber'),
						}}
					/>
					<FormInput
						disabled={true}
						type='email'
						label='Correo Electrónico del Administrador'
						errorMessage={errors.adminEmail?.message}
						onError={!!errors.adminEmail}
						placeholder='admin@company.com'
						register={{
							...register('adminEmail'),
						}}
					/>
					<FormInput
						disabled={true}
						type='text'
						label='Nombre del Administrador'
						errorMessage={errors.adminFirstName?.message}
						onError={!!errors.adminFirstName}
						placeholder='Admin'
						register={{
							...register('adminFirstName', {
								required: { value: true, message: 'This field is required' },
							}),
						}}
					/>
					<FormInput
						disabled={true}
						type='text'
						label='Apellido del Administrador'
						errorMessage={errors.adminLastName?.message}
						onError={!!errors.adminLastName}
						placeholder='Admin'
						register={{
							...register('adminLastName', {
								required: { value: true, message: 'This field is required' },
							}),
						}}
					/>
					<FormInput
						disabled={true}
						type='text'
						label='Cédula'
						errorMessage={errors.adminIdentificationNumber?.message}
						onError={!!errors.adminIdentificationNumber}
						placeholder='0999999999'
						register={{
							...register('adminIdentificationNumber'),
						}}
					/>
					<FormSearchableDropdown
						disabled={true}
						label='Provincia'
						options={places
							.getStatesByCountryId(places.getCountries().find(place => place.name === 'Ecuador')?.id ?? '')
							.map(({ id, name }) => ({ id, value: name }))}
						value={places.getStateByCityId(editingEntity.adminCity)}
						onChange={value => setSelectedState(value)}
					/>
					<Controller
						control={control}
						name='adminCity'
						defaultValue=''
						render={({ field: { ref, ...rest } }) => (
							<FormSearchableDropdown
								disabled={true}
								label='Ciudad'
								options={places.getCitiesByStateId(selectedState).map(({ id, name }) => ({ id, value: name }))}
								{...rest}
							/>
						)}
					/>
					<FormSearchableDropdown
						disabled={true}
						label='País'
						options={[{ id: 'ECUADOR', value: 'Ecuador' }]}
						value={'ECUADOR'}
					/>
				</div>
				<Button isLoading={mutation.isPending} disabled={!isDirty} className='mt-8'>
					Guardar Cambios
				</Button>
				{mutation.isError && <p>Some error ocurred while creating the company</p>}
			</form>
		</FormProvider>
	);
};
