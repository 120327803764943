import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcUserDisabled: FC<IIconsProps> = props => {
	return (
		<svg viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<g clipPath='url(#clip0_1370_4743)'>
				<path d='M18.8 12.745C17.88 11.815 16.64 11.305 15.31 11.305C12.58 11.305 10.42 13.465 10.39 16.225C10.42 18.975 12.58 21.135 15.32 21.135C18.02 21.135 20.22 18.935 20.23 16.225C20.23 14.905 19.73 13.665 18.8 12.735V12.745ZM15.62 19.545C13.89 19.705 12.32 18.495 12.02 16.785C11.98 16.565 11.97 16.385 11.97 16.205C11.97 15.585 12.14 15.005 12.46 14.475L17.05 19.065C16.61 19.335 16.13 19.485 15.61 19.535L15.62 19.545ZM18.17 17.955L13.58 13.365C14.03 13.095 14.51 12.935 15.02 12.895L15.22 12.875H15.32C16.95 12.875 18.34 14.035 18.61 15.645C18.75 16.465 18.61 17.235 18.17 17.955Z' />
				<path d='M8.54004 13.365H7.04004C5.55004 13.365 4.80004 13.365 4.09004 13.575C2.49004 14.065 1.24004 15.305 0.760039 16.905C0.540039 17.615 0.540039 18.365 0.540039 19.855C0.540039 20.405 0.990039 20.855 1.54004 20.855C2.09004 20.855 2.54004 20.405 2.54004 19.855C2.54004 18.605 2.54004 17.915 2.67004 17.485C2.96004 16.525 3.71004 15.775 4.67004 15.485C5.10004 15.355 5.79004 15.355 7.04004 15.355H8.54004C9.09004 15.355 9.54004 14.905 9.54004 14.355C9.54004 13.805 9.09004 13.355 8.54004 13.355V13.365Z' />
				<path d='M15.04 6.365C15.04 3.335 12.57 0.864998 9.54004 0.864998C6.51004 0.864998 4.04004 3.335 4.04004 6.365C4.04004 9.395 6.51004 11.865 9.54004 11.865C12.57 11.865 15.04 9.395 15.04 6.365ZM9.54004 9.865C7.61004 9.865 6.04004 8.295 6.04004 6.365C6.04004 4.435 7.61004 2.865 9.54004 2.865C11.47 2.865 13.04 4.435 13.04 6.365C13.04 8.295 11.47 9.865 9.54004 9.865Z' />
			</g>
			<defs>
				<clipPath id='clip0_1370_4743'>
					<rect width='19.69' height='20.27' fill='white' transform='translate(0.540039 0.864998)' />
				</clipPath>
			</defs>
		</svg>
	);
};
