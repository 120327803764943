import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcEye: FC<IIconsProps> = props => (
	<svg viewBox='0 0 22 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='M1.42012 9.21318C1.28394 8.99754 1.21584 8.88972 1.17772 8.72342C1.14909 8.5985 1.14909 8.4015 1.17772 8.27658C1.21584 8.11028 1.28394 8.00246 1.42012 7.78682C2.54553 6.00484 5.8954 1.5 11.0004 1.5C16.1054 1.5 19.4553 6.00484 20.5807 7.78682C20.7169 8.00246 20.785 8.11028 20.8231 8.27658C20.8517 8.4015 20.8517 8.5985 20.8231 8.72342C20.785 8.88972 20.7169 8.99754 20.5807 9.21318C19.4553 10.9952 16.1054 15.5 11.0004 15.5C5.8954 15.5 2.54553 10.9952 1.42012 9.21318Z'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M11.0004 11.5C12.6573 11.5 14.0004 10.1569 14.0004 8.5C14.0004 6.84315 12.6573 5.5 11.0004 5.5C9.34355 5.5 8.0004 6.84315 8.0004 8.5C8.0004 10.1569 9.34355 11.5 11.0004 11.5Z'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
