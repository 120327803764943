import { useDraggable } from '@dnd-kit/core';
import { IDraggableItem } from '@interfaces';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IcChevronDown, IcDrag, IcTrash } from '@atoms';

export const DraggableItem = ({
	id,
	label,
	onEdit,
	onDelete,
	onMoveUp,
	onMoveDown,
	isBeingEdited = false,
}: IDraggableItem) => {
	const { isDragging } = useDraggable({ id });
	const { attributes, items, index, listeners, setNodeRef, transform, transition } = useSortable({ id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	const canBeMovedUp = index !== 0;
	const canBeMovedDown = index < items.length - 1;

	let titleColor = 'text-bummock-disabled_grey_text';
	if (isBeingEdited) {
		titleColor = 'text-white';
	} else {
		if (label) {
			titleColor = 'text-black';
		} else {
			titleColor = 'text-bummock-disabled_grey_text';
		}
	}

	return (
		<div
			className={`flex duration-300 transition-colors items-center gap-2 p-2 border-bummock-disabled_grey border rounded-lg ${isDragging ? 'bg-bummock-arctic_blue z-50' : isBeingEdited ? 'bg-bummock-ocean_blue' : 'bg-bummock-off_white'}`}
			ref={setNodeRef}
			style={style}
			{...attributes}
			onClick={onEdit}
		>
			<div className='w-6 h-6 cursor-grab flex justify-center items-center' {...listeners}>
				<IcDrag className={`shrink-0 cursor-grab ${isDragging ? 'stroke-white' : ''}`} />
			</div>
			<span className='basis-[2.5rem] text-sm font-bold shrink-0 h-9 rounded-lg flex justify-center items-center bg-bummock-off_white shadow-bummock'>
				{index + 1}.
			</span>
			{label === 'Separador' ? (
				<div className={`h-[1px] flex-grow ${isBeingEdited ? 'bg-white' : 'bg-black'}`}></div>
			) : (
				<span
					className={`select-none cursor-text basis-[17.75rem] grow-[2] text-sm shrink-0 h-9 rounded-lg flex items-center p-4 ${!isBeingEdited ? 'bg-bummock-off_white shadow-bummock' : ''} ${titleColor}`}
				>
					{label || 'Ejemplo: Nombre del cliente'}
				</span>
			)}
			<button
				disabled={!canBeMovedUp}
				onClick={e => {
					e.stopPropagation();
					onMoveUp();
				}}
				className={`w-6 h-6 flex justify-center items-center ${isBeingEdited ? 'stroke-white' : 'stroke-bummock-midnight_blue '} disabled:stroke-bummock-disabled_grey_2 enabled:hover:stroke-slate-500`}
			>
				<IcChevronDown className={`transition-colors rotate-180 w-[0.8rem] stroke-2 stroke-inherit`} />
			</button>
			<button
				disabled={!canBeMovedDown}
				onClick={e => {
					e.stopPropagation();
					onMoveDown();
				}}
				className={`w-6 h-6 flex justify-center items-center ${isBeingEdited ? 'stroke-white' : 'stroke-bummock-midnight_blue '} disabled:stroke-bummock-disabled_grey_2 enabled:hover:stroke-slate-500`}
			>
				<IcChevronDown className={`transition-colors w-[0.8rem] stroke-2 stroke-inherit`} />
			</button>
			<button
				onClick={e => {
					e.stopPropagation();
					onDelete();
				}}
			>
				<IcTrash
					className={`w-[1.3rem] stroke-[1.7] ${isBeingEdited || isDragging ? 'stroke-white' : 'stroke-bummock-disabled_grey_2'} transition-colors ${items.length === 1 ? 'cursor-not-allowed' : 'hover:stroke-red-500'}`}
				/>
			</button>
		</div>
	);
};
