import { Button, IcEdit, IcPlus, IcTrash } from '@atoms';
import { BaseTable, ModalConfirmation, ModalEditForm, ModalNewForm, TablePagination, TableSearchbar } from '@organisms';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { deleteForm, findAllForms, getOneForm, queryClient } from '@api';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useConfirmation, useTableQuery } from '@hooks';

// TODO: Define a dashed button

// One title should be deleted
// Este se usará también para la edición.
// Debe recibir la info del endpoint de detalle y setear el estado "items". En teoría con eso cada formulario se pre-popularía.
const columnHelper = createColumnHelper<{ id: string; name: string }>();
const formsColumns: ColumnDef<{ id: string; name: string }, string>[] = [
	columnHelper.accessor('id', {
		header: 'ID',
		cell: ({ row }) => row.original.id.slice(-6),
		meta: { alignment: 'left' },
	}),
	columnHelper.accessor('name', {
		header: 'Nombre',
		meta: { alignment: 'left' },
	}),
];

const FormsPage = () => {
	const { confirm, confirmationModalProps } = useConfirmation({
		mutationFn: deleteForm,
		onSuccess: () => queryClient.invalidateQueries({ queryKey: ['forms'] }),
	});

	const [editingFormId, setEditingFormId] = useState('');
	const { data } = useQuery({
		queryKey: ['form', editingFormId],
		queryFn: () => getOneForm({ formId: editingFormId }),
		enabled: !!editingFormId,
	});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const tableQueryResult = useTableQuery({
		queryKey: ['forms'],
		fetcherFn: findAllForms,
		tableColumns: [
			...formsColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { id } = row.original;
					return (
						<div className='flex justify-end gap-4'>
							<IcEdit
								onClick={() => handleEditForm(id)}
								className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
							<IcTrash
								onClick={() => confirm({ id })}
								className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});

	function handleEditForm(id: string) {
		// Get form data
		// Show modal with populated data
		setEditingFormId(id);
	}

	function handleCloseEditModal() {
		queryClient.invalidateQueries({
			queryKey: ['form', editingFormId],
		});
		setEditingFormId('');
	}

	console.log('form being edited', data);

	return (
		<>
			<ModalConfirmation {...confirmationModalProps} />

			<ModalEditForm
				initialFormData={data ?? { id: '', name: '', formDetails: [] }}
				isModalVisible={!!data && !!editingFormId}
				onClose={handleCloseEditModal}
			/>

			<ModalNewForm isModalVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />

			<div className='flex justify-between'>
				<TableSearchbar tableQueryResult={tableQueryResult} />
				<div className='flex gap-4'>
					<Button onClick={() => setIsModalVisible(true)}>
						<IcPlus className='stroke-2 stroke-bummock-off_white w-5' />
						Crear Formulario
					</Button>
				</div>
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default FormsPage;

// El botón de guardar debe estar deshabilitado si hay campos pendientes

// Esos formularios creados aparecen en /api/workflow/forms
// pero también hay otros y no está la estructura que necesito para llenar el formulario con los datos por defecto.

// Todos aceptan un valor por defecto, pero para algunos campos esto no tiene sentido.
