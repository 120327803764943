import { useEffect, useState } from 'react';
import { Button, ButtonGoBack, ProgressBar } from '@atoms';
import { FormInput, FormSearchableDropdown } from '@molecules';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useKitTypeDetails, useKitTypes, useKitsUsages } from '@hooks';
import { closeBox, countKits, createKit, QUERY_KEYS } from '@api';
import { skipToken, useMutation, useQuery } from '@tanstack/react-query';
import { FormNewKit } from '@organisms';
import { IFormNewKitValues } from '@interfaces';

interface ISecurityItem {
	productType: {
		id: string;
		name: string;
	};
	items: { value: string }[];
}

// Creo que debería realizarse una petición al momento de seleccionar el tipo de kit. Eso debería retornar la cantidad si ya hay una box en progreso
// con ese tipo. Si no, debería dejarla vacía.

// TODO: This has WAY TOO MANY responsabilities hence it should be broken down into several components. Please do.
const NewKitPage = () => {
	const { firstName, lastName } = useAppSelector(state => state.userProfile);
	const navigator = useNavigate();

	const { data: kitsUsagesResponse } = useKitsUsages();
	const { data: kitsTypesResponse } = useKitTypes();

	const [selectedUsageId, setSelectedUsageId] = useState<string>('');
	const selectedUsage = kitsUsagesResponse?.data.find(usage => usage.id === selectedUsageId);
	const [selectedKitTypeId, setSelectedKitTypeId] = useState<string>('');
	const {
		isLoading: isLoadingKitType,
		isError: isErrorKitType,
		data: selectedKitType,
	} = useKitTypeDetails(selectedKitTypeId);

	const [currentKit, setCurrentKit] = useState<number>(1);
	// TODO: Limitar a 500 esto
	const [quantity, setQuantity] = useState<string>('');
	const hasSelectedKitInfo = !!selectedUsageId && !!selectedKitType && !!quantity;

	const selectedPackaging = selectedKitType?.kitTypeDetail.find(detail => detail.package_container);
	const securityItems =
		selectedKitType?.kitTypeDetail
			.filter(detail => !detail.package_container)
			.reduce((prev, curr) => {
				const newItem = {
					productType: {
						id: curr.productType.id,
						name: curr.productType.name,
					},
					items: Array.from({ length: curr.quantity }).map(() => ({
						value: '',
					})),
				};

				return [...prev, newItem];
			}, [] as ISecurityItem[]) ?? [];

	// Use /api/kits/count/{kitTypeId} to count based on kitTypeId y setear el estado de la barra de progreso en base a eso
	const { data: currentKitCreatedCount } = useQuery({
		queryKey: [QUERY_KEYS.kitCount, selectedKitTypeId],
		queryFn: selectedKitTypeId ? () => countKits({ kitTypeId: selectedKitTypeId }) : skipToken,
	});

	useEffect(() => {
		if (currentKitCreatedCount === undefined) return;
		setCurrentKit(currentKitCreatedCount + 1);
		if (currentKitCreatedCount === 0) {
			setQuantity('50');
			return;
		}
		setQuantity(currentKitCreatedCount + 1 + '');
	}, [currentKitCreatedCount]);

	const createKitMutation = useMutation({
		mutationFn: createKit,
		onSuccess: () => {
			console.log('Created kit');
			const isLastKit = currentKit === +quantity;

			if (isLastKit) {
				navigator('/kits/boxes');
			} else {
				setCurrentKit(currentKit + 1);
				// TODO: This is dead code. Implement a context based solution that uses the `main` container.
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});
			}
		},
	});

	const handleSubmit = ({ packaging, securityItems }: IFormNewKitValues) => {
		console.log('Kit Created with info', packaging, securityItems);
		createKitMutation.mutate({
			kitType: selectedKitTypeId,
			quantity: +quantity,
			// packaging
			inventoryDetail: packaging.backendValue,
			kitDetail: securityItems.flatMap(productType =>
				productType.items.map(({ backendValue }) => ({
					inventoryDetail: backendValue,
				}))
			),
		});
	};

	const closeBoxMutation = useMutation({
		mutationFn: closeBox,
		onSuccess: () => {
			navigator('/kits/boxes');
		},
	});
	const handleCloseBox = () => {
		setQuantity(currentKit + '');
		closeBoxMutation.mutate({
			kitType: selectedKitTypeId,
		});
	};

	const quantityIsTooLow = currentKit === +quantity + 1 || +quantity < 1;

	return (
		<>
			<ButtonGoBack onClick={() => navigator(-1)} />
			<div className='flex flex-col gap-4'>
				<h2 className='text-bummock-ocean_blue text-xl font-bold'>Creación de Kits</h2>
				<p>
					Crea varios Kits del mismo tipo simultáneamente y agrúpalos especificando una cantidad determinada. Puedes
					actualizar la cantidad o finalizar el proceso en cualquier momento.
				</p>
				<div className='grid grid-cols-4 gap-8'>
					<FormInput label='Nombre del Creador' value={`${firstName} ${lastName}`} disabled={true} />
					<FormSearchableDropdown
						label='Uso'
						includeEmptyOption={true}
						className='basis-0 grow'
						options={
							kitsUsagesResponse?.data.map(productType => ({
								id: productType.id,
								value: productType.name,
							})) ?? []
						}
						value={selectedUsageId}
						onChange={value => setSelectedUsageId(value)}
					/>
					<FormSearchableDropdown
						label='Tipo de Kit'
						includeEmptyOption={true}
						className='basis-0 grow'
						options={
							kitsTypesResponse?.data
								.filter(type => type.usage === selectedUsage?.name)
								.map(productType => ({
									id: productType.id,
									value: productType.name,
								})) ?? []
						}
						value={selectedKitTypeId}
						onChange={value => setSelectedKitTypeId(value)}
					/>
					<FormInput
						// disabled={currentKit > 1}
						label='Cantidad'
						onChange={e => {
							setQuantity(e!.target.value);
						}}
						value={quantity}
						placeholder={String(currentKitCreatedCount ?? '-')}
						type='number'
					/>
				</div>
			</div>

			{hasSelectedKitInfo ? (
				quantityIsTooLow ? (
					<p>
						Existe un box en progreso para este tipo de kit con {currentKit - 1} kit, por tanto,{' '}
						<span className='font-bold'>la cantidad seleccionada debe ser superior a {currentKit - 1}.</span>
					</p>
				) : (
					<>
						<div className='flex items-center gap-4'>
							<ProgressBar nth={currentKit} outOf={+quantity} />
							<Button onClick={handleCloseBox} variant='secondary' className='px-10'>
								Finalizar
							</Button>
						</div>

						{isLoadingKitType && 'Loading Kit type...'}

						{!isLoadingKitType && !isErrorKitType && !!selectedKitType && (
							<FormNewKit
								key={selectedKitTypeId}
								className='flex flex-col gap-8 transition-all duration-500'
								kitStructure={{
									packaging: {
										productType: {
											// De-hardcode this
											id: selectedPackaging?.productType.id ?? '',
											name: selectedPackaging?.productType.name ?? '',
										},
									},
									securityItems,
								}}
								currentKit={currentKit}
								onSubmit={handleSubmit}
								onCancel={() => navigator(-1)}
								isSaving={createKitMutation.isPending}
							/>
						)}
					</>
				)
			) : null}
		</>
	);
};

export default NewKitPage;
