import { Modal } from '../Modal';
import { InnerForm } from './InnerForm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { editForm } from '@api';
import { FIELD_TYPES_WITH_IDS } from '@utils';
import styles from './overrides.module.sass';
import { FormModalProvider } from './ctx';

interface IModalEditFormProps {
	initialFormData: {
		id: string;
		name: string;
		formDetails: {
			id: string;
			order: 0; // this could be unnecessary
			label: string;
			defaultValue: string; // This is always empty. Request fix
			// Im not getting the required either
			isActive: boolean;
			componentDataType: {
				id: string;
			};
		}[];
	};
	isModalVisible: boolean;
	onClose: () => void;
}

export const ModalEditForm = ({ initialFormData, isModalVisible, onClose }: IModalEditFormProps) => {
	const queryClient = useQueryClient();
	const editFormMutation = useMutation({
		mutationFn: editForm,
		onSuccess: () => {
			toast.success('Formulario creado exitosamente.', {
				position: 'bottom-right',
			});
			onClose();
			queryClient.invalidateQueries({ queryKey: ['forms'] });
		},
	});

	async function handleSave(
		formData: {
			title: string;
			items: {
				fieldId?: string;
				id: string; // this is the component id
				isRequired: boolean;
				defaultValue: string;
				label: string;
				order: string;
			}[];
		},
		onSuccess?: () => void
	) {
		await editFormMutation.mutateAsync({
			formId: initialFormData.id,
			formData: {
				name: formData.title,
				items: formData.items.map((field, index) => {
					const fieldData = {
						component: field.id,
						defaultValue: field.defaultValue,
						isActive: true,
						label: field.label,
						order: index,
						isRequerid: field.isRequired,
					};

					return {
						...fieldData,
						...(field.fieldId ? { id: field.fieldId } : {}),
					};
				}),
			},
		});
		if (onSuccess) onSuccess();
	}

	return (
		<Modal
			className={`flex flex-col gap-4 min-w-[1100px] ${styles.override}`}
			title='Crear Formulario'
			isOpen={isModalVisible}
			onClose={onClose}
		>
			<FormModalProvider>
				<InnerForm
					key={initialFormData.formDetails.length}
					isLoading={editFormMutation.isPending}
					onClose={onClose}
					initialFormTitle={initialFormData.name}
					initialState={initialFormData.formDetails.map((detail, index) => ({
						id: detail.id,
						backendId: detail.id,
						data: {
							id: detail.componentDataType.id,
							defaultValue: detail.defaultValue,
							isRequired: false,
							label: detail.label,
						},
						isBeingEdited: index === 0,
						isValid: true,
						type: FIELD_TYPES_WITH_IDS[detail.componentDataType.id] ?? 'Lista desplegable',
					}))}
					initialTitle={initialFormData.formDetails.at(0)?.label}
					onSave={handleSave}
				/>
			</FormModalProvider>
		</Modal>
	);
};
