import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcBox: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 16' fill='none' {...props}>
			<path d='M1.23788 12.2797L1.23831 12.2799L7.2844 15.4617C7.40906 15.5523 7.56023 15.6013 7.71375 15.6013H7.72858C7.85912 15.6013 7.98969 15.5661 8.10403 15.4975C8.12204 15.4867 8.13941 15.4749 8.15528 15.4631L14.253 12.2818C14.4919 12.1571 14.6434 11.9082 14.6434 11.638V4.46114C14.6434 4.41691 14.6392 4.37617 14.6335 4.33836L14.6335 4.33836L14.633 4.33544C14.6162 4.23651 14.5785 4.14521 14.5274 4.06543C14.4751 3.98387 14.4063 3.91311 14.3235 3.85725L14.3228 3.85678C14.292 3.83619 14.2594 3.81789 14.2253 3.80178C14.225 3.80164 14.2247 3.8015 14.2244 3.80137L8.05775 0.807823L8.05766 0.807776C7.8565 0.710307 7.62373 0.710307 7.42257 0.807775L7.42225 0.80793L1.25563 3.81379L1.25562 3.81378L1.25427 3.81446L1.2246 3.82929L1.22453 3.82915L1.21851 3.83251C1.15022 3.87068 1.08703 3.91854 1.03313 3.97891L1.03306 3.97885L1.02979 3.98278C1.00133 4.01693 0.973439 4.05455 0.94883 4.09557L0.927454 4.1312V4.13915C0.881537 4.23253 0.853521 4.33761 0.851669 4.44875L0.851648 4.44875V4.45125V4.4529V4.45454V4.45619V4.45784V4.45945V4.45949L0.85 11.6363V11.6363C0.85 11.9064 0.99965 12.1533 1.23788 12.2797ZM2.30514 5.65098L7.01318 8.06774L7.00386 13.6721L2.30359 11.198L2.30514 5.65098ZM13.1931 5.62021V11.196L8.45741 13.6661L8.46674 7.91367L13.1931 5.62021ZM7.74185 6.65092L3.23953 4.45949L7.74185 2.26805L12.2564 4.45949L7.74185 6.65092Z' />
			<path d='M5.62391 2.36353L5.5611 2.333L5.49731 2.36143L3.80321 3.11619L3.51601 3.24415L3.79617 3.38686L9.8471 6.46922L9.81497 10.048L9.81281 10.2889L10.03 10.1845L11.8773 9.29629L11.9615 9.25583L11.9623 9.16247L11.9953 5.55511L11.9961 5.46028L11.9108 5.41883L5.62391 2.36353Z' />
		</svg>
	);
};
