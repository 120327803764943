import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcUserEdit: FC<IIconsProps> = props => {
	return (
		<svg viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<g clipPath='url(#clip0_1370_4747)'>
				<path d='M10.23 20C9.96998 20 9.70998 19.9 9.51998 19.71C9.25998 19.45 9.16998 19.08 9.26998 18.73L10.16 15.63C10.22 15.41 10.26 15.3 10.31 15.2C10.37 15.07 10.44 14.96 10.52 14.85C10.59 14.76 10.66 14.67 10.83 14.51L17.29 8.05C18.37 6.97 20.12 6.97 21.2 8.05C21.72 8.57 22.01 9.27 22.01 10.01C22.01 10.75 21.72 11.44 21.2 11.97L14.74 18.43C14.57 18.6 14.49 18.67 14.4 18.74C14.29 18.82 14.17 18.89 14.05 18.95C13.95 18.99 13.84 19.03 13.62 19.1L10.52 19.99C10.43 20.02 10.34 20.03 10.25 20.03L10.23 20ZM19.23 9.23C19.03 9.23 18.84 9.31 18.69 9.45L12.23 15.91C12.17 15.97 12.14 16 12.11 16.03C12.11 16.05 12.1 16.09 12.08 16.17L11.69 17.54L13.06 17.15C13.14 17.13 13.18 17.12 13.22 17.1C13.22 17.09 13.26 17.05 13.31 17L19.78 10.53C19.93 10.38 20.01 10.19 20.01 9.99C20.01 9.79 19.93 9.59 19.78 9.45C19.63 9.3 19.43 9.22 19.24 9.22L19.23 9.23ZM1.22998 20C0.67998 20 0.22998 19.55 0.22998 19C0.22998 17.51 0.22998 16.76 0.44998 16.05C0.939981 14.45 2.17998 13.2 3.77998 12.72C4.48998 12.51 5.23998 12.51 6.72998 12.51H8.22998C8.77998 12.51 9.22998 12.96 9.22998 13.51C9.22998 14.06 8.77998 14.51 8.22998 14.51H6.72998C5.47998 14.51 4.77998 14.51 4.35998 14.64C3.39998 14.93 2.64998 15.68 2.35998 16.64C2.22998 17.07 2.22998 17.76 2.22998 19.01C2.22998 19.56 1.77998 20.01 1.22998 20.01V20ZM9.22998 11C6.19998 11 3.72998 8.53 3.72998 5.5C3.72998 2.47 6.19998 0 9.22998 0C12.26 0 14.73 2.47 14.73 5.5C14.73 8.53 12.26 11 9.22998 11ZM9.22998 2C7.29998 2 5.72998 3.57 5.72998 5.5C5.72998 7.43 7.29998 9 9.22998 9C11.16 9 12.73 7.43 12.73 5.5C12.73 3.57 11.16 2 9.22998 2Z' />
			</g>
			<defs>
				<clipPath id='clip0_1370_4747'>
					<rect width='21.77' height='20' fill='white' transform='translate(0.22998)' />
				</clipPath>
			</defs>
		</svg>
	);
};
