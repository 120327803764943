import { findAllKitsUsages, QUERY_KEYS } from '@api';
import { useQuery } from '@tanstack/react-query';

export const useKitsUsages = () => {
	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.usages],
		queryFn: () =>
			findAllKitsUsages({
				page: 0,
				limit: 100000,
				sorting: [],
				searchTerm: '',
			}),
	});

	return queryResult;
};
