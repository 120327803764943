import { Button } from '@atoms';
import { FormInput } from '@molecules';
import { useFormContext } from 'react-hook-form';

export namespace FormSupplier {
	export interface IProps {
		onCancel: () => void;
		onSubmit: () => void;
		isLoading: boolean;
		canSave?: boolean;
	}

	export interface IFormSupplierValues {
		name: string;
		nationalId: string;
	}
}

export const FormSupplier = ({ onCancel, onSubmit, isLoading, canSave = true }: FormSupplier.IProps) => {
	const { register, formState } = useFormContext<FormSupplier.IFormSupplierValues>();

	return (
		<form className='mt-8 flex flex-col gap-8' onSubmit={onSubmit} autoComplete='off'>
			<FormInput
				disabled={isLoading}
				label='Nombre'
				placeholder='Name'
				required={true}
				onError={!!formState.errors.name}
				errorMessage={formState.errors.name?.message}
				register={register('name', {
					required: { value: true, message: 'This field is required' },
				})}
			/>
			<FormInput
				disabled={isLoading}
				label='Identificación'
				placeholder='0999999999'
				required={true}
				onError={!!formState.errors.nationalId}
				errorMessage={formState.errors.nationalId?.message}
				register={register('nationalId', {
					required: { value: true, message: 'This field is required' },
				})}
			/>
			<div className='flex gap-4'>
				<Button disabled={isLoading} type='button' onClick={onCancel} className='px-9' variant='secondary'>
					Cancelar
				</Button>
				<Button disabled={!canSave} isLoading={isLoading} className='px-9'>
					Guardar
				</Button>
			</div>
		</form>
	);
};
