import { IFieldValues } from '@interfaces';
import { useForm } from 'react-hook-form';
import { FormWrapper } from './FormWrapper';
import { FormInputCheckbox } from '@molecules';

interface IPhotoFieldFormValues {
	title: string;
	isRequired: boolean;
}

interface IPhotoFieldFormProps {
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onDelete: () => void;
}

export function FormPhotoField({ defaultValues, onSave, onDelete, title }: IPhotoFieldFormProps & { title: string }) {
	const id = '5e5195a2-8da4-422b-8861-ea6b612ca220';
	const form = useForm<IPhotoFieldFormValues>({
		defaultValues: { ...defaultValues, title: defaultValues.label },
	});

	function handleSubmit(formValues: IPhotoFieldFormValues) {
		console.log('form submitted with values', formValues);
		onSave({
			id,
			label: formValues.title,
			defaultValue: '',
			isRequired: formValues.isRequired,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onCancel={onDelete}>
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}
