import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcArrowBack: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='20' height='15' viewBox='0 0 20 15' fill='none' {...props}>
			<path
				d='M1 5H14.5C16.9853 5 19 7.01472 19 9.5C19 11.9853 16.9853 14 14.5 14H10M1 5L5 1M1 5L5 9'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
