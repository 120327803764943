import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormProductType, Modal } from '@organisms';
import { createProductType, QUERY_KEYS } from '@api';
import { AxiosError } from 'axios';
import { IModalProps } from '@interfaces';

interface IFormNewProductTypeValues {
	name: string;
}

export const ModalNewProductType = ({ isVisible, onCloseModal }: IModalProps) => {
	const form = useForm<IFormNewProductTypeValues>();
	const { handleSubmit: handleSubmitWrapper, reset, setError } = form;
	useEffect(() => {
		isVisible && reset();
	}, [isVisible]);

	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationKey: ['categories'],
		mutationFn: createProductType,
		onSuccess: () => {
			onCloseModal();
			queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.productsTypes] });
		},
		onError: (error: AxiosError) => {
			if (error.response?.status === 400) {
				setError('name', { message: 'Tipo de producto ya existe' }, { shouldFocus: true });
			} else {
				throw error;
			}
		},
	});

	const handleSubmit = ({ name }: IFormNewProductTypeValues) => {
		mutation.mutate({
			name,
		});
	};

	return (
		<Modal
			className='p-10 w-full max-w-[838px]'
			title='Creación de Tipo de Producto'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<p className='text-2xs w-3/4'>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
				magna aliqua.
			</p>
			<FormProvider {...form}>
				<FormProductType
					isLoading={mutation.isPending}
					onSubmit={handleSubmitWrapper(handleSubmit)}
					onCancel={onCloseModal}
				/>
			</FormProvider>
		</Modal>
	);
};
