import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcUsers: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='22' height='21' viewBox='0 0 22 21' fill='none' {...props}>
			<path
				d='M21 19.668V17.668C21 15.8042 19.7252 14.2381 18 13.794M14.5 1.95876C15.9659 2.55215 17 3.98931 17 5.668C17 7.34669 15.9659 8.78385 14.5 9.37724M16 19.668C16 17.8042 16 16.8723 15.6955 16.1373C15.2895 15.1572 14.5108 14.3785 13.5307 13.9725C12.7956 13.668 11.8638 13.668 10 13.668H7C5.13623 13.668 4.20435 13.668 3.46927 13.9725C2.48915 14.3785 1.71046 15.1572 1.30448 16.1373C1 16.8723 1 17.8042 1 19.668M12.5 5.668C12.5 7.87714 10.7091 9.668 8.5 9.668C6.29086 9.668 4.5 7.87714 4.5 5.668C4.5 3.45886 6.29086 1.668 8.5 1.668C10.7091 1.668 12.5 3.45886 12.5 5.668Z'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
