import { Link, useNavigate } from 'react-router-dom';

import { Button, IcArrowLeft, IcCheckCircle } from '@atoms';
import { FormHeader } from '@molecules';
import { FormNewPassword } from '@organisms';
import { useAppSelector } from '@hooks';

const NewPasswordPage = () => {
	const { newPasswordSuccess } = useAppSelector(state => state.auth);
	const navigator = useNavigate();

	if (newPasswordSuccess) {
		return (
			<main className='flex flex-col items-center gap-y-8'>
				<FormHeader
					icon={<IcCheckCircle className='w-6 fill-success-500' />}
					title='Contraseña restablecida con éxito.'
					description='Se ha restablecido su contraseña con éxito. Continúe para iniciar sesión.'
				/>
				<Button className='w-full' onClick={() => navigator('/login')}>
					Iniciar sesión
				</Button>
			</main>
		);
	}

	return (
		<main className='flex flex-col items-center gap-y-8'>
			<FormHeader
				title='Crear una nueva contraseña'
				description='La nueva contraseña debe ser diferente de las contraseñas que haya utilizado anteriormente.'
			/>
			<FormNewPassword />
			<Link to='/login' className='self-center w-fit inline-flex gap-x-2 font-semibold text-bummock-midnight_blue'>
				<IcArrowLeft className='w-3.5 stroke-2 stroke-bummock-midnight_blue' />
				Iniciar sesión
			</Link>
		</main>
	);
};

export default NewPasswordPage;
