import { findAllWorkflows, QUERY_KEYS } from '@api';
import { Badge, Button, IcPlus } from '@atoms';
import { useTableQuery } from '@hooks';
import { IWorkflow } from '@interfaces';
import { BaseTable, TablePagination } from '@organisms';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<IWorkflow>();
const workflowsColumns = [
	columnHelper.accessor('title', {
		header: 'Nombre de Flujo',
		cell: ({ row }) => <span className='capitalize'>{row.original.title.toLowerCase()}</span>,
	}),
	columnHelper.accessor('description', {
		header: 'Descripción',
		cell: ({ row }) => <span className='capitalize'>{row.original.description?.toLowerCase() ?? ''}</span>,
	}),
	columnHelper.accessor('isShared', {
		header: 'Es compartido',
		cell: ({ row }) => (
			<Badge variant={row.original.isShared ? 'success' : 'warning'} label={row.original.isShared ? 'Sí' : 'No'} />
		),
		meta: { alignment: 'center' },
	}),
];

const WorkflowsPage = () => {
	const tableQueryResult = useTableQuery({
		queryKey: [QUERY_KEYS.workflows],
		fetcherFn: findAllWorkflows,
		tableColumns: workflowsColumns,
	});

	return (
		<>
			<Button onClick={() => alert('TODO.')}>
				<IcPlus className='stroke-2 stroke-bummock-off_white w-[22px]' />
				Crear Nuevo Flujo de Trabajo
			</Button>
			<section className='flex-grow'>
				<h2 className='text-bummock-ocean_blue text-xl font-bold mb-4'>Flujos de Trabajo</h2>
				<BaseTable tableQueryResult={tableQueryResult} />
			</section>
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default WorkflowsPage;
