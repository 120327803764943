import { useMemo, useState } from 'react';

import { v4 } from 'uuid';

import { FormLabel, IcEye, IcEyeOff } from '@atoms';

import type { IFormInputPasswordProps } from './FormInputPassword.interfaces';

import styles from './FormInputPassword.module.sass';

export const FormInputPassword = ({
	className = '',
	disabled,
	errorMessage,
	label,
	name,
	onChange,
	onError,
	pattern,
	placeholder,
	required,
	register,
	value,
}: IFormInputPasswordProps) => {
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const id = useMemo(() => v4(), []);

	return (
		<fieldset className={`${styles['fieldset']} ${className}`}>
			<FormLabel {...{ disabled, id, label, onError, required }} />
			<div className='relative'>
				<input
					aria-disabled={disabled}
					aria-required={required}
					data-has-error={onError}
					className='pr-8'
					type={showPassword ? 'text' : 'password'}
					{...{
						disabled,
						id,
						name,
						onChange,
						pattern,
						placeholder,
						required,
						value,
					}}
					{...register}
				/>
				{showPassword ? (
					<IcEyeOff className={styles['fieldset__icon']} onClick={() => setShowPassword(false)} />
				) : (
					<IcEye className={styles['fieldset__icon']} onClick={() => setShowPassword(true)} />
				)}
			</div>
			{onError ? <span className={styles['fieldset--error__message']}>{errorMessage}</span> : null}
		</fieldset>
	);
};
