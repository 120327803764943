import { IFieldValues } from '@interfaces';
import { useForm } from 'react-hook-form';
import { FormWrapper } from './FormWrapper';
import { FormInputCheckbox } from '@molecules';

interface ITitleFieldFormValues {
	title: string;
	isRequired: boolean;
}

interface ITitleFieldFormProps {
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onDelete: () => void;
}

export function FormTitleField({ defaultValues, onSave, onDelete, title }: ITitleFieldFormProps & { title: string }) {
	const id = 'b8cd4987-9c7a-4b0d-ae5f-4f2daa58c7b8';
	const form = useForm<ITitleFieldFormValues>({
		defaultValues: { ...defaultValues, title: defaultValues.label },
	});

	function handleSubmit(formValues: ITitleFieldFormValues) {
		console.log('form submitted with values', formValues);
		onSave({
			id,
			defaultValue: '',
			isRequired: false,
			label: formValues.title,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onCancel={onDelete}>
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}
