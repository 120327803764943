import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

interface IFormContext {
	canSubmit: boolean;
	setCanSubmit: Dispatch<SetStateAction<boolean>>;
	showError: boolean;
	setShowError: Dispatch<SetStateAction<boolean>>;
}

const FormModalContext = createContext<IFormContext>({} as IFormContext);

export const useFormModalContext = () => useContext(FormModalContext);

export function FormModalProvider({ children }: { children: ReactNode }) {
	const [canSubmit, setCanSubmit] = useState(false);
	const [showError, setShowError] = useState(false);

	return (
		<FormModalContext.Provider
			value={{
				canSubmit,
				setCanSubmit,
				showError,
				setShowError,
			}}
		>
			{children}
		</FormModalContext.Provider>
	);
}
