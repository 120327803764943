import { IPaginatedResource, ISeal } from '@interfaces';
import { SortingState } from '@tanstack/react-table';

interface IFindAllSealsParams {
	page: number;
	sorting: SortingState;
	searchTerm: string;
}

const seals: ISeal[] = [
	{
		id: '018328',
		encryptionDate: '02-Abr-2024',
		encryptionTime: '12:36:54',
		encryptedBy: 'Daniel Guzman',
		clientAssignedTo: 'Risk Protection S.A',
		status: 'No Asignado',
	},
	{
		id: '018329',
		encryptionDate: '02-Abr-2024',
		encryptionTime: '12:36:54',
		encryptedBy: 'Daniel Guzman',
		clientAssignedTo: 'Risk Protection S.A',
		status: 'Activo',
	},
	{
		id: '018330',
		encryptionDate: '02-Abr-2024',
		encryptionTime: '12:36:54',
		encryptedBy: 'Daniel Guzman',
		clientAssignedTo: 'Risk Protection S.A',
		status: 'No Asignado',
	},
	{
		id: '018331',
		encryptionDate: '02-Abr-2024',
		encryptionTime: '12:36:54',
		encryptedBy: 'Daniel Guzman',
		clientAssignedTo: 'Risk Protection S.A',
		status: 'Activo',
	},
	{
		id: '018332',
		encryptionDate: '02-Abr-2024',
		encryptionTime: '12:36:54',
		encryptedBy: 'Daniel Guzman',
		clientAssignedTo: 'No Asignado',
		status: 'No Asignado',
	},
	{
		id: '018333',
		encryptionDate: '02-Abr-2024',
		encryptionTime: '12:36:54',
		encryptedBy: 'Daniel Guzman',
		clientAssignedTo: 'No Asignado',
		status: 'No Asignado',
	},
];

export const findAllSeals = async (_params: IFindAllSealsParams): Promise<{ data: ISeal[] } & IPaginatedResource> => {
	// TODO: Implement this when endpoint is ready

	return {
		data: seals,
		meta: {
			currentPage: 1,
			itemsPerPage: seals.length,
			totalItems: seals.length,
			totalPages: 1,
		},
	};
};
