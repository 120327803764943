import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcUserCheck: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 46 42' fill='none' {...props}>
			<path
				d='M23.002 28H14.002C11.2108 28 9.81526 28 8.67967 28.3445C6.12286 29.1201 4.12203 31.1209 3.34643 33.6777C3.00195 34.8133 3.00195 36.2089 3.00195 39M31.002 33L35.002 37L43.002 29M28.002 12C28.002 16.9706 23.9725 21 19.002 21C14.0314 21 10.002 16.9706 10.002 12C10.002 7.02944 14.0314 3 19.002 3C23.9725 3 28.002 7.02944 28.002 12Z'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
