import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcUpload2: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 21' fill='none' {...props}>
			<path
				d='M18.998 13.5V14.7C18.998 16.3802 18.998 17.2202 18.6711 17.862C18.3834 18.4265 17.9245 18.8854 17.36 19.173C16.7183 19.5 15.8782 19.5 14.198 19.5H5.79805C4.11789 19.5 3.27781 19.5 2.63608 19.173C2.07159 18.8854 1.61265 18.4265 1.32503 17.862C0.998047 17.2202 0.998047 16.3802 0.998047 14.7V13.5M14.998 6.5L9.99805 1.5M9.99805 1.5L4.99805 6.5M9.99805 1.5V13.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
