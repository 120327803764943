import { Button } from '@atoms';
import { FormInput } from '@molecules';
import { useFormContext } from 'react-hook-form';

interface IFormCategoryProps {
	onCancel: () => void;
	onSubmit: () => void;
	isLoading: boolean;
	canSave?: boolean;
}

interface IFormCategoryValues {
	name: string;
	description: string;
}

export const FormCategory = ({ onCancel, onSubmit, isLoading, canSave = true }: IFormCategoryProps) => {
	const { register, formState } = useFormContext<IFormCategoryValues>();

	return (
		<form className='mt-8 flex flex-col gap-8' onSubmit={onSubmit} autoComplete='off'>
			<FormInput
				disabled={isLoading}
				label='Nombre'
				placeholder='Name'
				required={true}
				onError={!!formState.errors.name}
				errorMessage={formState.errors.name?.message}
				register={register('name', {
					required: { value: true, message: 'This field is required' },
				})}
			/>
			<FormInput
				disabled={isLoading}
				label='Descripción'
				placeholder='Description'
				required={true}
				onError={!!formState.errors.description}
				errorMessage={formState.errors.description?.message}
				register={register('description', {
					required: { value: true, message: 'This field is required' },
				})}
			/>
			<div className='flex gap-4'>
				<Button disabled={isLoading} type='button' onClick={onCancel} className='px-9' variant='secondary'>
					Cancelar
				</Button>
				<Button disabled={!canSave} isLoading={isLoading} className='px-9'>
					Guardar
				</Button>
			</div>
		</form>
	);
};
