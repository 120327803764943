import { useEffect } from 'react';

import type { FC } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';

import type { SubmitHandler } from 'react-hook-form';

import { addProduct } from '@api';
import { Button } from '@atoms';
import { FormInput, FormSearchableDropdown } from '@molecules';
import { Modal } from '@organisms';
import { useCategories, useProductsTypes } from '@hooks';

import type { IModalProps } from '@interfaces';

import type { IFormNewKitTypeValues } from './ModalNewProduct.interfaces';

export const ModalNewProduct: FC<IModalProps> = ({ isVisible = true, onCloseModal = () => {} }) => {
	const {
		formState: { errors, isValid },
		register,
		control,
		handleSubmit: handleSubmitWrapper,
		reset,
	} = useForm<IFormNewKitTypeValues>({
		defaultValues: {
			name: '',
			description: '',
			categoryId: '',
			productTypeId: '',
		},
	});

	const { data: categoriesResponse } = useCategories();
	const { data: productsTypesResponse } = useProductsTypes();
	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: addProduct,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['inventory'],
			});
			onCloseModal();
		},
	});

	const handleSubmit: SubmitHandler<IFormNewKitTypeValues> = formValues => {
		mutation.mutate(formValues);
	};

	useEffect(() => {
		if (isVisible) reset();
	}, [isVisible]);

	return (
		<Modal
			className='min-w-0 w-full !max-w-[50rem]'
			title='Agregar Producto Nuevo'
			closeButton={true}
			isOpen={isVisible}
			onClose={onCloseModal}
		>
			<form className='mt-8' onSubmit={handleSubmitWrapper(handleSubmit)} autoComplete='off'>
				<div className='grid grid-cols-1 gap-8 mb-8'>
					<FormInput
						className='basis-0 grow'
						register={register('name', {
							required: { value: true, message: 'This field is required' },
						})}
						required={true}
						errorMessage={errors.name?.message}
						onError={!!errors.name}
						label='Nombre de Producto'
						placeholder='Product XYZ'
					/>
				</div>

				<div className='flex gap-8 mb-8'>
					<Controller
						control={control}
						name='categoryId'
						rules={{
							required: { value: true, message: 'This field is required' },
						}}
						render={({ field: { ref, ...rest }, fieldState: { error } }) => (
							<FormSearchableDropdown
								includeEmptyOption={true}
								className='basis-0 grow'
								label='Categoría'
								required={true}
								options={
									categoriesResponse?.data.map(category => ({
										id: category.id,
										value: category.name,
									})) ?? []
								}
								onError={!!error?.message}
								errorMessage={error?.message}
								{...rest}
							/>
						)}
					/>
					<Controller
						control={control}
						name='productTypeId'
						rules={{
							required: { value: true, message: 'This field is required' },
						}}
						render={({ field: { ref, ...rest }, fieldState: { error } }) => (
							<FormSearchableDropdown
								includeEmptyOption={true}
								className='basis-0 grow'
								label='Tipo'
								required={true}
								options={
									productsTypesResponse.map(productType => ({
										id: productType.id,
										value: productType.name,
									})) ?? []
								}
								onError={!!error?.message}
								errorMessage={error?.message}
								{...rest}
							/>
						)}
					/>
				</div>

				<div className='grid grid-cols-1 gap-8 mb-8'>
					<FormInput
						className='basis-0 grow'
						register={register('description', {
							required: { value: true, message: 'This field is required' },
						})}
						required={true}
						errorMessage={errors.name?.message}
						onError={!!errors.name}
						label='Description'
						placeholder='Product description'
					/>
				</div>

				<div className='flex gap-4 mt-8'>
					<Button onClick={onCloseModal} type='button' variant='secondary' className='w-[9.375rem]'>
						Cancelar
					</Button>
					<Button isLoading={mutation.isPending} disabled={!isValid} className='w-[9.375rem]'>
						Guardar
					</Button>
				</div>
			</form>
		</Modal>
	);
};
