import { ReactNode } from 'react';
import { IItem } from '@interfaces';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

interface IDraggableListProps {
	items: Omit<IItem, 'label'>[];
	setItems: (
		stateUpdaterFn: (oldState: Omit<IItem, 'label'>[]) => {
			oldIndex: number;
			newIndex: number;
		}
	) => void;
	children: ReactNode;
}

export const DraggableList = ({ items, setItems, children }: IDraggableListProps) => {
	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		if (active.id !== over?.id) {
			setItems(items => {
				const oldIndex = items.findIndex(item => item.id === active.id);
				const newIndex = items.findIndex(item => item.id === over?.id);
				return { oldIndex, newIndex };
			});
		}
	};

	return (
		<DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
			<SortableContext items={items.map(item => item.id)} strategy={verticalListSortingStrategy}>
				{children}
			</SortableContext>
		</DndContext>
	);
};
