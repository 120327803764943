import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcSpinner: FC<IIconsProps> = ({ className, ...rest }) => {
	return (
		<svg
			className={`animate-spin ${className}`}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 22 23'
			fill='none'
			{...rest}
		>
			<path
				d='M11 1.75V4.25M11 17.5V21.5M4.75 11.5H1.25M20.25 11.5H18.75M17.4571 17.9571L16.75 17.25M17.6642 4.91579L16.25 6.33M3.92157 18.5784L6.75 15.75M4.12868 4.70868L6.25 6.83'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
