import { IFieldValues } from '@interfaces';
import { useForm } from 'react-hook-form';
import { FormWrapper } from './FormWrapper';
import { FormInput, FormInputCheckbox } from '@molecules';
import toast from 'react-hot-toast';

interface INumberFieldFormValues {
	title: string;
	defaultValue: string;
	isRequired: boolean;
}

interface INumberFieldFormProps {
	defaultValues: INumberFieldFormValues;
	onSave: (formValues: IFieldValues) => void;
	onDelete: () => void;
}

export function FormNumberField({ onSave, defaultValues, onDelete, title }: INumberFieldFormProps & { title: string }) {
	const id = '8577c344-fcbd-49ee-9264-28cf9de3da05';
	const form = useForm<INumberFieldFormValues>({
		defaultValues,
	});

	function handleSubmit(formValues: INumberFieldFormValues) {
		console.log('form submitted with values', formValues);
		toast.success('Campo guardado.', { position: 'bottom-right' });
		onSave({
			id,
			label: title,
			defaultValue: formValues.defaultValue,
			isRequired: formValues.isRequired,
		});

		form.reset({
			title,
			defaultValue: formValues.defaultValue,
			isRequired: formValues.isRequired,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onCancel={onDelete}>
			<FormInput type='number' label='Valor predeterminado' placeholder='0' register={form.register('defaultValue')} />
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}
