import { IcArrowBack } from '@atoms';
import { ReactNode, useEffect, useState } from 'react';

interface IStepperItemProps {
	children: ReactNode;
	isActive?: boolean;
	isCompleted?: boolean;
	onClick?: () => void;
	className?: string;
}

const StepperItem = ({
	children,
	isActive = false,
	isCompleted = false,
	onClick,
	className = '',
}: IStepperItemProps) => {
	return (
		<li onClick={onClick} className={`relative w-10 h-10`}>
			{/* TODO: REFACTOR THIS SHIT */}
			<span
				className={`absolute w-[140%] h-1/4 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-bummock-off_white`}
			></span>
			<span
				className={`absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 ${isActive ? 'bg-bummock-artic_blue opacity-50 delay-100' : ''} z-0 w-[calc(100%+8px)] h-[calc(100%+8px)] rounded-full transition-colors duration-500`}
			></span>
			<span
				className={`w-full h-full relative z-10 flex justify-center items-center font-bold text-base rounded-full transition-all ease-in-out duration-500 ${isActive ? 'bg-bummock-artic_blue text-bummock-arctic_white' : isCompleted ? 'bg-bummock-midnight_blue' : 'text-bummock-disabled_grey_2 shadow-bummock bg-white'} ${className}`}
			>
				{isCompleted ? (
					<div className='flex justify-center items-center rounded-full w-6 h-6 border-2 border-bummock-arctic_white'>
						<svg className='w-[1.375rem] shrink-0' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M6.25 10L8.75 12.5L13.75 7.5'
								stroke='white'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					</div>
				) : (
					children
				)}
			</span>
		</li>
	);
};

interface IStepperProps {
	stepsQuantity?: number;
	className?: string;
	currentStep: number;
	onChangeStep?: (step: number) => void;
}

export const Stepper = ({ className, currentStep, stepsQuantity = 3, onChangeStep = () => {} }: IStepperProps) => {
	const [steps, setSteps] = useState(() => {
		return Array.from({ length: stepsQuantity }).map((_, index) => ({
			number: index + 1,
			isComplete: false,
		}));
	});

	useEffect(() => {
		// Once the users goes to the next step the last one is considered to be completed
		setSteps(prev =>
			prev.map(prevStep => (currentStep === prevStep.number + 1 ? { ...prevStep, isComplete: true } : prevStep))
		);
	}, [currentStep]);

	const lastCompletedStep = [...steps].reverse().find(step => step.isComplete)?.number ?? 0;
	const barWidthPercentage = (
		((currentStep > lastCompletedStep ? currentStep : lastCompletedStep) / stepsQuantity) *
		100
	).toFixed(2);

	return (
		<ul className={`w-[25rem] relative flex justify-between ${className}`}>
			<div className='absolute w-full transition-sizing duration-500 h-[0.1875rem] top-1/2 -translate-y-1/2 bg-bummock-disabled_grey'></div>
			<div
				style={{
					width: `${barWidthPercentage}%`,
				}}
				className='absolute transition-sizing duration-500 h-[0.1875rem] top-1/2 -translate-y-1/2 bg-bummock-artic_blue'
			></div>
			<StepperItem
				onClick={() => onChangeStep(currentStep - 1)}
				className='border border-bummock-midnight_blue bg-white cursor-pointer'
				isCompleted={currentStep === stepsQuantity + 1}
			>
				<IcArrowBack className='w-4.5 stroke-2 stroke-bummock-midnight_blue' />
			</StepperItem>
			{steps.map((step, index) => (
				<StepperItem
					key={step.number}
					isActive={currentStep === step.number}
					isCompleted={step.isComplete}
					// if the step is completed or the previus step is, make it clickable
					{...(step.isComplete || steps[index - 1]?.isComplete
						? {
								className: 'cursor-pointer',
								onClick: () => onChangeStep(step.number),
							}
						: {})}
				>
					{step.number}
				</StepperItem>
			))}
		</ul>
	);
};
