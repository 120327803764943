import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcMinus: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 2' fill='none' {...props}>
			<path d='M1 1.00012H15' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	);
};
