import { getFormsUnpaginated } from '@api';
import { useQuery } from '@tanstack/react-query';

export const useFormsSelectData = () => {
	const {
		isLoading,
		isError,
		data = [],
	} = useQuery({
		queryFn: getFormsUnpaginated,
		queryKey: ['forms-select'],
	});

	return {
		isLoading,
		isError,
		forms: data.map(item => ({
			id: item.id,
			value: item.name,
		})),
	};
};
