import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcPistolScan: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 17' fill='none' {...props}>
			<path
				d='M5.67001 1.75H5.52001C4.2599 1.75 3.62984 1.75 3.14853 1.99524C2.72517 2.21095 2.38096 2.55516 2.16525 2.97852C1.92001 3.45982 1.92001 4.08988 1.92001 5.35V5.5M5.67001 15.25H5.52001C4.2599 15.25 3.62984 15.25 3.14853 15.0048C2.72517 14.789 2.38096 14.4448 2.16525 14.0215C1.92001 13.5402 1.92001 12.9101 1.92001 11.65V11.5M15.42 5.5V5.35C15.42 4.08988 15.42 3.45982 15.1748 2.97852C14.9591 2.55516 14.6149 2.21095 14.1915 1.99524C13.7102 1.75 13.0801 1.75 11.82 1.75H11.67M15.42 11.5V11.65C15.42 12.9101 15.42 13.5402 15.1748 14.0215C14.9591 14.4448 14.6149 14.789 14.1915 15.0048C13.7102 15.25 13.0801 15.25 11.82 15.25H11.67M1.92001 8.5H1.92751M5.29501 8.5H5.30251M12.045 8.5H12.0525M8.67001 8.5H8.67751M15.42 8.5H15.4275'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
