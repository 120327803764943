import { Fragment, useReducer, useRef, useState } from 'react';

import { arrayMove } from '@dnd-kit/sortable';
import { Controller, useForm } from 'react-hook-form';
import { v4 } from 'uuid';

import { Button, DashedContainer, IcAdd } from '@atoms';
import { DraggableItem, DraggableList, FormInput, FormSearchableDropdown } from '@molecules';
import { FormInputCheckbox } from '@molecules';
import { PROCESSES_STEP_TYPES, PROCESSES_STEP_TYPES_WITH_ID } from '@utils';
import { useFormModalContext } from '../Forms/ctx';
import { useFormsSelectData } from '@hooks';
import { FormWrapper } from '@organisms';

import type { Field, IFieldValues } from './interfaces';
import type { IItem } from '@interfaces';

interface IFormImageFormValues {
	defaultImage: string;
	isRequired: boolean;
}

interface IFormImageFormProps {
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onDelete: () => void;
}

function FormImage({ defaultValues, onSave, onDelete, title }: IFormImageFormProps & { title: string }) {
	const id = ''; // This id should come from each item I think
	const form = useForm<IFormImageFormValues>({
		// Creo que necesito un campo más para esto ".id" no funcionaría
		defaultValues: { ...defaultValues, defaultImage: defaultValues.id },
	});

	function handleSubmit(formValues: IFormImageFormValues) {
		console.log('form submitted with values', formValues);
		onSave({ id, isRequired: formValues.isRequired, label: '' });
		form.reset({
			defaultImage: formValues.defaultImage,
			isRequired: formValues.isRequired,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onCancel={onDelete}>
			<div className='flex gap-4 items-end'>
				{/* Tal vez este no debería ser un campo, solo mostrar el nombre del archivo */}
				<FormInput
					// className='h-[7.5rem]'
					label='Imagen Preestablecida'
					placeholder='Imagen'
					register={form.register('defaultImage')}
				/>
				<Button className='h-[2.5rem]'>Cargar Imagen</Button>
			</div>
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}

interface IFormSelectorFormValues {
	// title: string;
	formId: string;
	isRequired: boolean;
}

interface IFormSelectorFormProps {
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onDelete: () => void;
}

function FormSelectorForm({ defaultValues, onSave, onDelete, title }: IFormSelectorFormProps & { title: string }) {
	const { forms } = useFormsSelectData();
	const form = useForm<IFormSelectorFormValues>({
		defaultValues: { ...defaultValues, formId: defaultValues.id },
	});

	function handleSubmit(formValues: IFormSelectorFormValues) {
		console.log('form submitted with values', formValues);
		onSave({
			id: formValues.formId,
			isRequired: formValues.isRequired,
			label: '',
		});
		form.reset({
			formId: formValues.formId,
			isRequired: formValues.isRequired,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onCancel={onDelete}>
			<Controller
				control={form.control}
				name='formId'
				defaultValue=''
				rules={{ required: { value: true, message: 'Selecciona una lista' } }}
				render={({ field: { ref, ...rest } }) => (
					<FormSearchableDropdown
						required
						errorMessage={form.formState.errors.formId?.message}
						onError={!!form.formState.errors.formId}
						label='Formularios'
						options={[
							{
								id: '',
								value: 'Seleccionar formulario',
							},
							...forms,
						]}
						{...rest}
					/>
				)}
			/>
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}

interface ITitleFieldFormValues {
	title: string;
	isRequired: boolean;
}

interface ITitleFieldFormProps {
	id: string;
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onDelete: () => void;
}

// I think title is unnecessary here
export function TitleForm({ id, defaultValues, onSave, onDelete, title }: ITitleFieldFormProps & { title: string }) {
	const form = useForm<ITitleFieldFormValues>({
		defaultValues: { ...defaultValues, title: defaultValues.label },
	});

	function handleSubmit(formValues: ITitleFieldFormValues) {
		console.log('form submitted with values', formValues);
		onSave({ id, isRequired: formValues.isRequired, label: formValues.title });
		form.reset({
			title,
			isRequired: formValues.isRequired,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onCancel={onDelete}>
			<FormInputCheckbox label='Este paso es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}

type Action =
	| {
			type: 'add' | 'reset';
	  }
	| {
			type: 'cancel';
			payload: { id: string };
	  }
	| {
			type: 'replace';
			payload: Field[];
	  }
	| {
			type: 'edit';
			payload: string;
	  }
	| {
			type: 'delete';
			payload: string;
	  }
	| {
			type: 'changeType';
			payload: {
				fieldId: string;
				newType: (typeof PROCESSES_STEP_TYPES)[number];
			};
	  }
	| {
			type: 'saveFormData';
			payload: {
				fieldId: string;
				formData: IFieldValues;
			};
	  };

const INITIAL_STATE: Field[] = [
	{
		id: v4(),
		// TODO: Add nullable backend ID
		backendId: '',
		isBeingEdited: true,
		isValid: false,
		type: '' as 'Formulario',
		data: {
			id: '',
			label: '',
			isRequired: true,
		},
	},
];

function reducer(oldState: Field[], action: Action): Field[] {
	switch (action.type) {
		case 'reset': {
			return INITIAL_STATE;
		}
		case 'add': {
			const newState = oldState.map(field => ({
				...field,
				isBeingEdited: false,
			}));
			return [
				...newState,
				{
					id: v4(),
					backendId: '',
					isBeingEdited: true,
					type: '' as 'Formulario',
					isValid: false,
					data: {
						id: '', // TODO: get the ID of the text component
						label: '',
						isRequired: true,
					},
				},
			];
		}
		case 'cancel': {
			const foundIndex = oldState.findIndex(item => item.id === action.payload.id);
			if (foundIndex === -1) return oldState;
			const newState = [...oldState];
			newState[foundIndex].type = '' as 'Formulario';
			newState[foundIndex].data = {
				id: '',
				label: '',
				isRequired: false,
			};
			return newState;
		}
		case 'replace': {
			return action.payload;
		}
		case 'edit': {
			const newState = oldState.map(field => ({
				...field,
				isBeingEdited: false,
			}));
			const clickedItem = newState.find(field => field.id === action.payload);
			if (!clickedItem) return oldState;
			clickedItem.isBeingEdited = true;
			return newState;
		}
		case 'delete': {
			if (oldState.length === 1) return oldState;
			const newState = oldState
				.filter(field => field.id !== action.payload)
				.map(field => ({ ...field, isBeingEdited: false }));

			const isEditingLastItem = oldState.at(-1)?.isBeingEdited;
			if (isEditingLastItem) {
				const newLastElement = newState.at(-1);
				if (!newLastElement) return oldState;
				newLastElement.isBeingEdited = true;
			} else {
				const editingFieldIndex = oldState.findIndex(field => field.isBeingEdited);
				if (editingFieldIndex === -1) return oldState;
				newState[editingFieldIndex].isBeingEdited = true;
			}
			return newState;
		}
		case 'changeType': {
			const newState = [...oldState];
			const { fieldId, newType } = action.payload;
			const foundField = newState.find(field => field.id === fieldId);
			if (!foundField) return oldState;
			foundField.type = newType;
			return newState;
		}
		case 'saveFormData': {
			const newState = [...oldState];
			const { fieldId, formData } = action.payload;
			const foundField = newState.find(field => field.id === fieldId);
			if (!foundField) return oldState;
			foundField.data = formData;
			foundField.isValid = true;
			return newState;
		}
		default:
			return oldState;
	}
}

interface IInnerFormProps {
	onClose: () => void;
	initialFormTitle?: string;
	initialState?: Field[];
	initialTitle?: string;
	onSave: (
		formData: {
			title: string;
			items: {
				fieldId?: string;
				id: string; // this is the component id
				isRequired: boolean;
				// defaultValue: string;
				label: string;
				order: string;
			}[];
		},
		onSuccess?: () => void
	) => void;
	isLoading: boolean;
}

// TODO: Reset current field state when changing field type (using the select)
export function InnerForm({ isLoading, initialFormTitle, initialState, initialTitle, onSave }: IInnerFormProps) {
	const [title, setTitle] = useState(initialTitle || '');
	const [items, dispatch] = useReducer(reducer, initialState?.length ? initialState : INITIAL_STATE);
	const { setShowError, canSubmit, setCanSubmit } = useFormModalContext();
	const fieldsContainerElementRef = useRef<HTMLDivElement>(null);

	const form = useForm<{ formTitle: string }>({
		defaultValues: { formTitle: initialFormTitle },
	});

	function showErrorForSeconds(value: boolean, seconds: number = 3) {
		setShowError(value);
		setTimeout(() => setShowError(false), seconds * 1000);
	}

	// WATCH OUT! This assumes there is always a item being edited.
	const editingField = items.find(item => item.isBeingEdited)!;

	function handleAddItem() {
		if (canSubmit) {
			// early return as the inner form is submittable
			showErrorForSeconds(true);
			return;
		}
		dispatch({ type: 'add' });
		setTitle('');

		if (!fieldsContainerElementRef.current) return;
		const fieldsContainer = fieldsContainerElementRef.current;
		// Timeout to make sure the DOM has been updated
		setTimeout(() =>
			fieldsContainer.scrollTo({
				top: fieldsContainer.scrollHeight,
				behavior: 'smooth',
			})
		);
	}

	function handleCancel() {
		dispatch({ type: 'cancel', payload: { id: editingField.id } });
		setTitle('');
		setCanSubmit(false);
	}

	function handleEnableItemEdition(id: string) {
		if (canSubmit) {
			// early return as the inner form is submittable
			showErrorForSeconds(true);
			return;
		}
		dispatch({ type: 'edit', payload: id });
		const found = items.find(item => item.id === id);
		setTitle(found!.data.label);
	}

	function handleSetItems(
		stateUpdaterFn: (oldState: Omit<IItem, 'label'>[]) => {
			oldIndex: number;
			newIndex: number;
		}
	) {
		const { oldIndex, newIndex } = stateUpdaterFn(items);
		const swappedArray = arrayMove(items, oldIndex, newIndex);
		dispatch({ type: 'replace', payload: swappedArray });
	}

	function handleChangeFieldType(newType: (typeof PROCESSES_STEP_TYPES)[number]) {
		setTitle('');
		dispatch({
			type: 'changeType',
			payload: { fieldId: editingField.id, newType },
		});
	}

	function handleMoveUp(index: number) {
		const swappedArray = arrayMove(items, index, index - 1);
		dispatch({ type: 'replace', payload: swappedArray });
	}

	function handleMoveDown(index: number) {
		const swappedArray = arrayMove(items, index, index + 1);
		dispatch({ type: 'replace', payload: swappedArray });
	}

	function handleDeleteField(id: string) {
		dispatch({ type: 'delete', payload: id });
		setTitle('');
	}

	function handleSaveForm(formValues: { formTitle: string }) {
		console.log('submitted with', formValues);
		onSave(
			{
				title: formValues.formTitle,
				items: items.map(({ data: field, backendId }, index) => ({
					fieldId: backendId,
					id: field.id,
					isRequired: field.isRequired,
					label: field.label,
					order: index.toString(),
				})),
			},
			() => dispatch({ type: 'reset' })
		);
	}

	function renderForm(fieldType: (typeof PROCESSES_STEP_TYPES)[number]) {
		const props = {
			title,
			defaultValues: editingField.data,
			onSave: (formValues: IFieldValues) =>
				dispatch({
					type: 'saveFormData',
					payload: {
						fieldId: editingField.id,
						formData: {
							...formValues,
							// El title sale del estado local porque es compartido
							label: title,
						},
					},
				}),
			onDelete: handleCancel,
		};

		switch (fieldType) {
			case 'Formulario':
				return <FormSelectorForm {...props} />;
			case 'Canvas Drawing Tool':
				return <FormImage {...props} />;
			default:
				return <TitleForm id={PROCESSES_STEP_TYPES_WITH_ID[fieldType]} {...props} />;
		}
	}

	const isFormValid = items.reduce((prev, curr) => prev && curr.isValid, true);

	return (
		<Fragment>
			<p className='text-xs'>
				Personaliza un nuevo flujo de trabajo definiendo los pasos, valores predeterminados y opciones de visualización.
				Todos los cambios se pueden modificar más adelante.
			</p>

			<FormInput
				required
				errorMessage={form.formState.errors.formTitle?.message}
				onError={!!form.formState.errors.formTitle}
				register={form.register('formTitle', {
					required: { message: 'Ingresa un título', value: true },
				})}
				label='Título'
				placeholder='Título de Flujo de Trabajo'
			/>

			<hr />

			<div className='flex gap-4'>
				<div className='basis-0 grow'>
					<h2 className='text-xs font-semibold text-bummock-midnight_blue mb-2'>Pasos del flujo</h2>
					<div className='flex flex-col gap-4 h-[465px]'>
						<div ref={fieldsContainerElementRef} className='flex flex-col gap-4 overflow-x-hidden overflow-scroll pr-4'>
							<DraggableList items={items} setItems={handleSetItems}>
								{items.map((item, index) => (
									<DraggableItem
										key={item.id}
										id={item.id}
										label={editingField.id === item.id ? title : item.data.label}
										isBeingEdited={item.isBeingEdited}
										onEdit={() => handleEnableItemEdition(item.id)}
										onMoveUp={() => handleMoveUp(index)}
										onMoveDown={() => handleMoveDown(index)}
										onDelete={() => handleDeleteField(item.id)}
									/>
								))}
							</DraggableList>
						</div>
						<DashedContainer
							onClick={handleAddItem}
							className='cursor-pointer flex shrink-0 gap-2 text-sm items-center'
						>
							<IcAdd />
							<span className='font-semibold text-bummock-midnight_blue text-xs'>Agregar otro paso</span>
						</DashedContainer>
					</div>
				</div>
				<div className='w-[1px] bg-bummock-disabled_grey'></div>
				<div className='basis-0 grow flex flex-col'>
					<div className='flex flex-col gap-6 grow'>
						{/* Esto va fijo */}
						{/* When this happen the type of the current field should update */}
						<FormSearchableDropdown
							label='Tipo de componente'
							options={[
								{
									id: '',
									value: 'Selecciona un tipo de componente',
								},
								...PROCESSES_STEP_TYPES.map(fieldType => ({
									id: fieldType,
									value: fieldType,
								})),
							]}
							value={editingField.type}
							onChange={(newType: unknown) => handleChangeFieldType(newType as (typeof PROCESSES_STEP_TYPES)[number])}
						/>
						{/* This is dynamic based on the selected field type */}
						{editingField.type ? (
							<Fragment key={editingField.id}>
								<FormInput
									label='Título'
									placeholder='Título del campo'
									required
									value={title}
									onChange={e => setTitle(e!.target.value)}
								/>
								{renderForm(editingField.type)}
							</Fragment>
						) : null}
					</div>
				</div>
			</div>
			<Button
				disabled={!isFormValid}
				isLoading={isLoading}
				onClick={form.handleSubmit(handleSaveForm)}
				className='text-sm ml-auto h-10'
			>
				Guardar Flujo de Trabajo
			</Button>
		</Fragment>
	);
}
