import { Button } from '@atoms';
import { useAppSelector, usePlaces } from '@hooks';
import { IFormNewUserValues } from '@interfaces';
import { FormInput, FormInputPhone, FormSearchableDropdown, FormSelect } from '@molecules';
import { ImagePicker } from '@organisms';
import { REGEX_EMAIL } from '@utils';
import { useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

interface IFormNewUserProps {
	onSubmit: (formData: IFormNewUserValues) => void;
	form: UseFormReturn<IFormNewUserValues>;
}

export const FormNewUser = ({ onSubmit, form }: IFormNewUserProps) => {
	const { companies } = useAppSelector(state => state.userProfile);
	const {
		formState: { errors, dirtyFields },
		handleSubmit: handleSubmitWrapper,
		register,
		control,
		watch,
		getValues,
	} = form;

	const inputFields = Object.keys(getValues()).filter(input => input !== 'image');
	const isEveryInputDirty =
		inputFields.length === 0
			? false
			: inputFields.reduce((prev, curr) => {
					const isFieldDirty = dirtyFields[curr as keyof IFormNewUserValues];
					if (!isFieldDirty) return false;
					return prev && isFieldDirty;
				}, true);

	const { data: places } = usePlaces();
	const [selectedState, setSelectedState] = useState(places.getStateByCityId(watch('citiesSelectedOptionId')));

	function handleSubmit(formData: IFormNewUserValues) {
		onSubmit && onSubmit(formData);
	}

	return (
		<form autoComplete='off' onSubmit={handleSubmitWrapper(handleSubmit)}>
			<ImagePicker
				onError={!!errors.image}
				errorMessage={errors.image?.message}
				register={{
					...register('image'),
				}}
			/>
			<hr className='my-8' />
			<div className='grid grid-cols-4 gap-x-8 gap-y-4'>
				<FormInput
					disabled={true}
					type='text'
					label='Empresa'
					errorMessage={errors.company?.message}
					onError={!!errors.company}
					value={companies[0].name}
				/>
				<FormInput
					type='text'
					label='Número de Cédula'
					placeholder='0912345678'
					errorMessage={errors.nationalIdNumber?.message}
					onError={!!errors.nationalIdNumber}
					required={true}
					register={{
						...register('nationalIdNumber', {
							required: { value: true, message: 'This field is required' },
							minLength: {
								value: 10,
								message: 'National ID must be 10 characters long',
							},
							maxLength: {
								value: 10,
								message: 'National ID must be 10 characters long',
							},
							validate: v => {
								const everyCharacterIsDigit = /^\d+$/.test(v);
								return everyCharacterIsDigit || 'Every character should be a digit';
							},
						}),
					}}
				/>
				<FormInputPhone
					label='Número de Celular'
					placeholder='0912345678'
					errorMessage={errors.phoneNumber?.message}
					onError={!!errors.phoneNumber}
					required={true}
					register={{
						...register('phoneNumber', {
							required: { value: true, message: 'This field is required' },
							minLength: {
								value: 9,
								message: 'Phone number must be at least 9 characters long',
							},
							maxLength: {
								value: 10,
								message: 'Phone number must be at most 10 characters long',
							},
							validate: v => {
								const everyCharacterIsDigit = /^\d+$/.test(v);
								return everyCharacterIsDigit || 'Every character should be a digit';
							},
						}),
					}}
				/>
				<FormInput
					type='text'
					label='Correo Electrónico'
					placeholder='test@test.com'
					errorMessage={errors.email?.message}
					onError={!!errors.email}
					required={true}
					register={{
						...register('email', {
							required: { value: true, message: 'This field is required' },
							pattern: { value: REGEX_EMAIL, message: 'Invalid email' },
							minLength: { value: 6, message: 'Min length is 6' },
						}),
					}}
				/>
				<FormInput
					type='text'
					label='Nombre'
					placeholder='John'
					errorMessage={errors.name?.message}
					onError={!!errors.name}
					required={true}
					register={{
						...register('name', {
							required: { value: true, message: 'This field is required' },
						}),
					}}
				/>
				<FormInput
					type='text'
					label='Apellido'
					placeholder='Doe'
					errorMessage={errors.lastName?.message}
					onError={!!errors.lastName}
					required={true}
					register={{
						...register('lastName', {
							required: { value: true, message: 'This field is required' },
						}),
					}}
				/>
				<FormSearchableDropdown
					required={true}
					label='Provincia'
					options={places
						.getStatesByCountryId(places.getCountries().find(place => place.name === 'Ecuador')?.id ?? '')
						.map(({ id, name }) => ({ id, value: name }))}
					value={places.getStateByCityId(watch('citiesSelectedOptionId'))}
					onChange={value => setSelectedState(value)}
				/>
				<Controller
					control={control}
					name='citiesSelectedOptionId'
					defaultValue=''
					rules={{
						required: { value: true, message: 'This field is required' },
					}}
					render={({ field: { ref, ...rest } }) => (
						<FormSearchableDropdown
							required={true}
							label='Ciudad'
							options={places.getCitiesByStateId(selectedState).map(({ id, name }) => ({ id, value: name }))}
							{...rest}
						/>
					)}
				/>
				<FormSelect label='País' disabled={true} options={[{ id: '1', value: 'Ecuador' }]} selectedOptionId='1' />
			</div>
			<Button disabled={!isEveryInputDirty} className='mt-8'>
				Siguiente
			</Button>
		</form>
	);
};
