import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { authSlice, uiSlice, headerSlice, userProfileSlice } from '@RTK/Slices';
import { userProfileMiddleware } from '@RTK/Middlewares';

const rootReducer = combineReducers({
	ui: uiSlice.reducer,
	auth: authSlice.reducer,
	header: headerSlice.reducer,
	userProfile: userProfileSlice.reducer,
});

export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(userProfileMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
