import { Link } from 'react-router-dom';

import { Button, IcArrowLeft, IcEnvelop } from '@atoms';
import { FormHeader } from '@molecules';
import { FormResetPassword } from '@organisms';
import { setResetRequestEmail, setResetRequestFailed, setResetRequestWasSuccessfully } from '@RTK/Slices';
import { useAppDispatch, useAppSelector } from '@hooks';

const ForgotPasswordPage = () => {
	const dispatch = useAppDispatch();

	const { resetRequestWasSuccessfully, resetRequestFailed, resetRequestEmail } = useAppSelector(state => state.ui);

	const hdlResetData = () => {
		dispatch(setResetRequestEmail(''));
		dispatch(setResetRequestWasSuccessfully(false));
		dispatch(setResetRequestFailed(false));
	};

	if (resetRequestWasSuccessfully)
		return (
			<main className='flex flex-col items-center gap-y-6'>
				<FormHeader
					icon={<IcEnvelop className='stroke-bummock-arctic_white w-[1.458125rem] stroke-2' />}
					title='Por favor, revise su correo electrónico.'
					description='Las indicaciones para restablecer su contraseña se han enviado al siguiente correo electrónico: '
					descriptionHighlight={resetRequestEmail}
				/>
				<Button className='px-28'>Abrir app de correo</Button>
				<p className='font-normal text-sm'>
					¿No recibió el correo electrónico?{' '}
					<span className='font-bold text-bummock-midnight_blue'>Haga clic para reenviar.</span>
				</p>
				<Link
					to='/login'
					onClick={hdlResetData}
					className='self-center w-fit inline-flex gap-x-2 font-semibold text-bummock-midnight_blue'
				>
					<IcArrowLeft className='w-3.5 stroke-2 stroke-bummock-midnight_blue' />
					Iniciar sesión
				</Link>
			</main>
		);

	if (resetRequestFailed) return <main>Failure message</main>;

	return (
		<main className='flex flex-col gap-y-5 text-center'>
			<FormHeader title='Restablecer Contraseña' description='Ingrese el correo electrónico asociado a su cuenta.' />
			<FormResetPassword />
			<Link
				to='/login'
				onClick={hdlResetData}
				className='self-center w-fit inline-flex gap-x-2 font-semibold text-bummock-midnight_blue'
			>
				<IcArrowLeft className='w-3.5 stroke-2 stroke-bummock-midnight_blue' /> Iniciar sesión
			</Link>
		</main>
	);
};

export default ForgotPasswordPage;
