import type { FC } from 'react';

import { IcSpinner } from '@atoms';
import { useButtonAnimation } from '@hooks';

import type { IButtonProps } from './Button.interfaces';

import styles from './Button.module.sass';

export const Button: FC<IButtonProps> = ({
	children,
	className,
	disabled,
	isLoading,
	mRef,
	variant = 'primary',
	...rest
}) => {
	const { buttonRef, animationHandlers } = useButtonAnimation({
		disabled,
		variant,
	});

	if (isLoading) {
		return (
			<button
				className={`${styles.button} ${styles['button--loading']} ${className} relative`}
				aria-disabled={disabled}
				data-variant={variant}
				disabled
				{...rest}
			>
				<div className='opacity-0 flex items-center justify-center gap-2'>{children}</div>
				<div className='absolute left-1/2 -translate-x-1/2'>
					<IcSpinner className='w-[1.375rem] stroke-2 stroke-bummock-off_white' />
				</div>
			</button>
		);
	}

	if (disabled) {
		return (
			<button
				ref={buttonRef}
				className={`${styles.button} ${className}`}
				aria-disabled={disabled}
				data-variant={variant}
				disabled
				{...rest}
			>
				{children}
			</button>
		);
	}

	return (
		<button
			{...animationHandlers}
			ref={buttonRef}
			className={`${styles.button} ${className}`}
			data-variant={variant}
			{...rest}
		>
			{children}
		</button>
	);
};
