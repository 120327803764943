import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcCheck: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11' fill='none' {...props}>
			<path d='M13 1.5L4.75 9.75L1 6' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	);
};
