import { createBulkDispatch, createKitDispatch, QUERY_KEYS } from '@api';
import { Button } from '@atoms';
import { useAppSelector, useLocations } from '@hooks';
import { IBulkDispatchItem, IKitDispatchItem } from '@interfaces';
import { FormSearchableDropdown } from '@molecules';
import { TableInventoryDispatch, TableKitsDispatch } from '@organisms';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useState } from 'react';

const KitDispatchSummary = ({
	selectedItems,
	onDelete,
}: {
	selectedItems: IKitDispatchItem[];
	onDelete: (id: string) => void;
}) => {
	// This should receive the "counters" and display them in a table
	const table = useReactTable({
		columns: [
			{
				accessorKey: 'name',
				header: 'Nombre',
			},
			{
				accessorKey: 'description',
				header: 'Descripción',
			},
			{
				accessorKey: 'quantity',
				header: 'Cantidad',
			},
			{
				header: 'Modificar',
				cell: ({ row }) => {
					const { id } = row.original;
					return (
						<div className='flex justify-center'>
							<Button onClick={() => onDelete(id)} className='w-[9.375rem] h-[2.0625rem]' variant='secondary'>
								Eliminar
							</Button>
						</div>
					);
				},
			},
		],
		data: selectedItems,
		getCoreRowModel: getCoreRowModel(),
	});
	return (
		<table>
			<thead>
				{table.getHeaderGroups().map(headerGroup => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map(header => (
							<th key={header.id}>
								{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.length === 0 && (
					<tr>
						<td colSpan={table.getHeaderGroups()[0].headers.length}>
							Ningún item ha sido agregado para ser despachado.
						</td>
					</tr>
				)}
				{table.getRowModel().rows.map(row => (
					<tr key={row.id}>
						{row.getVisibleCells().map(cell => (
							<td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

const InventoryDispatchSummary = ({
	selectedItems,
	onDelete,
}: {
	selectedItems: IBulkDispatchItem[];
	onDelete: (id: string) => void;
}) => {
	// This should receive the "counters" and display them in a table
	const table = useReactTable({
		columns: [
			{
				accessorKey: 'code',
				header: 'Código',
			},
			{
				accessorKey: 'name',
				header: 'Nombre',
			},
			{
				accessorKey: 'serialBegin',
				header: 'Inicio de Serie',
			},
			{
				accessorKey: 'serialEnd',
				header: 'Fin de Serie',
			},
			{
				accessorKey: 'quantity',
				header: 'Cantidad',
			},
			{
				header: 'Modificar',
				cell: ({ row }) => {
					const { id } = row.original;
					return (
						<div className='flex justify-center'>
							<Button onClick={() => onDelete(id)} className='w-[9.375rem] h-[2.0625rem]' variant='secondary'>
								Eliminar
							</Button>
						</div>
					);
				},
			},
		],
		data: selectedItems,
		getCoreRowModel: getCoreRowModel(),
	});
	return (
		<table>
			<thead>
				{table.getHeaderGroups().map(headerGroup => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map(header => (
							<th key={header.id}>
								{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.length === 0 && (
					<tr>
						<td colSpan={table.getHeaderGroups()[0].headers.length}>
							Ningún item ha sido agregado para ser despachado.
						</td>
					</tr>
				)}
				{table.getRowModel().rows.map(row => (
					<tr key={row.id}>
						{row.getVisibleCells().map(cell => (
							<td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

const dispatchTypes = ['Inventario', 'Kits'] as const;

const CreateDispatch = () => {
	const { companies } = useAppSelector(state => state.userProfile);
	const { data: locations } = useLocations({
		by: 'company',
		companyId: companies[0].id,
	});

	const [selectedCourtyard, setSelectedCourtyard] = useState('');

	// Consider refactor these into a single useReducer
	const [dispatchType, setDispatchType] = useState<(typeof dispatchTypes)[number]>('Inventario');

	const [summary, setSummary] = useState<{
		inventory: IBulkDispatchItem[];
		kits: IKitDispatchItem[];
	}>({
		inventory: [],
		kits: [],
	});

	const resetSummaryState = () => {
		setSummary({
			inventory: [],
			kits: [],
		});
	};

	const queryClient = useQueryClient();
	const kitDispatchMutation = useMutation({
		mutationFn: createKitDispatch,
		onSuccess: () => {
			// Reset state here
			setSummary(prev => ({ ...prev, kits: [] }));

			// Invalidate inventory queries
			queryClient.invalidateQueries({
				queryKey: [`${QUERY_KEYS.boxes}-available`],
			});
		},
	});

	const bulkDispatchMutation = useMutation({
		mutationFn: createBulkDispatch,
		onSuccess: () => {
			setSummary(prev => ({ ...prev, inventory: [] }));

			queryClient.invalidateQueries({
				queryKey: [`${QUERY_KEYS.inventory}-available`],
			});
		},
	});

	// Rename to createKitDspatch
	const handleCreateDispatch = () => {
		if (dispatchType === 'Kits') {
			kitDispatchMutation.mutate({
				location: selectedCourtyard,
				boxes: summary.kits.map(item => item.id),
			});
		} else {
			bulkDispatchMutation.mutate({
				location: selectedCourtyard,
				items: summary.inventory.map(({ id, serialBegin, quantity }) => ({
					id,
					startCode: serialBegin,
					quantity: +quantity,
				})),
			});
		}
	};

	const canDispatch =
		dispatchType === 'Inventario'
			? summary.inventory.filter(item => +item.quantity > 0).length > 0
			: summary.kits.filter(item => item.quantity > 0).length > 0;

	const isCreatingDispatch = kitDispatchMutation.isPending || bulkDispatchMutation.isPending;

	return (
		<>
			<header className='flex flex-col gap-4'>
				<h2 className='text-2xl font-bold text-bummock-ocean_blue'>Crear orden de despacho</h2>
				<p>Puedes crear una orden de despacho para una entidad y localidad especificas.</p>
			</header>

			<div className='flex gap-8'>
				<FormSearchableDropdown disabled label='Entidad' options={[{ id: '1', value: 'Risk' }]} value='1' />
				{/* This should be a location */}
				<FormSearchableDropdown
					includeEmptyOption
					onChange={selectedValue => setSelectedCourtyard(selectedValue)}
					label='Patio'
					options={locations.courtyards.map(({ id, name }) => ({
						id,
						value: name,
					}))}
					value=''
				/>
			</div>

			<hr />

			<div
				className={`flex flex-col gap-8 ${selectedCourtyard ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500`}
			>
				<header className='flex flex-col gap-4'>
					<p>
						Selecciona el tipo de inventario según el despacho que deseas realizar, ajusta los resultados de la búsqueda
						y luego procede a crearlo.
					</p>
				</header>

				<div className='grid grid-cols-4 gap-y-4 gap-x-8 items-end'>
					<FormSearchableDropdown
						label='Tipo de Despacho'
						options={dispatchTypes.map((type, index) => ({
							id: index + '',
							value: type,
						}))}
						onChange={value => setDispatchType(dispatchTypes[+value])}
						value='0'
					/>
				</div>

				<h2 className='text-2xl font-bold text-bummock-ocean_blue'>Resultados</h2>
				{dispatchType === 'Inventario' ? (
					<TableInventoryDispatch
						selectedItems={summary.inventory}
						setSelectedItems={(stateSetterFunction: (prevKits: IBulkDispatchItem[]) => IBulkDispatchItem[]) => {
							setSummary(prev => {
								return {
									kits: prev.kits,
									inventory: stateSetterFunction(prev.inventory),
								};
							});
						}}
					/>
				) : (
					<TableKitsDispatch
						selectedItems={summary.kits}
						setSelectedItems={(stateSetterFunction: (prevKits: IKitDispatchItem[]) => IKitDispatchItem[]) => {
							setSummary(prev => {
								return {
									inventory: prev.inventory,
									kits: stateSetterFunction(prev.kits),
								};
							});
						}}
					/>
				)}

				<hr />

				<h2 className='text-2xl font-bold text-bummock-ocean_blue'>Resumen de Despacho</h2>

				{dispatchType === 'Inventario' ? (
					<InventoryDispatchSummary
						selectedItems={summary.inventory.filter(item => +item.quantity > 0)}
						onDelete={(id: string) =>
							setSummary(prev => ({
								inventory: prev.inventory.filter(item => item.id !== id),
								kits: prev.kits,
							}))
						}
					/>
				) : (
					<KitDispatchSummary
						selectedItems={summary.kits.filter(item => item.quantity > 0)}
						onDelete={(id: string) =>
							setSummary(prev => ({
								inventory: prev.inventory,
								kits: prev.kits.filter(item => item.id !== id),
							}))
						}
					/>
				)}

				<div className='flex gap-4'>
					<Button onClick={resetSummaryState} variant='secondary' className='px-10 w-[180px]' type='button'>
						Cancelar
					</Button>
					<Button
						isLoading={isCreatingDispatch}
						onClick={handleCreateDispatch}
						disabled={!canDispatch}
						type='submit'
						className='px-10 w-[180px]'
					>
						Crear Despacho
					</Button>
				</div>
			</div>
		</>
	);
};

export default CreateDispatch;
