import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcRestart: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 20' fill='none' {...props}>
			<path
				d='M10.0259 18.4458C10.0259 18.4458 10.7227 18.3434 13.6062 15.3749C16.4897 12.4064 16.4897 7.59358 13.6062 4.62511C12.5845 3.57337 11.338 2.89426 10.0259 2.58779M10.0259 18.4458H14.9484M10.0259 18.4458L10.0259 13.3783M6.74426 1.55433C6.74426 1.55433 6.04753 1.6568 3.16399 4.62527C0.280459 7.59375 0.280459 12.4066 3.16399 15.3751C4.18564 16.4268 5.43218 17.1059 6.74426 17.4124M6.74426 1.55433L1.82176 1.55417M6.74426 1.55433L6.74426 6.62167'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
