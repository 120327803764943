import { Button } from '@atoms';
import { FormInput, FormSearchableDropdown } from '@molecules';
import { Controller, useForm } from 'react-hook-form';
import { debounce } from '@utils';
import { IPoint, IViewState } from '@interfaces';
import { assignLocation, createLocation } from '@api';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useAppSelector, useLocationsTypes, usePlaces } from '@hooks';

interface IFormNewLocationProps {
	points: IPoint[];
	onChangeCenterPoint: (params: IViewState, isNamedPlace: boolean) => void;
	onSubmit: () => void;
	onCancel: () => void;
}

interface IFormNewLocationValues {
	locationName: string;
	locationType: 'courtyard' | 'farm' | '';
	countryOptionId: string;
	stateOptionId: string;
	cityOptionId: string;
	longitude: string;
	latitude: string;
	includeWorkflows: boolean;
}

export const FormNewLocation = ({ points, onChangeCenterPoint, onCancel, onSubmit }: IFormNewLocationProps) => {
	const { id: companyIdFromParams } = useParams();
	const { companies } = useAppSelector(state => state.userProfile);
	const companyId = companyIdFromParams ?? companies[0].id;

	const { data: places } = usePlaces();
	const { data: locationsTypes } = useLocationsTypes();

	const queryClient = useQueryClient();
	const {
		formState: { errors, isValid },
		handleSubmit: handleSubmitWrapper,
		register,
		control,
		getValues,
		setValue,
		resetField,
	} = useForm<IFormNewLocationValues>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const resetFormFields = () => {
		resetField('includeWorkflows');
		resetField('stateOptionId');
		resetField('locationName');
		resetField('locationType');
		resetField('longitude');
		resetField('latitude');
		resetField('locationType');
	};

	// This gets called when either the latitude or longitude inputs change
	const handleChangeCoordinate = debounce(() => {
		if (getValues('latitude') === '' || getValues('longitude') === '') return;
		const latitudeValue = +getValues('latitude');
		const longitudeValue = +getValues('longitude');
		if (isNaN(latitudeValue) || isNaN(longitudeValue)) return;

		if (latitudeValue > 90 || latitudeValue < -90) return;
		if (longitudeValue > 180 || longitudeValue < -180) return;

		onChangeCenterPoint(
			{
				lat: latitudeValue,
				lng: longitudeValue,
				zoom: 17,
			},
			false
		);
	}, 500);

	const handleCancel = () => {
		resetFormFields();
		onCancel();
	};

	const handleSubmit = async (formData: IFormNewLocationValues) => {
		console.log('Submitted with data', formData, 'and points', points);
		setIsLoading(true);
		if (!companyId || !formData.locationType) return;
		try {
			const { id: locationId } = await createLocation({
				locationType: formData.locationType,
				area: points.map(({ lat, lng }) => ({
					latitude: lat + '',
					longitude: lng + '',
				})),
				name: formData.locationName,
				city: formData.cityOptionId,
			});
			await assignLocation({
				companyId,
				locationId,
			});
			queryClient.invalidateQueries({
				queryKey: [formData.locationType === 'courtyard' ? 'courtyards' : 'farms'],
			});
			onSubmit();
		} catch (error) {
			// TODO: Handle error state
			console.error(error);
		} finally {
			setIsLoading(false);
			resetFormFields();
		}
	};

	return (
		<form autoComplete='off' onSubmit={handleSubmitWrapper(handleSubmit)} className='flex flex-col'>
			<h2 className='mb-8 font-semibold text-bummock-midnight_blue text-2xl'>Crear nueva localidad</h2>
			<div className='h-full flex flex-col overflow-y-auto w-[calc(21.875rem_+_(2*4px))] px-[4px] justify-between'>
				<div className='flex flex-col gap-4 '>
					<div>
						<h3 className='font-bold text-bummock-midnight_blue'>Ubicar Localidad</h3>
						<p className='text-[0.8125rem]'>
							Utiliza las siguientes opciones para ubicar tu localidad con mayor precisión en el mapa.
						</p>
					</div>
					<div className='flex justify-between gap-[0.625rem]'>
						<FormSearchableDropdown
							disabled={true}
							label='Country'
							options={places.getCountries().map(({ id, name }) => ({
								id,
								value: name,
							}))}
							value={places.data.at(0)?.id ?? ''}
						/>
						<Controller
							control={control}
							name='stateOptionId'
							defaultValue=''
							rules={{
								required: { value: true, message: 'This field is required' },
							}}
							render={({ field: { ref, onChange, ...rest } }) => (
								<FormSearchableDropdown
									required={true}
									label='State'
									options={places
										.getStatesByCountryId(places.getCountries().find(place => place.name === 'Ecuador')?.id ?? '')
										.map(({ id, name }) => ({ id, value: name }))}
									onChange={value => {
										onChange(value);
										const selectedState = places.data.at(0)?.states.find(state => state.id === value);
										if (!selectedState) return;

										onChangeCenterPoint(
											{
												lat: +selectedState.coordinates.latitude,
												lng: +selectedState.coordinates.longitude,
												zoom: 8, // zoom for state is 8
											},
											true
										);
										// Assigns the first city of the selected country
										setValue('cityOptionId', selectedState.cities[0].id!);
										resetField('longitude');
										resetField('latitude');
									}}
									{...rest}
								/>
							)}
						/>
					</div>

					<div className='flex gap-[0.625rem]'>
						<FormInput
							label='Longitud'
							placeholder='40.741895'
							register={register('longitude', {
								onChange: handleChangeCoordinate,
							})}
							errorMessage={errors.longitude?.message}
							onError={!!errors.longitude}
						/>
						<FormInput
							label='Latitud'
							placeholder='-73.989308'
							register={register('latitude', {
								onChange: handleChangeCoordinate,
							})}
							errorMessage={errors.latitude?.message}
							onError={!!errors.latitude}
						/>
					</div>

					<hr className='my-4' />

					<div>
						<h3 className='font-bold text-bummock-midnight_blue'>Informacion de Localidad</h3>
						<p className='text-[0.8125rem]'>Agrega la siguiente información para crear tu ubicación.</p>
					</div>
					<FormInput
						register={{
							...register('locationName', {
								required: { value: true, message: 'This field is required' },
							}),
						}}
						errorMessage={errors.locationName?.message}
						onError={!!errors.locationName}
						label='Nombre de Localidad'
						required={true} /* Make this not show default HTML pop-up */
						placeholder='Location XYZ'
					/>
					<Controller
						control={control}
						name='locationType'
						rules={{
							required: { value: true, message: 'This field is required' },
						}}
						defaultValue=''
						render={({ field: { ref, ...rest } }) => (
							<FormSearchableDropdown
								required={true}
								label='Tipo de Localidad'
								options={locationsTypes.map(({ id, name }) => ({
									id,
									value: name,
								}))}
								{...rest}
							/>
						)}
					/>
				</div>
				<div className='flex gap-4'>
					<Button variant='secondary' type='button' className='basis-full' onClick={handleCancel}>
						Cancelar
					</Button>
					<Button isLoading={isLoading} type='submit' className='basis-full' disabled={!isValid || !points.length}>
						Guardar Localidad
					</Button>
				</div>
			</div>
		</form>
	);
};
