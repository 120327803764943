import { ITabRoute } from '@interfaces';
import { TabbedLayout } from '@layout';
import {
	CompaniesLocationsPage,
	CompaniesPage,
	CompaniesWorkflowsPage,
	EditCompanyInformationPage,
	NewCompanyPage,
} from '@pages';
import { RouteObject } from 'react-router-dom';

const companiesSubRoutes: ITabRoute[] = [
	{
		path: 'information',
		handle: { tabName: 'Información' },
		element: <EditCompanyInformationPage />,
	},
	{
		path: 'locations',
		handle: { tabName: 'Localidades' },
		element: <CompaniesLocationsPage />,
	},
	{
		path: 'workflows',
		handle: { tabName: 'Flujos de Trabajo' },
		element: <CompaniesWorkflowsPage />,
	},
];

export const companiesRoutes: RouteObject[] = [
	{
		path: 'companies',
		element: <CompaniesPage />,
	},
	{
		path: 'companies/new',
		element: <NewCompanyPage />,
	},
	{
		path: 'companies/:id',
		element: <TabbedLayout tabRoutes={companiesSubRoutes} />,
		children: companiesSubRoutes,
	},
];
