import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal } from '../Modal';
import { createProcess } from '@api';
import toast from 'react-hot-toast';
import { IFormData } from './interfaces';
import styles from '../Forms/overrides.module.sass';
import { InnerForm } from './InnerForm';
import { FormModalProvider } from '../Forms/ctx';
import { useFormsSelectData } from '@hooks';

interface IModalNewProcess {
	isModalVisible: boolean;
	onClose: VoidFunction;
}

export const ModalNewWorkflow = ({ isModalVisible, onClose }: IModalNewProcess) => {
	useFormsSelectData();

	const queryClient = useQueryClient();
	const createFormMutation = useMutation({
		mutationFn: createProcess,
		onSuccess: () => {
			toast.success('Formulario creado exitosamente.', {
				position: 'bottom-right',
			});
			onClose();
			queryClient.invalidateQueries({ queryKey: ['workflows'] });
		},
	});

	async function handleSave(formData: IFormData, onSuccess?: () => void) {
		await createFormMutation.mutateAsync({
			title: formData.title,
			items: formData.items.map((field, index) => ({
				id: field.id,
				title: field.label,
				isRequired: field.isRequired,
				order: index,
			})),
		});
		if (onSuccess) onSuccess();
		console.log('trynna save', formData);
	}

	return (
		<Modal
			className={`flex flex-col gap-4 min-w-[1100px] ${styles.override}`}
			title='Crear Flujo de Trabajo'
			isOpen={isModalVisible}
			onClose={onClose}
		>
			<FormModalProvider>
				<InnerForm
					key={isModalVisible + ''}
					isLoading={createFormMutation.isPending}
					onSave={handleSave}
					onClose={onClose}
				/>
			</FormModalProvider>
		</Modal>
	);
};
