import { useEffect, useRef, useState } from 'react';

import { createPortal } from 'react-dom';

import { useDetectClickOutside } from '@hooks';
import { IcArrowDown } from '@atoms';

import type { ISearchableDropdownProps } from './SearchableDropdown.interfaces';

import styles from './SearchableDropdown.module.sass';

// TODO: Consider refactoring to useReducer
export const SearchableDropdown = ({
	id,
	options,
	disabled,
	className = '',
	includeEmptyOption = false,
	selectedOptionId: selectedOptionIdProp,
	onClose = () => {},
	onChange = () => {},
}: ISearchableDropdownProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedOptionId, setSelectedOptionId] = useState('');
	useEffect(() => setSelectedOptionId(selectedOptionIdProp), [selectedOptionIdProp]);
	const [isExceeding, setIsExceeding] = useState(false);
	const selectedOptionValue = options.find(option => option.id === selectedOptionId)?.value ?? '';
	const { elementRef: inputContainerRef } = useDetectClickOutside({
		isOpen,
		onClickOutside: () => {
			setIsOpen(false);
			onClose();
		},
	});
	const optionsContainerRef = useRef<HTMLUListElement | null>(null);

	const [keyfocusedItemIndex, setKeyFocusedItemIndex] = useState(-1);

	const handleClickOption = (optionId: string) => {
		setSelectedOptionId(optionId);
		setSearchTerm(options.find(option => option.id === optionId)?.value ?? '');
		setIsOpen(false);
		onChange(optionId);
		onClose();
	};

	const handleClickOpenButton = () => {
		setSearchTerm('');
		if (isOpen) onClose();
		setIsOpen(!isOpen);
	};

	const handleClickSearchInput = () => {
		if (!isOpen) setSearchTerm('');
		setIsOpen(true);
		setKeyFocusedItemIndex(-1);
	};

	useEffect(() => {
		if (!isOpen) return;
		const dropdownElement = inputContainerRef.current;
		const optionsContainerElement = optionsContainerRef.current;
		if (!dropdownElement || !optionsContainerElement) return;

		const viewportWidth = window.innerWidth;
		const viewportHeight = window.innerHeight;

		const dropdownDOMRect = dropdownElement.getBoundingClientRect();
		const optionsContainerDOMRect = optionsContainerElement.getBoundingClientRect();

		// Positions it relatively to dropdown
		optionsContainerElement.style.right = `${viewportWidth - dropdownDOMRect.width - dropdownDOMRect.x}px`;
		optionsContainerElement.style.left = `${dropdownDOMRect.x}px`;

		const upToDropdownYEnd = dropdownDOMRect.y + dropdownDOMRect.height;
		const upToOptionsYEnd = upToDropdownYEnd + optionsContainerDOMRect.height;
		const isExceedingViewportHeight = upToOptionsYEnd > viewportHeight;
		if (isExceedingViewportHeight) {
			optionsContainerElement.style.top = `${dropdownDOMRect.y - optionsContainerDOMRect.height}px`;
		} else {
			optionsContainerElement.style.top = `${dropdownDOMRect.y + dropdownDOMRect.height}px`;
		}
		setIsExceeding(isExceedingViewportHeight);
	}, [isOpen]);

	// Synchronizes with the DOM on each search keystroke to keep options height consistent with dynamic positioning
	useEffect(() => {
		if (!isOpen || !isExceeding) return;

		const dropdownElement = inputContainerRef.current;
		const optionsContainerElement = optionsContainerRef.current;
		if (!dropdownElement || !optionsContainerElement) return;

		const dropdownDOMRect = dropdownElement.getBoundingClientRect();
		const optionsContainerDOMRect = optionsContainerElement.getBoundingClientRect();

		optionsContainerElement.style.top = `${dropdownDOMRect.y - optionsContainerDOMRect.height}px`;
	}, [isOpen, isExceeding, searchTerm]);

	let dropdownExceedingClassname = '';
	let optionsExceedingClassname = '';
	if (isOpen) {
		dropdownExceedingClassname = isExceeding
			? styles['searchable-select--open-exceeding']
			: styles['searchable-select--open'];

		optionsExceedingClassname = isExceeding
			? styles['searchable-select__options-container--exceeding']
			: styles['searchable-select__options-container'];
	}

	const updatedOptions = (includeEmptyOption ? [{ id: '', value: '-' }, ...options] : options)
		.filter(({ id }) => id !== selectedOptionId)
		.filter(({ value }) => value.toUpperCase().includes(searchTerm.toUpperCase()));

	return (
		<div
			ref={inputContainerRef}
			className={`${styles['searchable-select']} ${disabled ? '!cursor-not-allowed' : ''} ${dropdownExceedingClassname} ${className}`}
		>
			<input
				spellCheck='false'
				autoComplete='off'
				id={id}
				disabled={disabled}
				onMouseDown={handleClickSearchInput}
				className={`${disabled ? 'text-bummock-disabled_grey_2 cursor-not-allowed' : ''} shadow-none focus:ring-0 !px-0 !pl-4`}
				type='text'
				placeholder={selectedOptionId !== '' ? selectedOptionValue : '-'}
				value={isOpen ? searchTerm : selectedOptionValue}
				onChange={e => setSearchTerm(e.target.value)}
				onKeyDown={e => {
					if (e.key === 'Enter') {
						e.preventDefault();
						if (!isOpen) {
							handleClickSearchInput();
						} else {
							if (updatedOptions[keyfocusedItemIndex]) {
								handleClickOption(updatedOptions[keyfocusedItemIndex].id);
							}
						}
					}
					setKeyFocusedItemIndex(prev => {
						if (e.key === 'ArrowUp' && prev > 0) {
							return prev - 1;
						}
						if (e.key === 'ArrowDown' && prev < updatedOptions.length - 1) {
							return prev + 1;
						}

						return prev;
					});
				}}
				// onFocus={handleClickSearchInput}
				onBlur={() => {
					setIsOpen(false);
					onClose();
				}}
			/>
			<button
				disabled={disabled}
				tabIndex={-1}
				type='button'
				className={`${disabled ? 'text-bummock-disabled_grey_2 cursor-not-allowed' : ''} pr-4`}
				onMouseDown={handleClickOpenButton}
			>
				<IcArrowDown
					className={`${isOpen ? 'rotate-180' : ''} ${disabled ? 'stroke-bummock-disabled_grey_2' : 'stroke-bummock-midnight_blue'} transition-transform stroke-2 w-[13px]`}
				/>
			</button>

			{isOpen &&
				createPortal(
					<ul ref={optionsContainerRef} className={optionsExceedingClassname}>
						{updatedOptions.map(({ id, value }, index) => (
							<li
								onMouseDown={() => {
									handleClickOption(id);
								}}
								key={id}
								className={`${styles['searchable-select__option']} ${index === keyfocusedItemIndex ? 'bg-bummock-arctic_blue cursor-pointer' : ''}`}
							>
								{value}
							</li>
						))}
					</ul>,
					document.querySelector('body')!
				)}
		</div>
	);
};
