import { NumberedContentPage } from './NumberedContentPage';
import { View, Text, Image } from '@react-pdf/renderer';
import { Container } from '../components/Container';
import { IDriverRelatedData, IInspectionFormatPage, IKitRelatedData } from '../interfaces';

interface IInspectionFormatPageValues {
	pageData: IInspectionFormatPage & IKitRelatedData & IDriverRelatedData;
}

// TODO: Refactor this
export const InspectionFormatPage = ({
	pageData: {
		RESP_INSP,
		STK_SEG,
		client,
		code,
		containerNumber,
		date,
		driverFullName,
		driverNationalId,
		driverCellphoneNumber,
		endTime,
		externalTapping,
		fundaSeguridad,
		headerNumber,
		inspectionPlace,
		internalTapping,
		model,
		navieraBooking,
		rastreo,
		registrationPlate,
		selloCable,
		selloNaviera,
		selloPlastico,
		selloRetorno,
		selloSalida,
		startTime,
		// transportCompany,
		weekNumber,
		leftDoor,
		rightDoor,
	},
}: IInspectionFormatPageValues) => (
	<NumberedContentPage>
		{/* HEADER */}
		{/* TODO: Abtract into another component and define props */}
		<View
			id='CONTAINER_INSPECTION_FORMAT'
			style={{
				height: '88px',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				border: '1px solid #eee',
				borderRadius: '4px',
			}}
		>
			<View style={{ width: '128px' }}>
				<Image
					style={{
						margin: '0 auto',
						width: '85%',
					}}
					src='/logo-risk-protection-horizontal.png'
				/>
			</View>
			<View
				style={{
					display: 'flex',
					flexDirection: 'column',
					flexGrow: 1,
					borderLeft: '1px solid #eee',
				}}
			>
				<View
					style={{
						borderBottom: '1px solid #eee',
					}}
				>
					<Text
						style={{
							margin: '0 auto',
							textTransform: 'uppercase',
							fontWeight: 'bold',
							paddingVertical: '4px',
						}}
					>
						Sistema de Gestión de Calidad ISO 9001:2015
					</Text>
				</View>

				<View style={{ display: 'flex', flexDirection: 'row' }}>
					<View style={{ display: 'flex', flexDirection: 'column' }}>
						<Text
							style={{
								padding: '8px 16px',
								fontWeight: 'bold',
								borderBottom: '1px solid #eee',
							}}
						>
							Formato de inspección de contenedores despacho a finca
						</Text>
						<Text
							style={{
								fontWeight: 'bold',
								margin: '0 auto',
								paddingVertical: '11px',
								textAlign: 'center',
							}}
						>
							{headerNumber}
						</Text>
					</View>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							borderLeft: '1px solid #eee',
							flexGrow: 1,
						}}
					>
						<View
							style={{
								borderRight: '1px solid #eee',
								flexGrow: 1,
								fontWeight: 'bold',
							}}
						>
							<Text style={{ padding: '4px 0 4px 4px' }}>Código</Text>
							<Text
								style={{
									borderTop: '1px solid #eee',
									padding: '4px 0 4px 4px',
								}}
							>
								Versión
							</Text>
							<Text
								style={{
									borderTop: '1px solid #eee',
									padding: '4px 0 4px 4px',
								}}
							>
								Fecha
							</Text>
						</View>
						<View style={{ flexGrow: 1 }}>
							<Text style={{ padding: '4px 0 4px 4px' }}>{code}</Text>
							<Text
								style={{
									borderTop: '1px solid #eee',
									padding: '4px 0 4px 4px',
								}}
							>
								05
							</Text>
							<Text
								style={{
									borderTop: '1px solid #eee',
									padding: '4px 0 4px 4px',
								}}
							>
								{date}
							</Text>
						</View>
					</View>
				</View>
			</View>
		</View>

		<View
			style={{
				height: '30px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				border: '1px solid #eee',
				borderRadius: '4px',
			}}
		>
			<View
				style={{
					flexBasis: '125px',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
					fontWeight: 'bold',
					paddingHorizontal: '8px',
				}}
			>
				<Text>Cliente:</Text>
			</View>
			<View
				style={{
					flexGrow: 1,
					borderLeft: '1px solid #eee',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
					paddingHorizontal: '8px',
				}}
			>
				<Text>{client}</Text>
			</View>
			<View
				style={{
					flexBasis: '125px',
					borderLeft: '1px solid #eee',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',

					fontWeight: 'bold',
					paddingHorizontal: '8px',
				}}
			>
				<Text>Lugar inspección:</Text>
			</View>
			<View
				style={{
					flexGrow: 1,
					borderLeft: '1px solid #eee',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
					paddingHorizontal: '8px',
				}}
			>
				<Text>{inspectionPlace}</Text>
			</View>
			<View
				style={{
					flexBasis: '36px',
					borderLeft: '1px solid #eee',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',

					fontWeight: 'bold',
					paddingHorizontal: '8px',
				}}
			>
				<Text>WK:</Text>
			</View>
			<View
				style={{
					flexBasis: '26px',
					borderLeft: '1px solid #eee',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
					paddingHorizontal: '8px',
				}}
			>
				<Text>{weekNumber}</Text>
			</View>
		</View>

		{/* Registro de contenedores table */}
		{/* TODO: Move this to a separate component */}
		{/* testing 225px */}
		<View style={{ maxHeight: '225px' }}>
			{/* Header */}
			<View
				style={{
					backgroundColor: '#092D45',
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					borderTopLeftRadius: '4px',
					borderTopRightRadius: '4px',
				}}
			>
				<View
					style={{
						flexGrow: 1,
						padding: '4px',
					}}
				>
					<Text
						style={{
							fontWeight: 600,
							color: 'white',
							textAlign: 'center',
						}}
					>
						Registro de contenedores
					</Text>
				</View>
			</View>

			{/* First row */}
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					fontSize: '8px',
					borderBottom: '1px solid #eee',
					borderLeft: '1px solid #eee',
					borderRight: '1px solid #eee',
				}}
			>
				<View
					style={{
						flexBasis: '60px',
						padding: '4px',
						borderRight: '1px solid #eee',
					}}
				>
					<Text style={{ fontWeight: 'bold' }}>Contenedor:</Text>
				</View>
				<View
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						flexGrow: 1,
					}}
				>
					<Text style={{ padding: '4px' }}>{containerNumber}</Text>
					<Text
						style={{
							padding: '4px',
							borderLeft: '1px solid #eee',
							fontWeight: 'bold',
						}}
					>
						Fecha:{' '}
					</Text>
					<Text style={{ padding: '4px', borderLeft: '1px solid #eee' }}>{date}</Text>
					<Text
						style={{
							padding: '4px',
							borderLeft: '1px solid #eee',
							fontWeight: 'bold',
						}}
					>
						Hora inicio:{' '}
					</Text>
					<Text style={{ padding: '4px', borderLeft: '1px solid #eee' }}>{startTime}</Text>
					<Text
						style={{
							padding: '4px',
							borderLeft: '1px solid #eee',
							fontWeight: 'bold',
						}}
					>
						Hora fin:{' '}
					</Text>
					<Text style={{ padding: '4px', borderLeft: '1px solid #eee' }}>{endTime}</Text>
				</View>
			</View>

			{/* Cintas de seguridad */}
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					border: '1px solid #eee',
					borderTop: 'none',
					borderBottomLeftRadius: '4px',
					borderTopRightRadius: '4px',
					fontSize: '8px',
				}}
			>
				{/* Cintas de seguridad */}
				<View
					style={{
						position: 'relative',
						// minHeight: '187px',
						width: '30px',
						borderRight: '1px solid #eee',
						backgroundColor: '#F9F9F9',
					}}
				>
					{/* THIS IS NASTY */}
					<View
						style={{
							top: '50%',
							left: 0,
							width: '150px',
							// right: '-150px',
							transform: 'translateX(-60%) translateY(-10%) rotate(-90 deg)',
							position: 'absolute',
						}}
					>
						<Text
							style={{
								textAlign: 'center',
								fontWeight: 'bold',
							}}
						>
							Cintas de Seguridad
						</Text>
					</View>
				</View>
				<View
					style={{
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						fontSize: '8px',
					}}
				>
					{/* Every one of these items can be inserted dynamically */}
					<View style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
						<View
							style={{
								position: 'relative',
								flexBasis: '30px',
								flexShrink: 0,
								borderRight: '1px solid #eee',
								backgroundColor: '#F9F9F9',
							}}
						>
							<Text
								style={{
									position: 'absolute',
									top: '50%',
									left: 0,
									width: '40px',
									fontWeight: 'bold',

									transform: 'translateX(-5%) translateY(-5%) rotate(-90 deg)',
								}}
							>
								Exteriores
							</Text>
						</View>
						<View
							style={{
								display: 'flex',
								flexDirection: 'row',
								padding: '12px',
								gap: '8px',
								flexWrap: 'wrap',
								minHeight: '50px',
								width: '501px', // This shouldn't be hardcoded but who cares
								justifyContent: 'center',
							}}
						>
							{externalTapping.map((imageSrc, i) => (
								<Container
									key={i}
									style={{
										justifyContent: 'center',
										alignItems: 'center',
										width: '89px',
										height: '27px',
										padding: 0,
									}}
								>
									<Image
										style={{
											height: '80%',
											width: '80%',
											objectFit: 'contain',
										}}
										src={{
											uri: imageSrc,
											method: 'GET',
											headers: {},
											body: '',
										}}
									/>
								</Container>
							))}
						</View>
					</View>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexGrow: 1,
							borderTop: '1px solid #eee',
						}}
					>
						<View
							style={{
								position: 'relative',
								flexBasis: '30px',
								flexShrink: 0,
								borderRight: '1px solid #eee',
								backgroundColor: '#F9F9F9',
							}}
						>
							<Text
								style={{
									position: 'absolute',
									top: '50%',
									left: 0,
									width: '40px',
									fontWeight: 'bold',

									transform: 'translateX(-5%) translateY(-5%) rotate(-90 deg)',
								}}
							>
								Interiores
							</Text>
						</View>
						<View
							style={{
								display: 'flex',
								flexDirection: 'row',
								padding: '12px',
								gap: '8px',
								flexWrap: 'wrap',
								minHeight: '50px',
								width: '501px', // This shouldn't be hardcoded but who cares
								justifyContent: 'center',
							}}
						>
							{internalTapping.map((imageSrc, i) => (
								<Container
									key={i}
									style={{
										justifyContent: 'center',
										alignItems: 'center',
										width: '89px',
										height: '27px',
										padding: 0,
									}}
								>
									<Image
										style={{
											height: '80%',
											width: '80%',
											objectFit: 'contain',
										}}
										src={{
											uri: imageSrc,
											method: 'GET',
											headers: {},
											body: '',
										}}
									/>
								</Container>
							))}
						</View>
					</View>
					<View
						style={{
							minHeight: '50px',
							display: 'flex',
							flexDirection: 'row',
							flexGrow: 1,
							borderTop: '1px solid #eee',
						}}
					>
						<View
							style={{
								position: 'relative',
								flexBasis: '30px',
								flexShrink: 0,
								borderRight: '1px solid #eee',
								backgroundColor: '#F9F9F9',
							}}
						>
							<Text
								style={{
									position: 'absolute',
									top: '50%',
									left: 0,
									width: '40px',
									fontWeight: 'bold',

									transform: 'translateX(-5%) translateY(-10%) rotate(-90 deg)',
								}}
							>
								Puertas
							</Text>
						</View>
						<View
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: '8px',
								flexWrap: 'wrap',
								width: '501px', // This shouldn't be hardcoded but who cares
								justifyContent: 'center',
							}}
						>
							<View
								style={{
									flexGrow: 1,
									padding: '12px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{
									<Container
										style={{
											justifyContent: 'center',
											alignItems: 'center',
											width: '89px',
											height: '27px',
											padding: 0,
										}}
									>
										<Image
											style={{
												height: '80%',
												width: '80%',
												objectFit: 'contain',
											}}
											src={{
												uri: leftDoor,
												method: 'GET',
												headers: {},
												body: '',
											}}
										/>
									</Container>
								}
							</View>
							<View
								style={{
									flexGrow: 1,
									borderLeft: '1px solid #eee',

									padding: '12px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{
									<Container
										style={{
											justifyContent: 'center',
											alignItems: 'center',
											width: '89px',
											height: '27px',
											padding: 0,
										}}
									>
										<Image
											style={{
												height: '80%',
												width: '80%',
												objectFit: 'contain',
											}}
											src={{
												uri: rightDoor,
												method: 'GET',
												headers: {},
												body: '',
											}}
										/>
									</Container>
								}
							</View>
						</View>
					</View>
				</View>
			</View>
		</View>

		{/* Tablas transpuestas */}
		{/* TODO: Move to a different component */}
		<View
			style={{
				height: '96px',
				fontSize: '6px',
				borderRadius: '4px',
				border: '1px solid #eee',
			}}
		>
			<View style={{ display: 'flex', flexDirection: 'row' }}>
				<View style={{ width: '50%' }}>
					<View style={{ display: 'flex', flexDirection: 'row' }}>
						<Text
							style={{
								borderTopLeftRadius: '4px',
								flexBasis: '50%',
								padding: '4px 0 4px 8px',
								backgroundColor: '#092D45',
								fontWeight: 'bold',
								color: 'white',
								borderRight: '1px solid #eee',
							}}
						>
							Chofer:{' '}
						</Text>
						<View style={{ flexGrow: 1 }}>
							<Text
								style={{
									margin: '0 auto',
									paddingVertical: '4px',
								}}
							>
								{driverFullName}
							</Text>
						</View>
					</View>
				</View>
				<View style={{ width: '50%', borderLeft: '1px solid #eee' }}>
					<View style={{ display: 'flex', flexDirection: 'row' }}>
						<Text
							style={{
								flexBasis: '50%',
								padding: '4px 0 4px 8px',
								backgroundColor: '#092D45',
								fontWeight: 'bold',
								color: 'white',
								borderRight: '1px solid #eee',
							}}
						>
							Sello cable:{' '}
						</Text>
						<View style={{ flexGrow: 1 }}>
							<Text
								style={{
									margin: '0 auto',
									paddingVertical: '4px',
								}}
							>
								{selloCable}
							</Text>
						</View>
					</View>
				</View>
			</View>

			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					borderTop: '1px solid #eee',
				}}
			>
				<View style={{ width: '50%' }}>
					<View style={{ display: 'flex', flexDirection: 'row' }}>
						<Text
							style={{
								flexBasis: '50%',
								padding: '4px 0 4px 8px',
								backgroundColor: '#092D45',
								fontWeight: 'bold',
								color: 'white',
								borderRight: '1px solid #eee',
							}}
						>
							Cédula del chofer:{' '}
						</Text>
						<View style={{ flexGrow: 1 }}>
							<Text
								style={{
									margin: '0 auto',
									paddingVertical: '4px',
								}}
							>
								{driverNationalId}
							</Text>
						</View>
					</View>
				</View>
				<View style={{ width: '50%', borderLeft: '1px solid #eee' }}>
					<View style={{ display: 'flex', flexDirection: 'row' }}>
						<Text
							style={{
								flexBasis: '50%',
								padding: '4px 0 4px 8px',
								backgroundColor: '#092D45',
								fontWeight: 'bold',
								color: 'white',
								borderRight: '1px solid #eee',
							}}
						>
							Sello naviera:{' '}
						</Text>

						<View style={{ flexGrow: 1 }}>
							<Text
								style={{
									margin: '0 auto',
									paddingVertical: '4px',
								}}
							>
								{selloNaviera}
							</Text>
						</View>
					</View>
				</View>
			</View>

			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					borderTop: '1px solid #eee',
				}}
			>
				<View style={{ width: '50%' }}>
					<View style={{ display: 'flex', flexDirection: 'row' }}>
						<Text
							style={{
								flexBasis: '50%',
								padding: '4px 0 4px 8px',
								backgroundColor: '#092D45',
								fontWeight: 'bold',
								color: 'white',
								borderRight: '1px solid #eee',
							}}
						>
							Celular del chofer:{' '}
						</Text>
						<View style={{ flexGrow: 1 }}>
							<Text
								style={{
									margin: '0 auto',
									paddingVertical: '4px',
								}}
							>
								{driverCellphoneNumber}
							</Text>
						</View>
					</View>
				</View>
				<View style={{ width: '50%', borderLeft: '1px solid #eee' }}>
					<View style={{ display: 'flex', flexDirection: 'row' }}>
						<Text
							style={{
								flexBasis: '50%',
								padding: '4px 0 4px 8px',
								backgroundColor: '#092D45',
								fontWeight: 'bold',
								color: 'white',
								borderRight: '1px solid #eee',
							}}
						>
							Sello plástico:{' '}
						</Text>

						<View style={{ flexGrow: 1 }}>
							<Text
								style={{
									margin: '0 auto',
									paddingVertical: '4px',
								}}
							>
								{selloPlastico}
							</Text>
						</View>
					</View>
				</View>
			</View>

			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					borderTop: '1px solid #eee',
				}}
			>
				<View style={{ width: '50%' }}>
					<View style={{ display: 'flex', flexDirection: 'row' }}>
						<Text
							style={{
								flexBasis: '50%',
								padding: '4px 0 4px 8px',
								backgroundColor: '#092D45',
								fontWeight: 'bold',
								color: 'white',
								borderRight: '1px solid #eee',
							}}
						>
							Placa:{' '}
						</Text>
						<View style={{ flexGrow: 1 }}>
							<Text
								style={{
									margin: '0 auto',
									paddingVertical: '4px',
								}}
							>
								{registrationPlate}
							</Text>
						</View>
					</View>
				</View>
				<View style={{ width: '50%', borderLeft: '1px solid #eee' }}>
					<View style={{ display: 'flex', flexDirection: 'row' }}>
						<Text
							style={{
								flexBasis: '50%',
								padding: '4px 0 4px 8px',
								backgroundColor: '#092D45',
								fontWeight: 'bold',
								color: 'white',
								borderRight: '1px solid #eee',
							}}
						>
							Funda de seguridad:{' '}
						</Text>

						<View style={{ flexGrow: 1 }}>
							<Text
								style={{
									margin: '0 auto',
									paddingVertical: '4px',
								}}
							>
								{fundaSeguridad}
							</Text>
						</View>
					</View>
				</View>
			</View>

			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					borderTop: '1px solid #eee',
				}}
			>
				<View style={{ width: '50%' }}>
					<View style={{ display: 'flex', flexDirection: 'row' }}>
						<Text
							style={{
								flexBasis: '50%',
								padding: '4px 0 4px 8px',
								backgroundColor: '#092D45',
								fontWeight: 'bold',
								color: 'white',
								borderRight: '1px solid #eee',
							}}
						>
							CIA de transporte:{' '}
						</Text>
						<View style={{ flexGrow: 1 }}>
							<Text
								style={{
									margin: '0 auto',
									paddingVertical: '4px',
								}}
							>
								5 de Julio{/*transportCompany*/}
							</Text>
						</View>
					</View>
				</View>
				<View style={{ width: '50%', borderLeft: '1px solid #eee' }}>
					<View style={{ display: 'flex', flexDirection: 'row' }}>
						<Text
							style={{
								flexBasis: '50%',
								padding: '4px 0 4px 8px',
								backgroundColor: '#092D45',
								fontWeight: 'bold',
								color: 'white',
								borderRight: '1px solid #eee',
							}}
						>
							STK SEG:{' '}
						</Text>

						<View style={{ flexGrow: 1 }}>
							<Text
								style={{
									margin: '0 auto',
									paddingVertical: '4px',
								}}
							>
								{STK_SEG}
							</Text>
						</View>
					</View>
				</View>
			</View>

			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					borderTop: '1px solid #eee',
				}}
			>
				<View style={{ width: '50%' }}>
					<View style={{ display: 'flex', flexDirection: 'row' }}>
						<Text
							style={{
								borderBottomLeftRadius: '4px',
								flexBasis: '50%',
								padding: '4px 0 4px 8px',
								backgroundColor: '#092D45',
								fontWeight: 'bold',
								color: 'white',
								borderRight: '1px solid #eee',
							}}
						>
							Naviera Booking:{' '}
						</Text>
						<View style={{ flexGrow: 1 }}>
							<Text
								style={{
									margin: '0 auto',
									paddingVertical: '4px',
								}}
							>
								{navieraBooking}
							</Text>
						</View>
					</View>
				</View>
				<View style={{ width: '50%', borderLeft: '1px solid #eee' }}>
					<View style={{ display: 'flex', flexDirection: 'row' }}>
						<Text
							style={{
								flexBasis: '50%',
								padding: '4px 0 4px 8px',
								backgroundColor: '#092D45',
								fontWeight: 'bold',
								color: 'white',
								borderRight: '1px solid #eee',
							}}
						>
							RESP, INSP:{' '}
						</Text>
						<View style={{ flexGrow: 1 }}>
							<Text
								style={{
									margin: '0 auto',
									paddingVertical: '4px',
								}}
							>
								{RESP_INSP}
							</Text>
						</View>
					</View>
				</View>
			</View>
		</View>

		<View
			style={{
				display: 'flex',
				flexDirection: 'row',
				gap: '8px',
				flexGrow: 1,
			}}
		>
			<View
				style={{
					flexBasis: '150px',
					display: 'flex',
					flexDirection: 'column',
					gap: '4px',
				}}
			>
				{/* 4x4 table */}
				<Container
					style={{
						flexBasis: '64px',
						display: 'flex',
						flexDirection: 'row',
						padding: 0,
						fontSize: '6px',
					}}
				>
					<View style={{ flexBasis: '50%' }}>
						<View style={{ height: '50%' }}>
							<View
								style={{
									backgroundColor: '#092D45',
									color: 'white',
									height: '50%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									borderTopLeftRadius: '4px',
								}}
							>
								<Text style={{ fontWeight: 'bold' }}>Rastreo:</Text>
							</View>
							<View
								style={{
									height: '50%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Text>{rastreo}</Text>
							</View>
						</View>
						<View style={{ height: '50%', borderTop: '1px solid #eee' }}>
							<View
								style={{
									backgroundColor: '#092D45',
									color: 'white',
									height: '50%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Text style={{ fontWeight: 'bold' }}>Modelo:</Text>
							</View>
							<View
								style={{
									height: '50%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Text>{model}</Text>
							</View>
						</View>
					</View>
					<View style={{ flexBasis: '50%', borderLeft: '1px solid #eee' }}>
						<View style={{ height: '50%' }}>
							<View
								style={{
									backgroundColor: '#092D45',
									color: 'white',
									height: '50%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									borderTopRightRadius: '4px',
								}}
							>
								<Text style={{ fontWeight: 'bold' }}>Sello de salida:</Text>
							</View>
							<View
								style={{
									height: '50%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Text>{selloSalida}</Text>
							</View>
						</View>
						<View style={{ height: '50%', borderTop: '1px solid #eee' }}>
							<View
								style={{
									backgroundColor: '#092D45',
									color: 'white',
									height: '50%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Text style={{ fontWeight: 'bold' }}>Sello de retorno:</Text>
							</View>
							<View
								style={{
									height: '50%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Text>{selloRetorno}</Text>
							</View>
						</View>
					</View>
				</Container>
				<Container style={{ flexGrow: 1 }}></Container>
				<Container style={{ flexGrow: 1 }}></Container>
				<Container style={{ flexGrow: 1 }}></Container>
			</View>
			<View style={{ flexGrow: 1 }}>
				{/* Inspection details table */}
				{/* Header */}
				<View
					style={{
						backgroundColor: '#092D45',
						borderTopLeftRadius: '4px',
						borderTopRightRadius: '4px',
						fontSize: '8px',
					}}
				>
					<View
						style={{
							padding: '4px',
						}}
					>
						<Text
							style={{
								fontWeight: 600,
								color: 'white',
								margin: '0 auto',
							}}
						>
							Detalles de la inspección
						</Text>
					</View>
				</View>

				{/* Inner table */}
				<View
					style={{
						backgroundColor: '#092D45',
						fontSize: '6px',
						display: 'flex',
						flexDirection: 'row',
						borderTop: '1px solid #eee',
					}}
				>
					<View
						style={{
							padding: '4px',
							flexBasis: '20px',
							flexGrow: 0,

							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Text
							style={{
								fontWeight: 600,
								color: 'white',
								margin: '0 auto',
							}}
						>
							#
						</Text>
					</View>

					<View
						style={{
							// This could be problematic
							flexGrow: 1,
							padding: '4px',

							display: 'flex',
							justifyContent: 'center',
							alignItems: 'flex-start',
						}}
					>
						<Text
							style={{
								fontWeight: 600,
								color: 'white',
							}}
						>
							Áreas
						</Text>
					</View>

					<View
						style={{
							padding: '4px',
							flexBasis: '20px',
							flexGrow: 0,
						}}
					>
						<Text
							style={{
								fontWeight: 600,
								color: 'white',
								margin: '0 auto',
							}}
						>
							SEA L/N
						</Text>
					</View>

					<View
						style={{
							padding: '4px',
							flexBasis: '20px',
							flexGrow: 0,
						}}
					>
						<Text
							style={{
								fontWeight: 600,
								color: 'white',
								margin: '0 auto',
							}}
						>
							SEA L/O
						</Text>
					</View>

					<View
						style={{
							padding: '4px',
							flexBasis: '20px',
							flexGrow: 0,

							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Text
							style={{
								fontWeight: 600,
								color: 'white',
								margin: '0 auto',
							}}
						>
							CUT
						</Text>
					</View>

					<View
						style={{
							padding: '4px',
							flexBasis: '20px',
							flexGrow: 0,
						}}
					>
						<Text
							style={{
								fontWeight: 600,
								color: 'white',
								margin: '0 auto',
							}}
						>
							PAT /N
						</Text>
					</View>

					<View
						style={{
							padding: '4px',
							flexBasis: '20px',
							flexGrow: 0,
						}}
					>
						<Text
							style={{
								fontWeight: 600,
								color: 'white',
								margin: '0 auto',
							}}
						>
							PAT /O
						</Text>
					</View>

					<View
						style={{
							padding: '4px',
							flexBasis: '20px',
							flexGrow: 0,
						}}
					>
						<Text
							style={{
								fontWeight: 600,
								color: 'white',
								margin: '0 auto',
							}}
						>
							WEL D/O
						</Text>
					</View>

					<View
						style={{
							padding: '4px',
							flexBasis: '20px',
							flexGrow: 0,

							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Text
							style={{
								fontWeight: 600,
								color: 'white',
								margin: '0 auto',
							}}
						>
							INST
						</Text>
					</View>

					<View
						style={{
							padding: '4px',
							flexBasis: '20px',
							flexGrow: 0,

							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Text
							style={{
								fontWeight: 600,
								color: 'white',
								margin: '0 auto',
							}}
						>
							SECT
						</Text>
					</View>

					<View
						style={{
							padding: '4px',
							flexBasis: '20px',
							flexGrow: 0,

							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Text
							style={{
								fontWeight: 600,
								color: 'white',
								margin: '0 auto',
							}}
						>
							DEL
						</Text>
					</View>

					<View
						style={{
							padding: '4px',
							flexBasis: '24px',
							flexGrow: 0,

							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Text
							style={{
								fontWeight: 600,
								color: 'white',
								margin: '0 auto',
							}}
						>
							PERF
						</Text>
					</View>

					<View
						style={{
							padding: '4px',
							flexBasis: '24px',
							flexGrow: 0,

							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Text
							style={{
								fontWeight: 600,
								color: 'white',
								margin: '0 auto',
							}}
						>
							DENT
						</Text>
					</View>
				</View>

				<View
					style={{
						flexGrow: 1,
						display: 'flex',
						flexDirection: 'column',
						borderBottom: '1px solid #eee',
					}}
				>
					{[
						'PTA-IZ.INT.',
						'PRD.IZ.INT.',
						'ESPACIADORES',
						'PRD.FR.INT.',
						'DEFLECTOR',
						'TECHO INT.',
						'PISO INT.',
						'PRD.DER.INT.',
						'PTA.DER.INT.',
						'EVAPORADORES',
						'PISO EXT.',
						'TECHO EXT.',
						'PRD.IZ.EXT.',
						'PRD.DER.EXT.',
						'PTAS.EXT.',
						'TORN.SEG',
					].map((value, index) => (
						<View
							key={index}
							style={{
								flexGrow: 1,
								fontSize: '6px',
								display: 'flex',
								flexDirection: 'row',
								borderTop: index !== 0 ? '1px solid #eee' : '',
								borderLeft: '1px solid #eee',
								borderRight: '1px solid #eee',
							}}
						>
							<View
								style={{
									flexBasis: '20px',
									flexGrow: 0,

									paddingLeft: '4px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'flex-start',
								}}
							>
								<Text
									style={{
										fontWeight: 'bold',
									}}
								>
									{index + 1}.
								</Text>
							</View>

							<View
								style={{
									// This could be problematic
									flexGrow: 1,
									paddingLeft: '4px',

									display: 'flex',
									justifyContent: 'center',
									alignItems: 'flex-start',
									borderLeft: '1px solid #eee',
								}}
							>
								<Text
									style={{
										fontWeight: 400,
									}}
								>
									{value}
								</Text>
							</View>

							<View
								style={{
									paddingLeft: '4px',
									flexBasis: '20px',
									flexGrow: 0,
									borderLeft: '1px solid #eee',
								}}
							></View>

							<View
								style={{
									paddingLeft: '4px',
									flexBasis: '20px',
									flexGrow: 0,
									borderLeft: '1px solid #eee',
								}}
							></View>

							<View
								style={{
									paddingLeft: '4px',
									flexBasis: '20px',
									flexGrow: 0,

									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',

									borderLeft: '1px solid #eee',
								}}
							></View>

							<View
								style={{
									paddingLeft: '4px',
									flexBasis: '20px',
									flexGrow: 0,

									borderLeft: '1px solid #eee',
								}}
							></View>

							<View
								style={{
									paddingLeft: '4px',
									flexBasis: '20px',
									flexGrow: 0,

									borderLeft: '1px solid #eee',
								}}
							></View>

							<View
								style={{
									paddingLeft: '4px',
									flexBasis: '20px',
									flexGrow: 0,

									borderLeft: '1px solid #eee',
								}}
							></View>

							<View
								style={{
									paddingLeft: '4px',
									flexBasis: '20px',
									flexGrow: 0,

									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',

									borderLeft: '1px solid #eee',
								}}
							></View>

							<View
								style={{
									paddingLeft: '4px',
									flexBasis: '20px',
									flexGrow: 0,

									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',

									borderLeft: '1px solid #eee',
								}}
							></View>

							<View
								style={{
									paddingLeft: '4px',
									flexBasis: '20px',
									flexGrow: 0,

									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',

									borderLeft: '1px solid #eee',
								}}
							></View>

							<View
								style={{
									paddingLeft: '4px',
									flexBasis: '24px',
									flexGrow: 0,

									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',

									borderLeft: '1px solid #eee',
								}}
							></View>

							<View
								style={{
									paddingLeft: '4px',
									flexBasis: '24px',
									flexGrow: 0,

									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',

									borderLeft: '1px solid #eee',
								}}
							></View>
						</View>
					))}
				</View>
			</View>
		</View>
	</NumberedContentPage>
);
