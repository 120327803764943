import { apiPrivate } from '../config/apiPrivate';

interface IGetOneFormParams {
	formId: string;
}

interface IGetOneFormResp {
	id: string;
	name: string;
	formDetails: {
		id: string;
		order: 0; // this could be unnecessary
		label: string;
		defaultValue: string; // This is always empty. Request fix
		// Im not getting the required either
		isActive: boolean;
		componentDataType: {
			id: string;
		};
	}[];
}

export const getOneForm = async (params: IGetOneFormParams): Promise<IGetOneFormResp> => {
	const { data } = await apiPrivate.get<IGetOneFormResp>(`/api/workflow/forms/${params.formId}`);
	return {
		id: data.id,
		name: data.name,
		formDetails: data.formDetails.sort((a, b) => a.order - b.order),
	};
};
