import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcAddUser: FC<IIconsProps> = props => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 20' fill='none' {...props}>
		<path
			d='M18 7.5V1.5M15 4.5H21M15 18.5V17.3C15 15.6198 15 14.7798 14.673 14.138C14.3854 13.5735 13.9265 13.1146 13.362 12.827C12.7202 12.5 11.8802 12.5 10.2 12.5H5.8C4.11984 12.5 3.27976 12.5 2.63803 12.827C2.07354 13.1146 1.6146 13.5735 1.32698 14.138C1 14.7798 1 15.6198 1 17.3V18.5M11.5 5C11.5 6.933 9.933 8.5 8 8.5C6.067 8.5 4.5 6.933 4.5 5C4.5 3.067 6.067 1.5 8 1.5C9.933 1.5 11.5 3.067 11.5 5Z'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
