import { createCompany } from '@api';
import { Button } from '@atoms';
import { FormInput, FormInputPhone, FormSearchableDropdown, FormSelect } from '@molecules';
import { ImagePicker } from '@organisms';
import { REGEX_EMAIL } from '@utils';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { usePlaces } from '@hooks';
import { useMutation } from '@tanstack/react-query';

interface IFormNewCompanyValues {
	companyName: string;
	companyNationalId: string;
	adminPhoneNumber: string;
	adminEmail: string;
	adminFirstName: string;
	adminLastName: string;
	adminNationalId: string;
	citiesSelectedOptionId: string;
	statesSelectedOptionId: string;
	countriesSelectedOptionId: string;
	image: string | FileList;
}

export const FormNewCompany = () => {
	const navigator = useNavigate();
	const methods = useForm<IFormNewCompanyValues>();

	const {
		formState: { errors, isValid },
		handleSubmit: handleSubmitWrapper,
		register,
		watch,
		control,
		resetField,
	} = methods;
	const { data: places } = usePlaces();

	const mutation = useMutation({
		mutationFn: createCompany,
		onSuccess: () => navigator('/companies'),
	});

	const handleSubmit = async ({
		adminEmail,
		companyNationalId,
		adminFirstName,
		adminLastName,
		adminNationalId,
		citiesSelectedOptionId,
		companyName,
		adminPhoneNumber,
		image,
	}: IFormNewCompanyValues) => {
		mutation.mutate({
			name: companyName,
			adminFirstName,
			adminLastName,
			adminCity: citiesSelectedOptionId,
			adminDni: adminNationalId,
			adminEmail,
			adminPhoneNumber,
			email: adminEmail,
			identificationNumber: companyNationalId,
			file: (image as FileList)[0],
			// This shouldn't be here
			adminAge: 0,
			numberOfCreatedUsers: 0,
			companyCount: 0,
			farmCount: 0,
			yardCount: 0,
			shippingLineCount: 0,
		});
	};

	return (
		<FormProvider {...methods}>
			<form autoComplete='off' onSubmit={handleSubmitWrapper(handleSubmit)}>
				<ImagePicker
					label='Cargar Logo'
					onError={!!errors.image}
					errorMessage={errors.image?.message}
					placeHolderImage='/company-profile-image.png'
					register={{
						...register('image', {
							required: { value: true, message: 'This field is required' },
						}),
					}}
				/>
				<div className='grid grid-cols-4 gap-x-8 gap-y-4 mt-8'>
					<FormInput
						type='text'
						label='Empresa'
						required={true}
						errorMessage={errors.companyName?.message}
						onError={!!errors.companyName}
						placeholder='Bummock S.A'
						register={{
							...register('companyName', {
								required: { value: true, message: 'This field is required' },
							}),
						}}
					/>
					<FormInput
						type='text'
						label='RUC'
						required={true}
						errorMessage={errors.companyNationalId?.message}
						onError={!!errors.companyNationalId}
						placeholder='0999999999001'
						register={{
							...register('companyNationalId', {
								required: { value: true, message: 'This field is required' },
								minLength: {
									value: 10,
									message: 'National ID must be at least 10 characters long',
								},
							}),
						}}
					/>
					<FormInputPhone
						label='Número de Contacto'
						errorMessage={errors.adminPhoneNumber?.message}
						required={true}
						onError={!!errors.adminPhoneNumber}
						placeholder='0999999999'
						register={{
							...register('adminPhoneNumber', {
								required: { value: true, message: 'This field is required' },
								minLength: {
									value: 8,
									message: 'Phone number must be at least 10 characters long',
								},
							}),
						}}
					/>
					<FormInput
						type='email'
						label='Correo Electrónico del Administrador'
						errorMessage={errors.adminEmail?.message}
						required={true}
						onError={!!errors.adminEmail}
						placeholder='admin@company.com'
						register={{
							...register('adminEmail', {
								required: { value: true, message: 'This field is required' },
								pattern: { value: REGEX_EMAIL, message: 'Invalid email' },
								minLength: { value: 6, message: 'Min length is 6' },
								maxLength: { value: 30, message: 'Max length is 30' },
							}),
						}}
					/>
					<FormInput
						type='text'
						label='Nombre del Administrador'
						errorMessage={errors.adminFirstName?.message}
						required={true}
						onError={!!errors.adminFirstName}
						placeholder='Admin'
						register={{
							...register('adminFirstName', {
								required: { value: true, message: 'This field is required' },
							}),
						}}
					/>
					<FormInput
						type='text'
						label='Apellido del Administrador'
						errorMessage={errors.adminLastName?.message}
						required={true}
						onError={!!errors.adminLastName}
						placeholder='Admin'
						register={{
							...register('adminLastName', {
								required: { value: true, message: 'This field is required' },
							}),
						}}
					/>
					<FormInput
						type='text'
						label='Cédula'
						required={true}
						errorMessage={errors.adminNationalId?.message}
						onError={!!errors.adminNationalId}
						placeholder='0999999999'
						register={{
							...register('adminNationalId', {
								required: { value: true, message: 'This field is required' },
								minLength: {
									value: 10,
									message: 'National ID must be at least 10 characters long',
								},
							}),
						}}
					/>
					<FormSelect label='País' disabled={true} options={[{ id: '1', value: 'Ecuador' }]} selectedOptionId='1' />
					<Controller
						control={control}
						name='statesSelectedOptionId'
						defaultValue=''
						rules={{
							required: { value: true, message: 'This field is required' },
						}}
						render={({ field: { ref, onChange, ...rest }, fieldState: { error } }) => (
							<FormSearchableDropdown
								required={true}
								label='Provincia'
								options={places
									.getStatesByCountryId(places.getCountries().find(place => place.name === 'Ecuador')?.id ?? '')
									.map(({ id, name }) => ({ id, value: name }))}
								onError={!!error?.message}
								errorMessage={error?.message}
								onChange={value => {
									resetField('citiesSelectedOptionId');
									onChange(value);
								}}
								{...rest}
							/>
						)}
					/>
					<Controller
						control={control}
						name='citiesSelectedOptionId'
						defaultValue=''
						rules={{
							required: { value: true, message: 'This field is required' },
						}}
						render={({ field: { ref, ...rest }, fieldState: { error } }) => (
							<FormSearchableDropdown
								required={true}
								label='Ciudad'
								options={places
									.getCitiesByStateId(watch('statesSelectedOptionId'))
									.map(({ id, name }) => ({ id, value: name }))}
								onError={!!error?.message}
								errorMessage={error?.message}
								{...rest}
							/>
						)}
					/>
				</div>
				<Button isLoading={mutation.isPending} disabled={!isValid} className='mt-8'>
					Crear Empresa
				</Button>
				{mutation.isError && <p>Some error ocurred while creating the company</p>}
			</form>
		</FormProvider>
	);
};
