import { useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { Button } from '@atoms';
import { FormSearchableDropdown } from '@molecules';
import { assignLocationToUser, QUERY_KEYS } from '@api';
import { Modal } from '../Modal';

import type { IModalProps } from '@interfaces';

interface IAssignLocationFormValues {
	locationId: string;
}

interface IModalAssignLocation extends IModalProps {
	locationsOptions: { id: string; value: string }[];
}

export const ModalAssignLocation = ({ locationsOptions, onCloseModal, isVisible }: IModalAssignLocation) => {
	const { id: userId = '' } = useParams();

	const {
		control,
		formState: { isValid },
		handleSubmit: handleSubmitWrapper,
		reset,
	} = useForm<IAssignLocationFormValues>();
	useEffect(() => {
		if (!isVisible) reset();
	}, [isVisible]);

	const queryClient = useQueryClient();
	const assignLocationMutation = useMutation({
		mutationFn: assignLocationToUser,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.farms, userId],
			});
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEYS.courtyards, userId],
			});
			onCloseModal();
		},
	});

	const handleSubmit = ({ locationId }: IAssignLocationFormValues) => {
		assignLocationMutation.mutate({
			userId,
			locationsIds: [locationId],
		});
	};

	return (
		<Modal title='Asignar Localidad' isOpen={isVisible} onClose={onCloseModal}>
			<p className='text-2xs w-3/4'>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
				magna aliqua.
			</p>
			<form onSubmit={handleSubmitWrapper(handleSubmit)} className='mt-8 flex flex-col gap-8' autoComplete='off'>
				<Controller
					control={control}
					name='locationId'
					defaultValue=''
					rules={{
						required: { value: true, message: 'This field is required' },
					}}
					render={({ field: { ref, ...rest } }) => (
						<FormSearchableDropdown includeEmptyOption={true} label='Localidad' options={locationsOptions} {...rest} />
					)}
				/>
				<div className='flex gap-4'>
					<Button
						type='button'
						disabled={assignLocationMutation.isPending}
						onClick={onCloseModal}
						className='px-9'
						variant='secondary'
					>
						Cancelar
					</Button>
					<Button disabled={!isValid} isLoading={assignLocationMutation.isPending} className='px-9'>
						Guardar
					</Button>
				</div>
			</form>
		</Modal>
	);
};
