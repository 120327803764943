import { ITabRoute } from '@interfaces';
import { TabbedLayout } from '@layout';
import { BoxPage, KitContentPage, KitsPage, KitsTypesPage, NewKitPage, NewKitUsagePage } from '@pages';
import { RouteObject } from 'react-router-dom';

const kitsSubRoutes: ITabRoute[] = [
	{
		path: 'boxes',
		handle: { tabName: 'Kits' },
		children: [
			{ index: true, element: <KitsPage /> },
			{
				path: ':id',
				children: [
					{ index: true, element: <BoxPage /> },
					{
						path: 'kit/:id',
						element: <KitContentPage />,
					},
				],
			},
		],
	},
	{
		path: 'types',
		handle: { tabName: 'Tipos de Kits' },
		element: <KitsTypesPage />,
	},
	{
		path: 'usages',
		handle: { tabName: 'Usos de Kits' },
		element: <NewKitUsagePage />,
	},
];

export const kitsRoutes: RouteObject[] = [
	{
		path: 'kits',
		element: <TabbedLayout tabRoutes={kitsSubRoutes} />,
		children: kitsSubRoutes,
	},
	{
		path: 'kits/new',
		element: <NewKitPage />,
	},
];
