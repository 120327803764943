import { apiPrivate } from '../config/apiPrivate';

interface IDeleteFormParams {
	id: string;
}

interface IDeleteFormResp {}

export const deleteForm = async (params: IDeleteFormParams): Promise<IDeleteFormResp> => {
	const { data } = await apiPrivate.delete<IDeleteFormResp>(`/api/workflow/${params.id}/form`);
	return data;
};
