import { apiPrivate } from '../config/apiPrivate';

interface ICreateFormParams {
	title: string;
	items: {
		id: string;
		label: string;
		order: string;
		isRequired: boolean;
		defaultValue: string;
	}[];
}

interface ICreateFormResp {}

export const createForm = async (params: ICreateFormParams): Promise<ICreateFormResp> => {
	const { data } = await apiPrivate.post<ICreateFormResp>('/api/workflow/form', params);
	return data;
};
