import { useState, useEffect } from 'react';

import { IcArrowDown, IcCheck } from '@atoms';
import { useDetectClickOutside } from '@hooks';

import type { ISelectProps } from './Select.interfaces';

import styles from './Select.module.sass';

export const Select = ({
	className = '',
	isOpen: isOpenProp = false,
	options: optionsProp,
	selectedOptionId: selectedOptionIdProp,
	onChange,
	disabled,
	onClose = () => {},
	includeEmptyOption = false,
}: ISelectProps) => {
	const [selectedOptionId, setSelectedOptionId] = useState<string>(selectedOptionIdProp);
	useEffect(() => setSelectedOptionId(selectedOptionIdProp), [selectedOptionIdProp]);

	const [isOpen, setIsOpen] = useState<boolean>(isOpenProp);
	useEffect(() => setIsOpen(isOpenProp), [isOpenProp]);

	const { elementRef: selectRef } = useDetectClickOutside({
		isOpen,
		onClickOutside: () => setIsOpen(false),
	});

	const options = [...(includeEmptyOption ? [{ id: '-1', value: '' }] : []), ...optionsProp];
	const selectedOption = options.find(option => option.id === selectedOptionId);

	function handleClickOption(optionId: string) {
		setSelectedOptionId(optionId);
		if (selectedOption?.id !== optionId && onChange) onChange(optionId);
	}

	function handleChangeVisibility() {
		if (disabled) return;
		setIsOpen(prev => !prev);
		if (isOpen) onClose();
	}

	return (
		<div
			className={`${styles['select']} ${className} ${disabled ? '!cursor-not-allowed' : ''} ${isOpen ? 'z-10' : ''}`}
			onClick={handleChangeVisibility}
			ref={selectRef}
		>
			<div
				className={`${styles['select__option']} ${styles['select__option--selected']} ${isOpen ? 'hidden' : ''} ${disabled ? 'text-bummock-disabled_grey_2' : ''}`}
			>
				{selectedOption?.value}
				<IcArrowDown
					className={`stroke-2 w-[14px] ml-auto ${disabled ? 'stroke-bummock-disabled_grey_2' : 'stroke-bummock-midnight_blue'}`}
				/>
			</div>
			<div className={`${isOpen ? '' : 'hidden'} ${styles['select__options']}`}>
				<div className={`${styles['select__option']} ${styles['select__option--selected']}`}>
					{selectedOption?.value}
					<IcCheck className='stroke-bummock-midnight_blue ml-auto stroke-2 w-4' />
				</div>
				{options
					.filter(option => option.id !== selectedOption?.id)
					.map(option => (
						<div className={styles['select__option']} key={option.id} onClick={() => handleClickOption(option.id)}>
							{option.value}
						</div>
					))}
			</div>
		</div>
	);
};
