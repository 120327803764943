import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcProcesses: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' fill='none' {...props}>
			<path
				d='M8.625 3.95642H8.9508C11.2362 3.95642 12.3789 3.95642 12.8127 4.36689C13.1877 4.7217 13.3539 5.24438 13.2526 5.75058C13.1355 6.33618 12.2026 6.99606 10.3367 8.31582L7.28829 10.472C5.42243 11.7918 4.48949 12.4517 4.37238 13.0373C4.27114 13.5435 4.43731 14.0661 4.81227 14.421C5.24605 14.8314 6.38877 14.8314 8.6742 14.8314H9.375M6 3.95642C6 5.19906 4.99264 6.20642 3.75 6.20642C2.50736 6.20642 1.5 5.19906 1.5 3.95642C1.5 2.71378 2.50736 1.70642 3.75 1.70642C4.99264 1.70642 6 2.71378 6 3.95642ZM16.5 14.4564C16.5 15.6991 15.4926 16.7064 14.25 16.7064C13.0074 16.7064 12 15.6991 12 14.4564C12 13.2138 13.0074 12.2064 14.25 12.2064C15.4926 12.2064 16.5 13.2138 16.5 14.4564Z'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
