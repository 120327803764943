import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useRef, useState } from 'react';

// confirmationMessage podría aceptar un string o un callback que permita
// definir un mensaje dinámico que se establecería al llamar `confirm`
export const useConfirmation = <R, E, P>(
	mutationOptions: UseMutationOptions<R, E, P> & {
		confirmationMessage?: string;
	}
) => {
	const [isVisible, setIsVisible] = useState(false);
	const { mutate } = useMutation<R, E, P>(mutationOptions);

	const mutationParamsRef = useRef<P | null>({} as P);

	const confirm = (params: P) => {
		setIsVisible(true);
		mutationParamsRef.current = params;
	};

	return {
		confirm,
		confirmationModalProps: {
			isVisible,
			onConfirm: () => {
				// Maybe use mutateAsync and wait for it
				mutate(mutationParamsRef.current!);
				setIsVisible(false);
				mutationParamsRef.current = null;
			},
			onReject: () => {
				setIsVisible(false);
				mutationParamsRef.current = null;
			},
			confirmationMessage: mutationOptions.confirmationMessage,
		},
	};
};
