import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcAdd: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none' {...props}>
			<path
				d='M7.96875 5.5V11.5M4.96875 8.5H10.9688M4.81875 15.25H11.1187C12.3789 15.25 13.0089 15.25 13.4902 15.0048C13.9136 14.789 14.2578 14.4448 14.4735 14.0215C14.7187 13.5402 14.7188 12.9101 14.7188 11.65V5.35C14.7188 4.08988 14.7187 3.45982 14.4735 2.97852C14.2578 2.55516 13.9136 2.21095 13.4902 1.99524C13.0089 1.75 12.3789 1.75 11.1188 1.75H4.81875C3.55863 1.75 2.92857 1.75 2.44727 1.99524C2.02391 2.21095 1.6797 2.55516 1.46399 2.97852C1.21875 3.45982 1.21875 4.08988 1.21875 5.35V11.65C1.21875 12.9101 1.21875 13.5402 1.46399 14.0215C1.6797 14.4448 2.02391 14.789 2.44727 15.0048C2.92857 15.25 3.55863 15.25 4.81875 15.25Z'
				stroke='#092D45'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
