import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcPaperPlane: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 23' fill='none' {...props}>
			<path
				d='M9.49952 13.0001L19.9995 2.50005M9.6271 13.3281L12.2552 20.0861C12.4867 20.6815 12.6025 20.9791 12.7693 21.066C12.9139 21.1414 13.0862 21.1415 13.2308 21.0663C13.3977 20.9796 13.5139 20.6821 13.7461 20.087L20.3364 3.19925C20.5461 2.66207 20.6509 2.39348 20.5935 2.22185C20.5437 2.0728 20.4268 1.95583 20.2777 1.90604C20.1061 1.84871 19.8375 1.95352 19.3003 2.16315L2.41258 8.75349C1.8175 8.98572 1.51997 9.10183 1.43326 9.26873C1.35809 9.41342 1.35819 9.58567 1.43353 9.73027C1.52043 9.89707 1.81811 10.0128 2.41345 10.2444L9.17146 12.8725C9.29231 12.9195 9.35273 12.943 9.40361 12.9793C9.44871 13.0114 9.48815 13.0509 9.52031 13.096C9.55661 13.1468 9.58011 13.2073 9.6271 13.3281Z'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
