import { useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';

import { findAllWorkflows, getOneWorkflow } from '@api';
import { Badge, Button, IcEdit, IcPlus, IcTrash } from '@atoms';
import { useTableQuery } from '@hooks';
import { BaseTable, ModalEditWorkflow, ModalNewWorkflow, TablePagination, TableSearchbar } from '@organisms';

import type { IWorkflow } from '@interfaces';

const columnHelper = createColumnHelper<IWorkflow>();
const workflowsColumns = [
	columnHelper.accessor('title', {
		header: 'Nombre de Flujo',
		cell: ({ row }) => <span className='capitalize'>{row.original.title.toLowerCase()}</span>,
	}),
	columnHelper.accessor('description', {
		header: 'Descripción',
		cell: ({ row }) => <span className='capitalize'>{row.original.description?.toLowerCase() ?? '-'}</span>,
	}),
	columnHelper.accessor('isShared', {
		header: 'Es compartido',
		cell: ({ row }) => (
			<Badge variant={row.original.isShared ? 'success' : 'warning'} label={row.original.isShared ? 'Sí' : 'No'} />
		),
		meta: { alignment: 'center' },
	}),
];

const WorkflowsPage = () => {
	const [editingWorkflowId, setEditingWorkflowId] = useState('');
	const [isModalVisible, setIsModalVisible] = useState(false);

	const queryClient = useQueryClient();
	const { data } = useQuery({
		queryKey: ['form', editingWorkflowId],
		queryFn: () => getOneWorkflow({ workflowId: editingWorkflowId }),
		enabled: !!editingWorkflowId,
	});
	const tableQueryResult = useTableQuery({
		queryKey: ['workflows'],
		fetcherFn: findAllWorkflows,
		tableColumns: [
			...workflowsColumns,
			columnHelper.display({
				header: 'Acciones',
				cell: ({ row }) => {
					const { id } = row.original;
					console.log(id);
					return (
						<div className='flex justify-end gap-4'>
							<IcEdit
								onClick={() => handleEditWorkflow(id)}
								className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
							<IcTrash
								onClick={() => alert('delete')}
								className='stroke-2 w-5 stroke-bummock-disabled_grey_2  hover:stroke-bummock-midnight_blue cursor-pointer transition-all ease-in-out duration-200'
							/>
						</div>
					);
				},
				meta: { alignment: 'right' },
			}),
		],
	});

	function handleEditWorkflow(id: string) {
		// Get form data
		// Show modal with populated data
		setEditingWorkflowId(id);
	}

	function handleCloseEditModal() {
		queryClient.invalidateQueries({
			queryKey: ['workflows', editingWorkflowId],
		});
		setEditingWorkflowId('');
	}

	return (
		<>
			{/*
			<ModalConfirmation {...confirmationModalProps} />


                */}
			<ModalEditWorkflow
				initialWorkflowData={data ?? { id: '', name: '', details: [] }}
				isModalVisible={!!data && !!editingWorkflowId}
				onClose={handleCloseEditModal}
			/>
			<ModalNewWorkflow isModalVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />

			<div className='flex justify-between'>
				<TableSearchbar tableQueryResult={tableQueryResult} />
				<div className='flex gap-4'>
					<Button onClick={() => setIsModalVisible(true)}>
						<IcPlus className='stroke-2 stroke-bummock-off_white w-5' />
						Crear Flujo de Trabajo
					</Button>
				</div>
			</div>
			<BaseTable tableQueryResult={tableQueryResult} />
			<TablePagination tableQueryResult={tableQueryResult} />
		</>
	);
};

export default WorkflowsPage;
