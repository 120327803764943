import { Badge, ButtonGoBack, Card } from '@atoms';
import { useProcessDetails } from '@hooks';
import { useNavigate, useParams } from 'react-router-dom';

const InspectionDetailsPage = () => {
	const { id: processId } = useParams();
	const navigate = useNavigate();
	const { processDetails, isLoading, isError } = useProcessDetails({
		id: processId ?? '',
	});
	console.log(processDetails);

	if (isLoading) return <p>Loading...</p>;

	if (isError) return <p className='text-red-500'>An error has ocurred while getting the data.</p>;

	return (
		<>
			<ButtonGoBack onClick={() => navigate(-1)} />
			<h2 className='text-xl font-bold text-bummock-ocean_blue'>Detalles de inspección</h2>

			<div className='grid grid-cols-5 gap-8'>
				<Card title='Inspección' value={processDetails?.id.slice(0, 8).toUpperCase()} />
				<Card title='Contenedor' value={processDetails?.container} />
				<Card title='Cliente' value={processDetails?.client} />
				<Card title='Fecha de creación' value={processDetails?.creationDate} />
				{/*<Card title='Realizado por' value='John Smith' />*/}
				<Card title='Estado' value={<Badge label='Inspeccionado' />} />
			</div>

			<h2 className='text-xl font-bold text-bummock-ocean_blue'>Contenido de Kit</h2>
			{/* Podría ordenarse en base a la cantidad de labels */}
			<div className='flex flex-row gap-[4rem] flex-wrap'>
				{processDetails?.kitContent.securityItems
					.sort((a, b) => a.labels.length - b.labels.length)
					.map(securityItem => (
						<div key={securityItem.name}>
							<span className='text-sm font-semibold text-bummock-midnight_blue'>{securityItem.name}</span>
							<div className='flex gap-4 mt-4'>
								{securityItem.labels.map(label => (
									<div
										className='p-2 text-bummock-dark_grey bg-bummock-disabled_grey border border-bummock-grey rounded-lg'
										key={label}
									>
										{label}
									</div>
								))}
							</div>
						</div>
					))}
			</div>

			{processDetails?.workFlows.map(workflow => (
				<div className='flex flex-col gap-8' key={workflow.name}>
					<hr />

					<h2 className='text-xl font-bold text-bummock-ocean_blue capitalize'>{workflow.name.toLocaleLowerCase()}</h2>
					<ol className='flex flex-col gap-4'>
						{workflow.steps.map(step => (
							<li key={step.name}>
								<span className='text-sm font-semibold text-bummock-midnight_blue capitalize'>
									{step.name.toLowerCase()}
								</span>
								<ol className='flex gap-8 flex-wrap mt-4'>
									{step.pictures.map(picture => (
										<li key={picture.url}>
											<img
												className='border border-bummock-grey bg-bummock-disabled_grey rounded-2xl h-[400px] w-[300px] object-contain'
												src={picture.url}
											/>
											<div className='flex justify-between mt-2'>
												<span className='text-sm'>{picture.tookBy}</span>
												<a
													target='_blank'
													rel='noreferrer'
													href={`https://www.openstreetmap.org/#map=15/${picture.coords.lat}/${picture.coords.lng}`}
													className='text-sm text-bummock-artic_blue underline'
												>
													{picture.coords.lat.length === 1 ? '0.0' : Number(picture.coords.lat).toFixed(6)}{' '}
													{picture.coords.lng.length === 1 ? '0.0' : Number(picture.coords.lng).toFixed(6)}
												</a>
											</div>
										</li>
									))}
								</ol>
							</li>
						))}
					</ol>
				</div>
			))}
		</>
	);
};

export default InspectionDetailsPage;
