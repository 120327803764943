import { forwardRef } from 'react';

import { IcCheck } from '@atoms';

import type { IInputCheckboxProps } from './InputCheckbox.interfaces';

import styles from './InputCheckbox.module.sass';

const InputCheckbox = ({
	className = '',
	checked = false,
	onError: hasError,
	errorMessage,
	...rest
}: IInputCheckboxProps) => {
	return (
		<div className={styles['container']}>
			<input checked={checked} className={`${styles['container__checkbox']} ${className}`} type='checkbox' {...rest} />
			{checked && <IcCheck className={styles['container__check-icon']} />}
		</div>
	);
};

const ForwardedCheckbox = forwardRef<HTMLInputElement, IInputCheckboxProps>(InputCheckbox);

export { ForwardedCheckbox as InputCheckbox };
