import { FIELD_TYPES } from '@utils';
import { Field } from './interfaces';
import { IFieldValues, IItem } from '@interfaces';
import { v4 } from 'uuid';
import { Fragment, useReducer, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { arrayMove } from '@dnd-kit/sortable';
import {
	FormDateField,
	FormInformativeTextField,
	FormKitSkanField,
	FormListField,
	FormNumberField,
	FormPhotoField,
	FormQrScanField,
	FormTextField,
	FormTextScanField,
	FormTimeField,
	FormTitleField,
} from '@organisms';
import { Button, DashedContainer, IcAdd } from '@atoms';
import { DraggableItem, DraggableList, FormInput, FormSearchableDropdown } from '@molecules';
import { useFormModalContext } from './ctx';

type Action =
	| {
			type: 'add' | 'reset';
	  }
	| {
			type: 'cancel';
			payload: { id: string };
	  }
	| {
			type: 'replace';
			payload: Field[];
	  }
	| {
			type: 'edit';
			payload: string;
	  }
	| {
			type: 'delete';
			payload: string;
	  }
	| {
			type: 'changeType';
			payload: {
				fieldId: string;
				newType: (typeof FIELD_TYPES)[number];
			};
	  }
	| {
			type: 'saveFormData';
			payload: {
				fieldId: string;
				formData: IFieldValues;
			};
	  };

const INITIAL_STATE: Field[] = [
	{
		id: v4(),
		// TODO: Add nullable backend ID
		backendId: '',
		isBeingEdited: true,
		isValid: false,
		type: '' as 'Texto',
		data: {
			id: '',
			label: '',
			defaultValue: '',
			isRequired: true,
		},
	},
];

function reducer(oldState: Field[], action: Action): Field[] {
	switch (action.type) {
		case 'reset': {
			return INITIAL_STATE;
		}
		case 'add': {
			const newState = oldState.map(field => ({
				...field,
				isBeingEdited: false,
			}));
			return [
				...newState,
				{
					id: v4(),
					backendId: '',
					isBeingEdited: true,
					type: '' as 'Texto',
					isValid: false,
					data: {
						id: '', // TODO: get the ID of the text component
						label: '',
						defaultValue: '',
						isRequired: true,
					},
				},
			];
		}
		case 'cancel': {
			const foundIndex = oldState.findIndex(item => item.id === action.payload.id);
			if (foundIndex === -1) return oldState;
			const newState = [...oldState];
			newState[foundIndex].type = '' as 'Texto';
			newState[foundIndex].data = {
				id: '',
				label: '',
				defaultValue: '',
				isRequired: false,
			};
			return newState;
		}
		case 'replace': {
			return action.payload;
		}
		case 'edit': {
			const newState = oldState.map(field => ({
				...field,
				isBeingEdited: false,
			}));
			const clickedItem = newState.find(field => field.id === action.payload);
			if (!clickedItem) return oldState;
			clickedItem.isBeingEdited = true;
			return newState;
		}
		case 'delete': {
			if (oldState.length === 1) return oldState;
			const newState = oldState
				.filter(field => field.id !== action.payload)
				.map(field => ({ ...field, isBeingEdited: false }));

			const isEditingLastItem = oldState.at(-1)?.isBeingEdited;
			if (isEditingLastItem) {
				const newLastElement = newState.at(-1);
				if (!newLastElement) return oldState;
				newLastElement.isBeingEdited = true;
			} else {
				const editingFieldIndex = oldState.findIndex(field => field.isBeingEdited);
				if (editingFieldIndex === -1) return oldState;
				newState[editingFieldIndex].isBeingEdited = true;
			}
			return newState;
		}
		case 'changeType': {
			const newState = [...oldState];
			const { fieldId, newType } = action.payload;
			const foundField = newState.find(field => field.id === fieldId);
			if (!foundField) return oldState;
			foundField.type = newType;
			return newState;
		}
		case 'saveFormData': {
			const newState = [...oldState];
			const { fieldId, formData } = action.payload;
			const foundField = newState.find(field => field.id === fieldId);
			if (!foundField) return oldState;
			foundField.data = formData;
			foundField.isValid = true;
			return newState;
		}
		default:
			return oldState;
	}
}

interface IInnerFormProps {
	onClose: () => void;
	initialFormTitle?: string;
	initialState?: Field[];
	initialTitle?: string;
	onSave: (
		formData: {
			title: string;
			items: {
				fieldId?: string;
				id: string; // this is the component id
				isRequired: boolean;
				defaultValue: string;
				label: string;
				order: string;
			}[];
		},
		onSuccess?: () => void
	) => void;
	isLoading: boolean;
}

export function InnerForm({ isLoading, initialFormTitle, initialState, initialTitle, onSave }: IInnerFormProps) {
	const [title, setTitle] = useState(initialTitle || '');
	const [items, dispatch] = useReducer(reducer, initialState?.length ? initialState : INITIAL_STATE);
	const { setShowError, canSubmit, setCanSubmit } = useFormModalContext();
	const fieldsContainerElementRef = useRef<HTMLDivElement>(null);

	const form = useForm<{ formTitle: string }>({
		defaultValues: { formTitle: initialFormTitle },
	});

	function showErrorForSeconds(value: boolean, seconds: number = 3) {
		setShowError(value);
		setTimeout(() => setShowError(false), seconds * 1000);
	}

	// WATCH OUT! This assumes there is always a item being edited.
	const editingField = items.find(item => item.isBeingEdited)!;

	function handleAddItem() {
		if (canSubmit) {
			// early return as the inner form is submittable
			showErrorForSeconds(true);
			return;
		}
		dispatch({ type: 'add' });
		setTitle('');

		if (!fieldsContainerElementRef.current) return;
		const fieldsContainer = fieldsContainerElementRef.current;
		// Timeout to make sure the DOM has been updated
		setTimeout(() =>
			fieldsContainer.scrollTo({
				top: fieldsContainer.scrollHeight,
				behavior: 'smooth',
			})
		);
	}

	function handleCancel() {
		dispatch({ type: 'cancel', payload: { id: editingField.id } });
		setTitle('');
		setCanSubmit(false);
	}

	function handleEnableItemEdition(id: string) {
		if (canSubmit) {
			// early return as the inner form is submittable
			showErrorForSeconds(true);
			return;
		}
		dispatch({ type: 'edit', payload: id });
		const found = items.find(item => item.id === id);
		setTitle(found!.data.label);
	}

	function handleSetItems(
		stateUpdaterFn: (oldState: Omit<IItem, 'label'>[]) => {
			oldIndex: number;
			newIndex: number;
		}
	) {
		const { oldIndex, newIndex } = stateUpdaterFn(items);
		const swappedArray = arrayMove(items, oldIndex, newIndex);
		dispatch({ type: 'replace', payload: swappedArray });
	}

	function handleChangeFieldType(newType: (typeof FIELD_TYPES)[number]) {
		setTitle('');
		dispatch({
			type: 'changeType',
			payload: { fieldId: editingField.id, newType },
		});
	}

	function handleMoveUp(index: number) {
		const swappedArray = arrayMove(items, index, index - 1);
		dispatch({ type: 'replace', payload: swappedArray });
	}

	function handleMoveDown(index: number) {
		const swappedArray = arrayMove(items, index, index + 1);
		dispatch({ type: 'replace', payload: swappedArray });
	}

	function handleDeleteField(id: string) {
		dispatch({ type: 'delete', payload: id });
		setTitle('');
	}

	function handleSaveForm(formValues: { formTitle: string }) {
		console.log('submitted with', formValues);
		onSave(
			{
				title: formValues.formTitle,
				items: items.map(({ data: field, backendId }, index) => ({
					fieldId: backendId,
					id: field.id,
					isRequired: field.isRequired,
					defaultValue: field.defaultValue || '',
					label: field.label,
					order: index.toString(),
				})),
			},
			() => dispatch({ type: 'reset' })
		);
	}

	function renderForm(fieldType: (typeof FIELD_TYPES)[number]) {
		switch (fieldType) {
			case 'Texto':
				return (
					<FormTextField
						title={title}
						defaultValues={{ ...editingField.data, title }}
						onSave={formValues => {
							console.log('saving with', {
								...formValues,
								title,
							});
							dispatch({
								type: 'saveFormData',
								payload: {
									fieldId: editingField.id,
									formData: formValues,
								},
							});
						}}
						onDelete={handleCancel}
					/>
				);
			case 'Número':
				return (
					<FormNumberField
						title={title}
						defaultValues={{ ...editingField.data, title }}
						onSave={formValues =>
							dispatch({
								type: 'saveFormData',
								payload: {
									fieldId: editingField.id,
									formData: formValues,
								},
							})
						}
						onDelete={handleCancel}
					/>
				);
			case 'Lista desplegable':
			case 'Lista desplegable selección múltiple':
				return (
					<FormListField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							dispatch({
								type: 'saveFormData',
								payload: {
									fieldId: editingField.id,
									formData: {
										...formValues,
										label: title,
									},
								},
							})
						}
						onDelete={handleCancel}
					/>
				);
			case 'Fecha y hora':
				return (
					<FormDateField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							dispatch({
								type: 'saveFormData',
								payload: {
									fieldId: editingField.id,
									formData: {
										...formValues,
										label: title,
									},
								},
							})
						}
						onDelete={handleCancel}
					/>
				);
			case 'Hora':
				return (
					<FormTimeField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							dispatch({
								type: 'saveFormData',
								payload: {
									fieldId: editingField.id,
									formData: {
										...formValues,
										label: title,
									},
								},
							})
						}
						onDelete={handleCancel}
					/>
				);
			case 'Título':
				return (
					<FormTitleField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							dispatch({
								type: 'saveFormData',
								payload: {
									fieldId: editingField.id,
									formData: {
										...formValues,
										label: title,
									},
								},
							})
						}
						onDelete={handleCancel}
					/>
				);
			case 'Texto informativo / instrucción':
				return (
					<FormInformativeTextField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							dispatch({
								type: 'saveFormData',
								payload: {
									fieldId: editingField.id,
									formData: {
										...formValues,
										label: title,
									},
								},
							})
						}
						onDelete={handleCancel}
					/>
				);
			case 'Separador':
				return (
					<div className='flex-grow flex items-end'>
						<div className='flex gap-4'>
							<Button
								onClick={() => {
									dispatch({
										type: 'saveFormData',
										payload: {
											fieldId: editingField.id,
											formData: {
												id: 'd12c56c8-df55-4560-b060-dffda4baf904',
												label: 'Separador',
												defaultValue: '',
												isRequired: false,
											},
										},
									});
									setTitle('Separador');
								}}
							>
								Guardar Cambios
							</Button>
							<Button onClick={handleCancel} type='button' className='w-[8.125rem]' variant='secondary'>
								Cancelar
							</Button>
						</div>
					</div>
				);
			case 'Foto':
				return (
					<FormPhotoField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							dispatch({
								type: 'saveFormData',
								payload: {
									fieldId: editingField.id,
									formData: {
										...formValues,
										label: title,
									},
								},
							})
						}
						onDelete={handleCancel}
					/>
				);
			case 'Escáner de kit':
				return (
					<FormKitSkanField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							dispatch({
								type: 'saveFormData',
								payload: {
									fieldId: editingField.id,
									formData: {
										...formValues,
										label: title,
									},
								},
							})
						}
						onDelete={handleCancel}
					/>
				);
			case 'Escáner de texto':
				return (
					<FormTextScanField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							dispatch({
								type: 'saveFormData',
								payload: {
									fieldId: editingField.id,
									formData: {
										...formValues,
										label: title,
									},
								},
							})
						}
						onDelete={handleCancel}
					/>
				);
			case 'Escáner de QR':
				return (
					<FormQrScanField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							dispatch({
								type: 'saveFormData',
								payload: {
									fieldId: editingField.id,
									formData: {
										...formValues,
										label: title,
									},
								},
							})
						}
						onDelete={handleCancel}
					/>
				);
			default:
				// Esto es útil para el "Código de barra del formato"
				return (
					<FormQrScanField
						title={title}
						defaultValues={editingField.data}
						onSave={formValues =>
							dispatch({
								type: 'saveFormData',
								payload: {
									fieldId: editingField.id,
									formData: {
										...formValues,
										label: title,
									},
								},
							})
						}
						onDelete={handleCancel}
					/>
				);
		}
	}

	const isFormValid = items.reduce((prev, curr) => prev && curr.isValid, true);

	return (
		<Fragment>
			<p className='text-xs'>
				Personaliza un nuevo formulario definiendo los campos, valores predeterminados y opciones de visualización.
				Todos los cambios se pueden modificar más adelante.
			</p>

			<FormInput
				required
				errorMessage={form.formState.errors.formTitle?.message}
				onError={!!form.formState.errors.formTitle}
				register={form.register('formTitle', {
					required: { message: 'Ingresa un título', value: true },
				})}
				label='Título'
				placeholder='Título de Formulario'
			/>

			<hr />

			<div className='flex gap-4'>
				<div className='basis-0 grow'>
					<h2 className='text-xs font-semibold text-bummock-midnight_blue mb-2'>Campos del formulario</h2>
					<div className='flex flex-col gap-4 h-[465px]'>
						<div ref={fieldsContainerElementRef} className='flex flex-col gap-4 overflow-x-hidden overflow-scroll pr-4'>
							<DraggableList items={items} setItems={handleSetItems}>
								{items.map((item, index) => {
									// console.log(title, index, item.title);
									return (
										<DraggableItem
											key={item.id}
											id={item.id}
											label={editingField.id === item.id ? title : item.data.label}
											isBeingEdited={item.isBeingEdited}
											onEdit={() => handleEnableItemEdition(item.id)}
											onMoveUp={() => handleMoveUp(index)}
											onMoveDown={() => handleMoveDown(index)}
											onDelete={() => handleDeleteField(item.id)}
										/>
									);
								})}
							</DraggableList>
						</div>
						<DashedContainer
							onClick={handleAddItem}
							className='cursor-pointer flex shrink-0 gap-2 text-sm items-center'
						>
							<IcAdd />
							<span className='font-semibold text-bummock-midnight_blue text-xs'>Agregar otro campo</span>
						</DashedContainer>
					</div>
				</div>
				<div className='w-[1px] bg-bummock-disabled_grey'></div>
				<div className='basis-0 grow flex flex-col'>
					<div className='flex flex-col gap-6 grow'>
						{/* Esto va fijo */}
						{/* When this happen the type of the current field should update */}
						<FormSearchableDropdown
							label='Tipo de campo'
							options={[
								{
									id: '',
									value: 'Selecciona un tipo de campo',
								},
								...FIELD_TYPES.map(fieldType => ({
									id: fieldType,
									value: fieldType,
								})),
							]}
							value={editingField.type}
							onChange={(newType: unknown) => handleChangeFieldType(newType as (typeof FIELD_TYPES)[number])}
						/>
						{/* This is dynamic based on the selected field type */}
						{editingField.type ? (
							<Fragment key={editingField.id}>
								{editingField.type !== 'Separador' && (
									<FormInput
										label='Título'
										placeholder='Título del campo'
										required
										value={title}
										onChange={e => setTitle(e!.target.value)}
									/>
								)}

								{renderForm(editingField.type)}
							</Fragment>
						) : null}
					</div>
				</div>
			</div>
			<Button
				disabled={!isFormValid}
				isLoading={isLoading}
				onClick={form.handleSubmit(handleSaveForm)}
				className='text-sm ml-auto h-10'
			>
				Guardar Formulario
			</Button>
		</Fragment>
	);
}
