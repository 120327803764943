import {
	IcKit,
	IcBusiness,
	IcCompanies,
	IcInventory,
	IcUsers,
	IcLockWithoutKey,
	IcBox,
	IcReport,
	SidebarLink,
	IcProcesses,
} from '@atoms';

export const Navbar = ({ className = '' }: { className?: string }) => {
	return (
		<nav className={className}>
			<SidebarLink
				permission='USERS'
				icon={<IcUsers className='stroke-bummock-midnight_blue stroke-[2.2]' />}
				to='/users'
				label='Usuarios'
			/>
			<SidebarLink
				permission='COMPANIES'
				icon={<IcBusiness className='stroke-bummock-midnight_blue stroke-2' />}
				to='/companies'
				label='Empresas'
			/>
			<SidebarLink
				permission='MY_COMPANY'
				icon={<IcBusiness className='stroke-bummock-midnight_blue stroke-2' />}
				to='/company/information'
				label='Empresa'
			/>
			<SidebarLink
				permission='ENTITIES'
				icon={<IcCompanies className='stroke-bummock-midnight_blue stroke-2' />}
				to='/entities/clients'
				label='Entidades'
			/>
			<SidebarLink
				permission='MY_COMPANY'
				icon={<IcProcesses className='stroke-bummock-midnight_blue stroke-2' />}
				to='/processes/forms'
				label='Procesos'
			/>
			<SidebarLink
				permission='INVENTORY'
				icon={<IcInventory className='stroke-bummock-midnight_blue stroke-2' />}
				to='/inventory/products'
				label='Inventario'
			/>
			<SidebarLink
				permission='INVENTORY'
				icon={<IcKit className='stroke-bummock-midnight_blue stroke-2' />}
				to='/kits/boxes'
				label='Kits'
			/>
			<SidebarLink
				permission='SEALS'
				icon={<IcLockWithoutKey className='stroke-bummock-midnight_blue stroke-2' />}
				to='/seals/encrypted'
				label='Sellos'
			/>
			<SidebarLink
				permission='DISPATCH'
				icon={<IcBox className='stroke-bummock-midnight_blue stroke-[0] fill-bummock-midnight_blue' />}
				to='/dispatch/create'
				label='Despacho'
			/>
			<SidebarLink
				permission='INSPECTIONS'
				icon={<IcReport className='stroke-bummock-midnight_blue stroke-[1.7]' />}
				to='/inspections/all'
				label='Inspecciones'
			/>
		</nav>
	);
};
