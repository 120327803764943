import { IFieldValues } from '@interfaces';
import { useForm } from 'react-hook-form';
import { FormWrapper } from './FormWrapper';
import { FormInputCheckbox } from '@molecules';

interface ITextScanFieldFormValues {
	title: string;
	isRequired: boolean;
}

interface ITextScanFieldFormProps {
	defaultValues: IFieldValues;
	onSave: (formValues: IFieldValues) => void;
	onDelete: () => void;
}

export function FormTextScanField({
	defaultValues,
	onSave,
	onDelete,
	title,
}: ITextScanFieldFormProps & { title: string }) {
	const id = 'ce5cbfcc-1215-4f9b-8fb4-820d6c0b2e3a';
	const form = useForm<ITextScanFieldFormValues>({
		defaultValues: { ...defaultValues, title: defaultValues.label },
	});

	function handleSubmit(formValues: ITextScanFieldFormValues) {
		console.log('form submitted with values', formValues);
		onSave({
			id,
			label: formValues.title,
			defaultValue: '',
			isRequired: formValues.isRequired,
		});
	}

	return (
		<FormWrapper title={title} form={form} onSubmit={form.handleSubmit(handleSubmit)} onCancel={onDelete}>
			<FormInputCheckbox label='Este campo es obligatorio' inputName='isRequired' form={form} />
		</FormWrapper>
	);
}
