import type { FC } from 'react';

import { NavLink } from 'react-router-dom';

import type { ILinkIconProps } from './LinkIcon.interfaces';

import styles from './LinkIcon.module.sass';

export const LinkIcon: FC<ILinkIconProps> = ({ className = '', to, icon }) => {
	return (
		<NavLink className={({ isActive }) => `${styles['link']} ${className} ${isActive ? styles['active'] : ''}`} to={to}>
			{icon}
		</NavLink>
	);
};
