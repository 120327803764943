import { findAllCategories, QUERY_KEYS } from '@api';
import { useQuery } from '@tanstack/react-query';

export const useCategories = () => {
	const queryResult = useQuery({
		queryKey: [QUERY_KEYS.categories],
		queryFn: () =>
			findAllCategories({
				page: 0,
				limit: 100000,
				sorting: [],
				searchTerm: '',
			}),
	});

	return queryResult;
};
