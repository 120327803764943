import { Button } from '@atoms';
import { FormInput, FormInputPhone, FormSearchableDropdown } from '@molecules';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { REGEX_EMAIL, getDirtyFormData } from '@utils';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { usePlaces } from '@hooks';
import { IFormEditUserValues } from '@interfaces';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUser } from '@api';
import toast from 'react-hot-toast';

interface IFormEditUserInformationProps {
	editingUser: { id: string } & IFormEditUserValues;
}

export const FormEditUserInformation = ({ editingUser }: IFormEditUserInformationProps) => {
	const { id: userId } = useParams();
	const isEditingUserFromTable = !!userId;
	const methods = useForm<IFormEditUserValues>({
		defaultValues: editingUser,
	});
	const {
		formState: { isDirty, errors, dirtyFields, defaultValues },
		handleSubmit: handleSubmitWrapper,
		register,
		watch,
		control,
		reset,
	} = methods;

	const { data: places } = usePlaces();
	const [selectedState, setSelectedState] = useState(places.getStateByCityId(watch('city')));

	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: updateUser,
		onSuccess: (_, { id, ...savedFields }) => {
			reset(
				{
					...defaultValues,
					...savedFields,
				},
				{ keepDirty: false }
			);
			queryClient.invalidateQueries({ queryKey: ['user', editingUser.id] });
			// Maybe this is too agressive
			queryClient.removeQueries({ queryKey: ['company'] });
			toast.success('Información actualizada.');
		},
	});

	function handleSubmit(formData: IFormEditUserValues) {
		const dirties = getDirtyFormData(dirtyFields, formData);
		mutation.mutate({
			id: editingUser.id,
			...dirties,
		});
	}

	return (
		<FormProvider {...methods}>
			<form autoComplete='off' onSubmit={handleSubmitWrapper(handleSubmit)}>
				<div className='grid grid-cols-4 gap-x-8 gap-y-4'>
					<FormInput type='text' label='Empresa' disabled={true} placeholder='BUMMOCK S.A' />
					<FormInput
						type='text'
						label='Número de Cédula'
						errorMessage={errors.dni?.message}
						disabled={!isEditingUserFromTable}
						onError={!!errors.dni}
						register={{
							...register('dni', {
								required: { value: true, message: 'This field is required' },
								minLength: {
									value: 10,
									message: 'National ID must be 10 characters long',
								},
								maxLength: {
									value: 10,
									message: 'National ID must be 10 characters long',
								},
								validate: v => {
									const everyCharacterIsDigit = /^\d+$/.test(v);
									return everyCharacterIsDigit || 'Every character should be a digit';
								},
							}),
						}}
					/>
					<FormInputPhone
						label='Número de Celular'
						errorMessage={errors.phoneNumber?.message}
						onError={!!errors.phoneNumber}
						register={{
							...register('phoneNumber', {
								required: { value: true, message: 'This field is required' },
								minLength: {
									value: 9,
									message: 'Phone number must be at least 9 characters long',
								},
								maxLength: {
									value: 10,
									message: 'Phone number must be at most 10 characters long',
								},
								validate: v => {
									const everyCharacterIsDigit = /^\d+$/.test(v);
									return everyCharacterIsDigit || 'Every character should be a digit';
								},
							}),
						}}
					/>
					<FormInput
						type='email'
						label='Correo Electrónico'
						disabled={!isEditingUserFromTable}
						errorMessage={errors.email?.message}
						onError={!!errors.email}
						register={{
							...register('email', {
								required: { value: true, message: 'This field is required' },
								pattern: { value: REGEX_EMAIL, message: 'Invalid email' },
								minLength: { value: 6, message: 'Min length is 6' },
								maxLength: { value: 30, message: 'Max length is 30' },
							}),
						}}
					/>
					<FormInput
						type='text'
						label='Nombre'
						errorMessage={errors.firstName?.message}
						onError={!!errors.firstName}
						register={{
							...register('firstName', {
								required: { value: true, message: 'This field is required' },
							}),
						}}
					/>
					<FormInput
						type='text'
						label='Apellido'
						errorMessage={errors.lastName?.message}
						onError={!!errors.lastName}
						register={{
							...register('lastName', {
								required: { value: true, message: 'This field is required' },
							}),
						}}
					/>
					<FormSearchableDropdown
						required={true}
						label='Provincia'
						options={places
							.getStatesByCountryId(places.getCountries().find(place => place.name === 'Ecuador')?.id ?? '')
							.map(({ id, name }) => ({ id, value: name }))}
						value={places.getStateByCityId(watch('city'))}
						onChange={value => setSelectedState(value)}
					/>
					<Controller
						control={control}
						name='city'
						defaultValue=''
						render={({ field: { ref, ...rest } }) => (
							<FormSearchableDropdown
								required={true}
								label='Ciudad'
								options={places.getCitiesByStateId(selectedState).map(({ id, name }) => ({ id, value: name }))}
								{...rest}
							/>
						)}
					/>
					<FormSearchableDropdown
						disabled={true}
						label='País'
						options={[{ id: 'ECUADOR', value: 'Ecuador' }]}
						value={'ECUADOR'}
					/>
				</div>
				<Button isLoading={mutation.isPending} disabled={!isDirty} className='mt-8'>
					Guardar Cambios
				</Button>
			</form>
		</FormProvider>
	);
};
