import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcArrowNarrowUp: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 18' fill='none' {...props}>
			<path d='M7 17V1M7 1L1 7M7 1L13 7' stroke='#092D45' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	);
};
