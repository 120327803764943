import type { FC } from 'react';

import type { IIconsProps } from './Icons.interfaces';

export const IcMap: FC<IIconsProps> = props => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 23' fill='none' {...props}>
			<path
				d='M8 17.5L1 21.5V5.5L8 1.5M8 17.5L15 21.5M8 17.5V1.5M15 21.5L21 17.5V1.5L15 5.5M15 21.5V5.5M15 5.5L8 1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
