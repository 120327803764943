export const PROCESSES_STEP_TYPES = [
	'Camara',
	'Scanner Document',
	'Custom Painter parte interna',
	'Custom Painter parte externa 1',
	'Custom Painter parte externa 2',
	'Formulario',
	// 'Barcode/QR Scanner',
	'Canvas Drawing Tool',
] as const;

// These are the IDs that have to be sent for each component type
export const PROCESSES_STEP_TYPES_WITH_ID: Record<(typeof PROCESSES_STEP_TYPES)[number], string> = {
	Camara: 'e70ad13f-6aa7-4e83-bc89-269fd279a72a',
	'Scanner Document': '7089a802-2de9-457d-86c7-9884e9ea0584',
	'Custom Painter parte interna': 'c0a7969e-649b-4286-97d7-f165926226f8',
	'Custom Painter parte externa 1': '279e544a-44e7-464c-b232-724143f02108',
	'Custom Painter parte externa 2': '84aab4d7-5094-4b69-b002-27859412cf0b',
	Formulario: '62271e89-ac4c-4330-bfa8-1fe1baf7ffb3',
	'Canvas Drawing Tool': 'DEFINE_THIS_ID',
};

export const PROCESS_TYPE_BY_ID: Record<string, (typeof PROCESSES_STEP_TYPES)[number]> = {
	'e70ad13f-6aa7-4e83-bc89-269fd279a72a': 'Camara',
	'7089a802-2de9-457d-86c7-9884e9ea0584': 'Scanner Document',
	'c0a7969e-649b-4286-97d7-f165926226f8': 'Custom Painter parte interna',
	'279e544a-44e7-464c-b232-724143f02108': 'Custom Painter parte externa 1',
	'84aab4d7-5094-4b69-b002-27859412cf0b': 'Custom Painter parte externa 2',
	'62271e89-ac4c-4330-bfa8-1fe1baf7ffb3': 'Formulario',
	DEFINE_THIS_ID: 'Canvas Drawing Tool',
};
